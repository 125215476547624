import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import {RESOURCES as resources} from '../orderResources';
import {setOrdersRetrieved} from '../store/actions';
import {getGetHeaders} from '../utils/headers';
import {assembleDefaultQueryString} from '../utils/dataFunctions';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

const useNotificationStyles = makeStyles((theme) => ({
    root: {
        top: 64,
        right: 34,
        '& > div': {
            backgroundColor: '#fafafa',
            color: '#212121',
            margin: '-8px 8px 0 0',
            padding: '7px 20px 7px 20px',
        },
    },
}));

/**
 * A component for displaying a notification with total Pending orders
 *
 * @return {JSX.Element}
 * @constructor
 */
const OrdersNotification = (props) => {

    const setOrdersRetrieved = props.setOrdersRetrieved;
    const defaultOrderQueryString = assembleDefaultQueryString('Order');

    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderText, setOrderText] = useState('Orders');

    const setCallsComplete = useCallback(() => {
        setOrdersRetrieved(true);
    }, [setOrdersRetrieved]);

    useEffect(
        () => {
            const fetchPendingOrders = () => {
                let cfg = resources.OrdersByStatus.GET_LIST({status: '1'});
                axios({
                    method: 'GET',
                    url: cfg.uri,
                    headers: getGetHeaders(),
                    maxBodyLength: Infinity,
                    maxContentLength: Infinity
                }).then((response) => {
                    const total = response.data.total;
                    setOrderTotal(total);
                    setOrderText((total === 1)
                        ? 'is 1 pending Order'
                        : 'are ' + total + ' pending Orders');
                    setDataRetrieved(true);
                    setCallsComplete();
                }).catch((error) => {
                    console.log('error response for Orders: ', error);
                    if (error.response) {
                        // Server response outside 2xx
                    } else if (error.request) {
                        // No response
                    }
                });
            };

            if (!props.ordersRetrieved) {
                return fetchPendingOrders();
            }

        }, [props.ordersRetrieved, setCallsComplete]
    );

    const action = (
        <Button variant="outlined" size="small" component={Link} to={`/Order${defaultOrderQueryString}`}>
            View Orders
        </Button>
    );

    const classes = useNotificationStyles();

    return (
        <>
            {dataRetrieved && orderTotal > 0 &&
                <div>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        message={`There ${orderText}.`}
                        action={action}
                        open={true}
                        classes={classes}
                    />
                </div>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        ordersRetrieved: state.ordersRetrieved
    };
};

const mapDispatchToProps = {
    setOrdersRetrieved: setOrdersRetrieved
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersNotification);
