import React, {useState} from 'react';
import {
    Button,
    Confirm,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import {assemblePostTasks} from '../utils/apiFunctions';

const BulkEditOrderSubmitButton = ({
                                         editOption,
                                         selectedIds,
                                         selectedBilled,
                                         onSuccess
                                     }) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const onSaveProgress = (e) => {
    };

    const saveData = async () => {
        const data = {
            isOrderValueUpdate: true,
            isProductValueUpdate: false,
            selectedIds: selectedIds
        };
        switch (editOption) {
            case 'bulk_edit_option_billed':
                data.updateType = 'billed';
                data.updateValue = selectedBilled;
                break;
            default:
                break;
        }
        return await assemblePostTasks(data, onSaveProgress)
            .then((result, ...rest) => {
                notify('Orders updated');
                if (typeof onSuccess === 'function') {
                    onSuccess();
                    unselectAll('Order');
                }
            })
            .catch(error => {
                console.log('Posting Error: ', error);
                notify('Error: Orders not updated', 'warning');
            });
    };

    const handleClick = () => setOpen(true);

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        saveData().then(() => {
            setOpen(false);
        });
    };

    // Assemble button and confirmation text
    const productStr = (selectedIds.length === 1) ? 'Order' : 'Orders';
    let btnText = 'Update Orders';
    let content = '?';
    let isDisabled = false;
    if (editOption === 'bulk_edit_option_billed') {
        btnText = 'Set Billed State';
        content = `as ${(selectedBilled) ? 'Billed' : 'Not Billed'}?`;
    }

    return (
        <>
            {editOption &&
            <Button
                color="primary"
                variant="outlined"
                size="medium"
                label={btnText}
                onClick={handleClick}
                disabled={(isDisabled)}
                className="btn-bulk-edit"
            />
            }
            <Confirm
                isOpen={open}
                title="Update Order"
                content={`Are you sure you want to update ${selectedIds.length} ${productStr} ${content}`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
}

export default BulkEditOrderSubmitButton;
