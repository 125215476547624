import React, {useEffect, useState} from 'react';
import {useForm} from 'react-final-form';
import {connect} from 'react-redux';
import {getUserInfoFromToken} from '../utils/tokenFunctions';
import {TextInput} from 'react-admin';

/**
 * Component for setting a Keycloak username value for Receipts
 *
 */
const ReceiptUsernameField = (props) => {

    const receiptForm = useForm();
    const [username, setUsername] = useState('');

    // Effect: Set Username from token
    useEffect(() => {
        const getUsernameFromToken = () => {
            const userInfo = getUserInfoFromToken(props.tokens.token);
            setUsername(userInfo.preferred_username);
        };

        if (props.tokens?.token) {
            return getUsernameFromToken();
        }
    }, [props.tokens.token]);

    // Effect: Populate field if approved status changes
    useEffect(() => {
        const updateApprovalUsername = () => {
            // If Approved status has been set to true, store the current username
            if (!props.record.approved && props.record.set_approved) {
                receiptForm.change('set_approved_by', username);

            }
            // If Approved status has been set to false, clear the "Approved by" username
            if (!props.record.set_approved) {
                receiptForm.change('set_approved_by', '');

            }
        };

        return updateApprovalUsername();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.record.approved, props.record.set_approved]);


    return (
        <>
            <TextInput label="Approved By:" source="set_approved_by" className="input-inline input-280" disabled/>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        tokens: {
            token: state.tokens.token
        }
    };
};

export default connect(
    mapStateToProps
)(ReceiptUsernameField);
