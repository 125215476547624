import React from 'react';
import {
    EditButton,
    ShowButton
} from 'react-admin';
import DeleteWithDialog from '../components/DeleteWithDialog';

/**
 * Default filter for React Table
 *
 * @param filterValue
 * @param setFilter
 * @param onUpdate
 * @return {JSX.Element}
 * @constructor
 */
export const DefaultColumnFilter = (
    {
        column: {id, filterValue, setFilter, onFilterFunc}
    }) => {
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            placeholder="Search"
            className="table-filter-default"
        />
    )
};

/**
 * Filter for React Table with auto-focus
 *
 * @param filterValue
 * @param setFilter
 * @param onUpdate
 * @return {JSX.Element}
 * @constructor
 */
export const FocusedColumnFilter = (
    {
        column: {id, isSorted, filterValue, setFilter}
    }) => {
    return (
        <input
            autoFocus={(isSorted) ? true : undefined}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            placeholder="Search"
            className="table-filter-default"
        />
    )
};

/**
 * Default filter for React Table with total count
 *
 * @param filterValue
 * @param preFilteredRows
 * @param setFilter
 * @return {JSX.Element}
 * @constructor
 */
export const DefaultColumnFilterWithRecord = (
    {
        column: {filterValue, preFilteredRows, setFilter},
    }) => {

    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
            className="table-filter-default"
        />
    )
};

/**
 * Qty checkbox filter for React Table
 *
 * @param setFilter
 * @returns {JSX.Element}
 * @constructor
 */
export const QtyColumnFilter = (
    {column: {setFilter}}
) => {
    const labelStyle = {
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'right',
        lineHeight: 1,
        verticalAlign: 'middle',
        margin: '2px -4px 0 0'
    };
    return (
        <label style={labelStyle}>
            <input
                type="checkbox"
                className="table-header-checkbox"
                onChange={(e) => setFilter((e.target.checked) ? 1 : 0)}
                style={{verticalAlign: 'top', margin: '2px 3px 0 0'}}
            />
            Hide 0 Qty
        </label>
    )
};

/**
 * Return React Table column object for Edit Button
 *
 */
export const editButtonColumnObject = () => {
    return {
        Header: () => null,
        id: 'list-edit',
        Cell: ({row, basePath}) => (
            <EditButton basePath={basePath} record={row.original} label="" title="Edit" className="table-icon-button"/>
        ),
        width: 60,
        maxWidth: 60,
        ClassName: 'cell-icon',
        disableFilters: true,
        disableSortBy: true
    }
};

/**
 * Return React Table column object for Delete Button
 *
 */
export const deleteButtonColumnObject = (label, resourceFile, resourceName, resourceParam) => {
    return {
        Header: () => null,
        id: 'list-delete',
        Cell: ({row}) => (
            <DeleteWithDialog
                type="icon"
                label={label}
                title={label}
                resourceFile={resourceFile}
                resourceName={resourceName}
                resourceParam={resourceParam}
                record={row.original}
                className="table-icon-button"
            />
        ),
        width: 60,
        maxWidth: 60,
        ClassName: 'cell-icon',
        disableFilters: true,
        disableSortBy: true
    }
};

/**
 * Return React Table column object for Show Button
 *
 */
export const showButtonColumnObject = () => {
    return {
        Header: () => null,
        id: 'list-show',
        Cell: ({row, basePath}) => (
            <ShowButton basePath={basePath} record={row.original} label="" title="Details"
                        className="table-icon-button"/>
        ),
        width: 60,
        maxWidth: 60,
        ClassName: 'cell-icon',
        disableFilters: true,
        disableSortBy: true
    }
};
