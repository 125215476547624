import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    BooleanField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton,
    required
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import GetListSelectInput from '../components/GetListSelectInput';
import {SHIPPING_METHOD_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import SendIcon from '@material-ui/icons/Send';

export const ShippingMethodIcon = SendIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const validateName = [required()];
const validateOrgId = [required()];

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <GetListSelectInput
            key="filter_company_id"
            source="filter_company_id"
            label="Select a Company"
            displayType="name"
            resourceFile="default"
            resourceName="ShippingCompany"
            helperText=""
            resourceActive={true}
            allowEmpty
            alwaysOn
            className="input-220"
        />
        <SelectInput source="active" label="Active"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Shipping Methods"><ShippingMethodIcon style={viewStyles.avatarSvg}/></Avatar>Shipping Methods</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [showButtonColumn, editButtonColumn, ...SHIPPING_METHOD_COLUMNS];

const listSort = {field: 'name', order: 'ASC'};

export const ShippingMethodList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.company_id}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable
                    columns={listColumns}
                    sort={listSort}
                    listFilters="filterValues"
                    listFilter="filter_company_id"
                    messageFilter="Select a Shipping Company to view its Methods."
                    messageEmpty=" No Shipping Methods found for this Company."
                    {...props}
                />
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShippingMethodTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Shipping Method"><ShippingMethodIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Shipping Method'}</span>;
};

export const ShippingMethodShow = (props) => (
    <Show title={<ShippingMethodTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Shipping Method details below. Click Edit above to edit this Method.
                `}
            />
            <TextField label="ID" source="id" className="input-inline input-120"/>
            <TextField label="Company ID" source="company_id" className="input-inline input-120"/>
            <TextField label="Shipping Method Name" source="name" className="field-bg"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<ShippingMethodIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Shipping Method</span>;

export const ShippingMethodCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add a Shipping Method by selecting a Company and entering the name of the Method.
                `}
            />
            <GetListSelectInput
                {...props}
                source="company_id"
                label="Select a Company"
                displayType="id - name"
                resourceFile="default"
                resourceName="ShippingCompany"
                resourceActive={true}
                className="half-width"
                addLabel={false}
                allowEmpty
                validate={validateOrgId}
            />
            <TextInput label="Name of Method" source="name" helperText="Enter the full name of the Shipping Method."
                       fullWidth={true} validate={validateName}/>
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Shipping Methods List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ShippingMethodIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.name}` : 'Shipping Method'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const ShippingMethodEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`Edit Shipping Method name below.`}
            />
            <TextInput label="Name of Shipping Method" source="name" fullWidth={true} validate={validateName}/>
            <FieldDescription
                description={true}
                text="If this Shipping Method is no longer to be referenced, mark it as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Shipping Methods List."
            />
        </SimpleForm>
    </Edit>
);

