import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {CSVLink} from 'react-csv';
import {connect} from 'react-redux';
import {RESOURCES as orderResources} from '../orderResources';
import {RESOURCES as receiptResources} from '../receiptResources';
import {getGetHeaders} from '../utils/headers';
import {buildOrderReceiptExportQuery} from '../utils/dataFunctions';
import {postProcessData} from '../dataProviderPostProcess';
import PTheme from '../global/theme';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExportIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
            fontSize: '0.8125rem',
            color: PTheme.palette.toolBarTextColor,
            padding: '4px 5px',
        },
        leftIcon: {
            fontSize: 20,
            marginRight: theme.spacing(1),
        },
        icon: {
            color: PTheme.palette.toolBarTextColor,
        },
    })
);

const ExportBasicToolbarButton = (
    {
        type = 'Orders',
        filename = 'Orders',
        filterValues = {},
        sort = {
            sort_by: 'created_on_date',
            sort_order: 'DESC'
        },
        exportColumns = [],
        disabled = false,
        label = 'Export'
    }) => {

    const [complete, setComplete] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    // Callback: Trigger click on Link
    const setDownload = useCallback(() => {
        const link = document.getElementById(type + '-Link');
        link.click();
    }, [type]);

    // Effect: When data has been received and processed:
    useEffect(() => {
        const download = () => {
            setDownload();
        };

        if (complete && data && data.length > 0) {
            return download();
        }
    }, [complete, data, setDownload]);

    // Get and process data
    const handleExportClick = (event, done) => {
        setComplete(false);
        setLoading(true);

        let exportFilterValues = JSON.parse(JSON.stringify(filterValues));
        exportFilterValues.export_type = type;
        exportFilterValues.sort_by = sort.sort_by;
        exportFilterValues.sort_order = sort.sort_order;
        let query = buildOrderReceiptExportQuery(exportFilterValues, true, true);
        let cfg;

        if (type === 'Receipts') {
            cfg = receiptResources.ReceiptExport.GET_LIST(query);
        } else {
            cfg = orderResources.OrderExport.GET_LIST(query);
        }
        const resourceType = (type === 'Receipts') ? 'Receipt' : 'Order';
        const dataObject = (type === 'Receipts') ? 'receipts' : 'orders';

        axios({
            method: cfg.method,
            url: cfg.uri,
            headers: getGetHeaders()
        })
            .then((result) => {
                if (result.data && result.data[dataObject]) {
                    let rdata = {};
                    rdata.data = JSON.parse(JSON.stringify(result.data[dataObject]));
                    const processed = postProcessData(resourceType, 'GET_LIST', {}, rdata);
                    setData(processed.data);
                }
            })
            .catch((err) => {
                console.log('Error retrieving Orders for Export');
            })
            .finally(() => {
                setLoading(false);
                setComplete(true);
            });
    };

    const handleDownload = () => {
        // CSVLink
    };

    const classes = useStyles();

    if (disabled) {
        return (
            <Button
                variant="text"
                label={label}
                disabled={true}
                className={classes.button}
            >
                <ExportIcon className={classes.leftIcon}/>
                {label}
            </Button>
        )
    }

    return (
        <>
            <Button
                variant="text"
                label={label}
                disabled={disabled}
                className={classes.button}
                onClick={handleExportClick}
            >
                {!loading &&
                    <ExportIcon className={classes.leftIcon}/>
                }
                {loading &&
                    <CircularProgress
                        color="primary"
                        size={18}
                        thickness={5}
                        className={classes.leftIcon}
                    />
                }
                {label}
            </Button>
            <CSVLink
                id={`${type}-Link`}
                data={data}
                headers={exportColumns}
                filename={filename}
                onClick={handleDownload}
                target="_blank"
                style={{display: 'none'}}
            >
            </CSVLink>
        </>
    )
};

const mapStateToProps = (state) => {
    const data = state.currentTableData;
    if (data && data.currentList !== undefined && data.currentList.length > 0) {
        return {
            currentList: data.currentList,
        };
    }
    return {};
};

export default connect(
    mapStateToProps
)(ExportBasicToolbarButton);
