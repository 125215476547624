import React, {useCallback, useEffect, useState} from 'react';
import {SelectInput, TextInput} from 'react-admin';
import {getProductList} from '../utils/apiProductFunctions';
import LocationAvailableProductsList from './LocationAvailableProductsList';
import SelectFromListInput from './SelectFromListInput';
import Button from '@material-ui/core/Button';

/**
 * A component for selecting available Products for Warehouse Locations
 *
 * @param products
 * @param onProductAdd
 * @return {JSX.Element}
 * @constructor
 */
const LocationAvailableProductsContainer = ({products, onProductAdd}) => {

    const [filterBy, setFilterBy] = useState('');
    const [org, setOrg] = useState(null);
    const [show, setShow] = useState(null);
    const [searchName, setSearchName] = useState('');
    const [searchSku, setSearchSku] = useState('');
    const [searchType, setSearchType] = useState(null);
    const [itemList, setItemList] = useState({
        list: []
    });
    const [updateComplete, setUpdateComplete] = useState(true);

    const fetchProductsBySearch = () => {
        let value = (searchType === 'sku') ? searchSku : searchName;
        if (value) {
            getProductList(searchType, value)
                .then(result => {
                    if (result && result.length) {
                        setItemList({
                            list: result
                        });
                        setUpdateComplete(true);
                    }
                })
                .catch(error => {
                    console.log('Products Error: ', error);
                    setUpdateComplete(true);
                });
            setUpdateComplete(true);
        }
    };

    // Callback: Get Products by browse option
    const fetchProductsByBrowse = useCallback((value) => {
        if (value) {
            getProductList('show', value)
                .then(result => {
                    if (result && result.length) {
                        setItemList({
                            list: result
                        });
                        setUpdateComplete(true);
                    }
                })
                .catch(error => {
                    console.log('Products Error: ', error);
                    setUpdateComplete(true);
                });
            setUpdateComplete(true);
        }
    }, []);

    // Effect: Check for browse selections
    useEffect(
        () => {
            if (!updateComplete && filterBy === 'org_show') {
                if (show) {
                    fetchProductsByBrowse(show);
                }
            }
        }, [updateComplete, filterBy, show, fetchProductsByBrowse]
    );

    // Effect: Reset Browse if Location Products have updated
    useEffect(
        () => {
            setFilterBy('');
        }, [products]
    );

    const resetValues = () => {
        setItemList({list: []});
        setOrg(null);
        setShow(null);
        setSearchName('');
        setSearchSku('');
    };

    // Handle selection of Browse By
    const handleSelectFilterBy = (e) => {
        setFilterBy(e?.target?.value ?? '');
        resetValues();
    };

    // Handle Search input change
    const handleSearchFilter = (type, e) => {
        let min = 4;
        let value = e?.target?.value ?? '';
        if (type === 'sku') {
            if (value.indexOf('SKU') !== -1) {
                min = 8;
            } else {
                min = 7;
            }
            if (type === 'sku') {
                setSearchSku(value);
            }
        }
        if (type === 'name') {
            setSearchName(value);
        }
        if (updateComplete && value.length >= min) {
            setSearchType(type);
            setItemList({list: []})
            setOrg(null);
            setShow(null);
        }
        if (updateComplete && value.length === 0) {
            setItemList({list: []})
            setOrg(null);
            setShow(null);
        }
    };

    const submitSearchFilter = (type) => {
        fetchProductsBySearch();
    };

    // Handle selection of Browse By
    const handleSelectBrowse = (type, e) => {
        if (updateComplete) {
            if (e && type === 'org') {
                setOrg(e);
            }
            if (e && type === 'show') {
                setShow(e);
            }
            setItemList({list: []})
            setUpdateComplete(false);
        }
    };

    /**
     * Post a request to add an available Product to the items list
     *
     * @param item
     */
    const handleAddChange = (item) => {
        if (typeof onProductAdd === 'function') {
            onProductAdd(item);
        }
    };

    return (
        <>
            <div className="location-products-select">
                <SelectInput
                    source="filter_products_by" label="Find Products By:"
                    choices={[
                        {id: 'org_show', name: 'Browse by Client and Title'},
                        {id: 'search_name', name: 'Search by Product Name'},
                        {id: 'search_sku', name: 'Search by SKU'}
                    ]}
                    value={filterBy}
                    allowEmpty={true}
                    onChange={(e) => handleSelectFilterBy(e)}
                    className="input-inline input-280"
                />
                <TextInput
                    label="Search Product SKU"
                    source="filter_search_sku"
                    value={searchSku}
                    onChange={(e) => handleSearchFilter('sku', e)}
                    className={(filterBy === 'search_sku') ? 'input-inline input-320' : 'input-hidden'}
                />
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => submitSearchFilter('sku')}
                    className={(filterBy === 'search_sku') ? 'input-inline' : 'input-hidden'}
                    disabled={(searchSku === '' || searchSku.length < 5)}
                    style={{marginTop: 5}}
                >
                    Search
                </Button>
                <TextInput
                    label="Search Product Name"
                    source="filter_search_name"
                    value={searchName}
                    onChange={(e) => handleSearchFilter('name', e)}
                    className={(filterBy === 'search_name') ? 'input-inline input-320' : 'input-hidden'}
                />
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => submitSearchFilter('name')}
                    className={(filterBy === 'search_name') ? '' : 'input-hidden'}
                    disabled={(searchName === '' || searchName.length < 3)}
                    style={{marginTop: 5}}
                >
                    Search
                </Button>
                <span className={(filterBy === 'org_show') ? 'input-inline input-full' : 'input-hidden'}>
                    <>
                        <SelectFromListInput
                            inAdminForm={false}
                            source="filter_org_id"
                            resourceName="Organization"
                            listLabel="By Client"
                            itemPlural="Clients"
                            returnType="string"
                            listFilters={{
                                active: true
                            }}
                            isRequired={false}
                            size="small"
                            hasClear={true}
                            onChangeFunc={(e) => handleSelectBrowse('org', e)}
                            className="input-select-list"
                        />
                        <SelectFromListInput
                            inAdminForm={false}
                            source="filter_show_id"
                            resourceName="ShowByOrg"
                            selectedObject={(show)
                                ? {id: show, name: ''}
                                : undefined}
                            listLabel="By Title"
                            itemPlural="Titles"
                            returnType="string"
                            listFilters={{
                                active: true,
                                org_id: org
                            }}
                            isRequired={false}
                            disabled={(!org)}
                            size="small"
                            hasClear={true}
                            onChangeFunc={(e) => handleSelectBrowse('show', e)}
                            className={(org) ? 'input-select-list' : 'input-hidden'}
                        />
                    </>
                </span>
            </div>

            <LocationAvailableProductsList
                itemList={itemList.list}
                products={products}
                onAddFunc={(e) => handleAddChange(e)}
            />
        </>
    );
}

export default LocationAvailableProductsContainer;
