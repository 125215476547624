import React, {useEffect, useState} from 'react';
import {setItemDateValues} from '../utils/dataFunctions';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AdjustOutlinedIcon from '@material-ui/icons/AdjustOutlined';
import InputIcon from '@material-ui/icons/Input';

const useLedgerRowStyles = makeStyles((theme) => ({
    btnActive: {
        padding: 8
    },
    btnInactive: {
        padding: 8,
        pointerEvents: 'none',
        opacity: '0.5'
    },
    iconReceipt: {
        fontSize: 24,
        margin: 0,
        verticalAlign: 'middle',
        transform: 'scale(-0.9, 1)'
    },
    iconOrder: {
        fontSize: 24,
        margin: 0,
        verticalAlign: 'middle',
        transform: 'scale(0.9, 1)'
    },
    iconManual: {
        fontSize: 26,
        margin: '0 -1px 0 -2px',
        verticalAlign: 'middle',
    },
    td: {
        position: 'relative',
        padding: '6px 12px 6px 12px'
    },
    tdIcon: {
        width: 122,
        maxWidth: 122,
        position: 'relative',
        padding: '4px 4px 4px 2px'
    },
    tdNum: {
        width: 90,
        maxWidth: 90,
        position: 'relative',
        padding: '4px 8px 4px 8px'
    },
    typeTextActive: {
        fontSize: 14,
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    typeTextInactive: {
        fontSize: 14,
        verticalAlign: 'middle',
        pointerEvents: 'none',
        opacity: '0.7'
    },
}));

/**
 * A component for displaying an Inventory Ledger entry with optional links
 *
 * @param entry
 * @param onEntryClick
 * @returns {JSX.Element}
 * @constructor
 */
const InventoryLedgerTableRow = ({entry, onEntryClick}) => {

    const [entryType, setEntryType] = useState('');
    const [entryLink, setEntryLink] = useState(null);
    const [entryDate, setEntryDate] = useState('');
    const classes = useLedgerRowStyles();

    useEffect(
        () => {
            // Determine whether this Entry is an Order, a Receipt, or a Manual Adjustment
            const setEntry = () => {
                const info = entry.info;
                let type = 'Order';
                let link = null;
                if (
                    info === 'ordered' ||
                    info === 'order deleted' ||
                    info.indexOf('order item') !== -1
                ) {
                    type = 'Order';
                    link = (info === 'order deleted') ? null : '/Order/' + entry.order_id + '/show';
                } else if (
                    info === 'received' ||
                    info === 'receipt deleted' ||
                    info.indexOf('receipt item') !== -1
                ) {
                    type = 'Receipt';
                    link = (info === 'receipt deleted') ? null : '/Receipt/' + entry.order_id + '/show';
                } else if (!entry.order_id || entry.order_id === 0) {
                    type = 'Manual';
                } else {
                    type = (entry.qty >= 0) ? 'Receipt' : 'Order';
                    entry.info = (entry.total === 0) ? 'Legacy' : '';
                    if (entry.total === 0) {
                        entry.total = 'N/A';
                    }
                    link = (entry.qty >= 0) ? '/Receipt/' + entry.order_id + '/show' : '/Order/' + entry.order_id + '/show';
                }
                // Set Entry Type and Link if applicable
                setEntryType(type);
                setEntryLink(link);

                // Create a human-readable date string
                const modifiedDate = setItemDateValues(entry.modified);
                setEntryDate(modifiedDate.date_string);
            };

            if (entryType === '') {
                return setEntry();
            }

        }, [entry, entryType]
    );

    const handleIconClick = () => {
        if (typeof onEntryClick === 'function') {
            onEntryClick({type: entryType, id: entry.order_id});
        }
    };

    return (
        <TableRow>
            <TableCell className={classes.tdIcon}>
                <IconButton
                    color="primary"
                    onClick={() => (entryLink) ? handleIconClick() : undefined}
                    className={(entryLink) ? classes.btnActive : classes.btnInactive}
                >
                    {entryType === 'Order' &&
                        <InputIcon className={classes.iconOrder}/>
                    }
                    {entryType === 'Receipt' &&
                        <InputIcon className={classes.iconReceipt}/>
                    }
                    {entryType === 'Manual' &&
                        <AdjustOutlinedIcon className={classes.iconManual}/>
                    }
                </IconButton>
                <span
                    onClick={() => (entryLink) ? handleIconClick() : undefined}
                    className={(entryLink) ? classes.typeTextActive : classes.typeTextInactive}
                >
                    {entryType}
                </span>
            </TableCell>
            <TableCell className={classes.td}>{entryDate}</TableCell>
            <TableCell align="right" className={classes.tdNum}>{entry.qty}</TableCell>
            <TableCell align="right" className={classes.tdNum}>{entry.total}</TableCell>
            <TableCell className={classes.td}>{entry.info}</TableCell>
            <TableCell className={classes.td}>{entry.username}</TableCell>
        </TableRow>
    );
}

export default InventoryLedgerTableRow;
