import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {CSVLink} from 'react-csv';
import {connect} from 'react-redux';
import {RESOURCES as defaultResources} from '../resources';
import {RESOURCES as productResources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import {postProcessData} from '../dataProviderPostProcess';
import {sortArrayByKey} from '../utils/dataFunctions';
import PTheme from '../global/theme';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExportIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
            fontSize: '0.8125rem',
            color: PTheme.palette.toolBarTextColor,
            padding: '4px 5px',
        },
        leftIcon: {
            fontSize: 20,
            marginRight: theme.spacing(1),
        },
        icon: {
            color: PTheme.palette.toolBarTextColor,
        },
    })
);

const ExportBasicListToolbarButton = (
    {
        type = 'Product',
        filename = 'Products',
        filterValues = {},
        sort = {
            sort_by: 'created_on_date',
            sort_order: 'DESC'
        },
        exportColumns = [],
        disabled = false,
        label = 'Export'
    }) => {

    const [complete, setComplete] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    // Callback: Trigger click on Link
    const setDownload = useCallback(() => {
        const link = document.getElementById(type + '-Export-Link');
        link.click();
    }, [type]);

    // Effect: When data has been received and processed:
    useEffect(() => {
        const download = () => {
            setDownload();
        };

        if (complete && data && data.length > 0) {
            return download();
        }
    }, [complete, data, setDownload]);

    // Get and process data
    const handleExportClick = (event, done) => {
        setComplete(false);
        setLoading(true);

        let fv = JSON.parse(JSON.stringify(filterValues));

        fv.export_type = type;
        fv.sort_by = sort.sort_by;
        fv.sort_order = sort.sort_order;
        fv.params = {
            filter: {
                filter_by: fv.filter_by ?? '',
                filter_org_id: fv.filter_org_id ?? undefined,
                filter_product_type_id: fv.filter_product_type_id ?? undefined,
                filter_parent_product_type_id: fv.filter_parent_product_type_id ?? undefined,
                filter_sku: fv.filter_sku ?? undefined,
                filter_show_id: fv.filter_show_id ?? undefined,
                filter_title: fv.filter_title ?? undefined
            }
        };

        let cfg;
        let resourceType = type;
        if (type === 'Product') {
            cfg = productResources[type].GET_LIST(fv);
        } else {
            cfg = defaultResources[type].GET_LIST(fv);
        }

        axios({
            method: cfg.method,
            url: cfg.uri,
            headers: getGetHeaders()
        })
            .then((result) => {
                if (result.data && result.data.length > 0) {
                    const processed = postProcessData(resourceType, 'GET_LIST', {}, result.data);
                    if (type === 'Product') {
                        processed.forEach(function (product, idx) {
                            product.locations = product.locations ?? '';
                            product.title = (product.title) ? product.title.replace(/"/g, '""') : '';
                            product.description = (product.description) ? product.description.replace(/"/g, '""') : '';
                        });
                    }
                    const sorted = sortArrayByKey(processed, fv.sort_by, fv.sort_order);
                    setData(sorted);
                }
            })
            .catch((err) => {
                console.log('Error retrieving ' + type + ' for exporting');
            })
            .finally(() => {
                setLoading(false);
                setComplete(true);
            });
    };

    const handleDownload = () => {
        // CSVLink
    };

    const classes = useStyles();

    if (disabled) {
        return (
            <Button
                variant="text"
                label={label}
                disabled={true}
                className={classes.button}
            >
                <ExportIcon className={classes.leftIcon}/>
                {label}
            </Button>
        )
    }

    return (
        <>
            <Button
                variant="text"
                label={label}
                disabled={disabled}
                className={classes.button}
                onClick={handleExportClick}
            >
                {!loading &&
                    <ExportIcon className={classes.leftIcon}/>
                }
                {loading &&
                    <CircularProgress
                        color="primary"
                        size={18}
                        thickness={5}
                        className={classes.leftIcon}
                    />
                }
                {label}
            </Button>
            <CSVLink
                id={`${type}-Export-Link`}
                data={data}
                headers={exportColumns}
                filename={filename}
                onClick={handleDownload}
                target="_blank"
                style={{display: 'none'}}
            >
            </CSVLink>
        </>
    )
};

const mapStateToProps = (state) => {
    // const data = state.currentTableData;
    // if (data && data.currentList !== undefined && data.currentList.length > 0) {
    //     return {
    //         currentList: data.currentList,
    //     };
    // }
    return {};
};

export default connect(
    mapStateToProps
)(ExportBasicListToolbarButton);
