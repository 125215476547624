import * as React from "react";
import {
    List,
    Filter,
    Create,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    FormDataConsumer,
    Toolbar,
    BooleanField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton,
    required
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import GetListSelectInput from '../components/GetListSelectInput';
import ProductTypeEditParentChild from '../components/ProductTypeEditParentChild';
import {PRODUCT_TYPE_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LabelIcon from '@material-ui/icons/Label';

export const ProductTypeIcon = LabelIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const validateName = [required()];

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="active" label="Active Categories"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
                     className="input-180"
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Product Categories"><ProductTypeIcon style={viewStyles.avatarSvg}/></Avatar>Product Categories</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [showButtonColumn, editButtonColumn, ...PRODUCT_TYPE_COLUMNS];

const listSort = {field: 'name', order: 'ASC'};

export const ProductTypeList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.description}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable columns={listColumns} sort={listSort}
                           messageEmpty=" No Product Categories found." {...props}/>
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Product Category"><ProductTypeIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Product Category'}</span>;
};

export const ProductTypeShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Product Category details below. To edit this Category, click Edit above.
                `}
            />
            <TextField label="ID" source="id"/>
            <TextField label="Name of Category" source="name" className="field-bg input-inline input-320"/>
            <TextField label="Parent Category (if any)" source="parent_type_name" className="field-bg input-inline input-320" disabled/>
            <FieldDivider type="break" margin="bottom"/>
            <ProductTypeEditParentChild record={props.record} display="show"/>
            <TextField label="Description" source="description" className="field-bg"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<ProductTypeIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Product Category</span>;

export const ProductTypeCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Product Categories are used to categorize Products. Add a Product Category by entering a name and description.
                `}
            />
            <TextInput label="Name of Product Category" source="name" validate={validateName} fullWidth={true}/>
            <TextInput label="Description" source="description" multiline fullWidth={true}/>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`If this Product Category is a Subcategory, select a top-level Category.`}
            />
            <GetListSelectInput
                {...props}
                source="parent_type_id"
                label="Select a top-level Category (optional)"
                displayType="name"
                resourceFile="default"
                resourceName="ParentProductType"
                resourceSort={{field: 'name', order: 'ASC'}}
                resourceActive={true}
                className="half-width"
                addLabel={false}
                allowEmpty
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Product Categories List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ProductTypeIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.name}` : 'Product Category'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const ProductTypeEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Edit Product Category details below. Product Category ID cannot be edited.
                `}
            />
            <TextInput disabled label="ID" source="id"/>
            <TextInput label="Name of Category" source="name" formClassName="input-inline input-320"/>
            <TextInput label="Parent Category" source="parent_type_name" formClassName="input-inline input-320" disabled/>
            <FieldDivider type="break"/>
            <FormDataConsumer {...props}>
                {({formData, ...rest}) => (
                    <ProductTypeEditParentChild record={formData} display="edit"/>
                )}
            </FormDataConsumer>
            <FieldDivider type="break"/>
            <TextInput label="Category Description" source="description" formClassName="input-inline input-full"/>
            <FieldDescription
                description={true}
                text="If this Product Category is no longer used, mark it as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Product Categories List."
            />
        </SimpleForm>
    </Edit>
);

