import axios from 'axios';
import {getGetHeaders, getPostHeaders} from './headers';
import {RESOURCES as productResources} from '../productResources';
import {RESOURCES as resources} from '../resources';

/**
 * Retrieve list of Warehouse Location Products by Location
 *
 * @param location
 * @returns {Promise<unknown>}
 */
export const refreshLocationProducts = (location) => {
    return new Promise(function (resolve, reject) {
        if (location) {

            const onFailure = function (result) {
                return reject('List Refresh failed: ', result);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            let params = {
                id: location
            };
            let cfg = resources.WarehouseLocation.GET_ONE(params);

            // Set headers
            const headers = getGetHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    headers: headers
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Product List Error response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Product List Error request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Product List Error: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Product List try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Post a request to Add, Update or Delete a Location with a Product
 *
 * @param type
 * @param data
 * @return {Promise<unknown>}
 */
export const postItem = (type, data) => {
    return new Promise(function (resolve, reject) {
        if (data) {

            const onFailure = function (result) {
                return reject('Product Update failed: ', result);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            let params = {};
            if (type === 'add' || type === 'update') {
                params.location = data.location;
                params.product_id = data.product_id;
                params.qty = data.qty;
            }
            if (type === 'delete') {
                params.id = data.id;
            }
            let cfg;
            switch (type) {
                case 'add':
                    cfg = resources.WarehouseLocation.CREATE(params);
                    break;
                case 'update':
                    cfg = resources.WarehouseLocation.CREATE(params);
                    break;
                case 'delete':
                    cfg = resources.WarehouseLocation.DELETE(params);
                    break;
                default:
                    break;
            }

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers
                })
                    .then((result) => {
                        if (result && result.data) {
                            if (type === 'delete' && result.data.message) {
                                return onSuccess({success: true});
                            } else {
                                return onSuccess(result.data);
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Product Update Error response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Product Update Error request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Product Update Error: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Product Update try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

export const getProductList = (type, value) => {
    return new Promise(function (resolve, reject) {
        if (type && value) {
            const onFailure = function (result) {
                return reject('Product Retrieval failed: ', result);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            let cfg;
            let params = {
                active: true,
                id: null,
                params: {
                    filter: {}
                }
            };

            switch (type) {
                case 'sku':
                    params.params.filter.filter_sku = value;
                    cfg = productResources.Product.GET_LIST(params);
                    break;
                case 'name':
                    params.params.filter.filter_title = value;
                    cfg = productResources.Product.GET_LIST(params);
                    break;
                case 'org':
                    params.id = value;
                    cfg = productResources.ProductsByOrg.GET_LIST(params);
                    break;
                case 'show':
                    params.id = value;
                    cfg = productResources.ProductsByShow.GET_LIST(params);
                    break;
                case 'product_type':
                    params.id = value;
                    cfg = productResources.ProductsByType.GET_LIST(params);
                    break;
                default:
                    break;
            }

            const headers = getGetHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    headers: headers
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Products Error response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Products Error request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Products Error: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Products try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};
