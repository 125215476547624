import React, {useState} from 'react';
import DeleteOrderButton from './DeleteOrderButton';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    btnText: {
        marginLeft: 6,
        padding: '1px 10px'
    },
    messageError: {
        fontSize: 14,
        color: '#b4212d',
        margin: '8px 0 10px 0'
    },
    th: {
        lineHeight: '1.2',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '5px 16px 5px 16px'
    }
});

/**
 * A component to display a list of Stored Orders or Receipts
 *
 * @param onResume
 * @param onDeleted
 * @param type
 * @param clients
 * @param storedList
 * @return {JSX.Element}
 * @constructor
 */
const StoredOrdersReceiptsList = ({
                                   onResume,
                                   onDeleted,
                                   type = 'order',
                                   clients = [],
                                   storedList = []
                               }) => {

    const [message, setMessage] = useState(null);

    const typeStr = (type === 'receipt') ? 'Receipt' : 'Order';

    const handleResume = (id, name) => {
        if (typeof onResume === 'function') {
            onResume({id: id, name: name});
        }
        setMessage(null);
    };

    const handleDeleted = (id) => {
        if (typeof onDeleted === 'function') {
            onDeleted(id);
        }
        setMessage(null);
    };

    const handleDeleteFail = (id) => {
        if (typeof onDeleted === 'function') {
            onDeleted(id);
        }
        setMessage(null);
    };

    const setRows = () => {
        const list = storedList;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Stored {typeStr}s</span>
            )
        }

        return (
            storedList.map((item, index) => {
                    let client = clients.find(r => r.id === item.org_id);
                    let name = (client) ? client.name : '';
                    return (
                        <TableRow key={`stored-order-${item.id}-${index}`}>

                            <TableCell className={classes.td}>{item.id}</TableCell>
                            <TableCell className="td-name">
                                {name}
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.btnText}
                                    startIcon={<EditIcon/>}
                                    onClick={() => handleResume(item.id, name)}
                                >
                                    Resume {typeStr}
                                </Button>
                                <DeleteOrderButton
                                    type={type}
                                    id={item.id}
                                    client={name}
                                    onSuccess={() => handleDeleted(item.id)}
                                    onFail={() => handleDeleteFail(item.id)}
                                />
                            </TableCell>
                        </TableRow>
                    )
                }
            )
        )
    };

    const classes = useStyles();

    return (
        <>
            {storedList.length > 0 &&
                <div className="stored-order-list-container">
                    <Table size="small" stickyHeader aria-label={`${typeStr}s in Progress`}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.th}>{typeStr} ID</TableCell>
                                <TableCell className={classes.th}>for Client</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...setRows()]}
                        </TableBody>
                    </Table>
                </div>
            }
            {(!storedList || storedList.length === 0) &&
                <div>
                    <Table size="small" stickyHeader aria-label={`${typeStr}s in Progress`}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.th}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.td}>You have no {typeStr}s in progress.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            }
            {message &&
                <p className={classes.messageError}>
                    {message}
                </p>
            }
        </>
    )
}

export default StoredOrdersReceiptsList;
