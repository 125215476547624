import React, {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';
import OrderPrint from '../OrderPrint';
import ReceiptPrint from '../ReceiptPrint';
import PTheme from '../../global/theme';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
            fontSize: '0.8125rem',
            color: PTheme.palette.toolBarTextColor,
            padding: '4px 5px',
        },
        leftIcon: {
            fontSize: 20,
            marginRight: theme.spacing(1),
        },
        icon: {
            color: PTheme.palette.toolBarTextColor,
        },
    })
);

const printStyle = `
@media print {
  @page {page-break-after: auto; size: portrait;}
  @page :header {display: none;}
  @page :footer {display: none;}
  html, body {background: #e0e0e0; overflow: initial !important;}
}`;

/**
 * Button component for single detail pages
 *
 * @param record
 * @param disabled
 * @param label
 * @param type
 * @returns {JSX.Element|null}
 * @constructor
 */
const PrintToolbarButton = ({
                                record,
                                disabled = false,
                                label = 'Print',
                                type = 'Order'
                            }) => {

    const printRef = useRef();
    const [id, setId] = useState('');

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        copyStyles: true,
        documentTitle: 'Parrot Media - ' + type + (id) ? ' ' + id : '',
        pageStyle: printStyle
    });

    const classes = useStyles();

    useEffect(() => {
        const setPrintId = () => {
            setId(record.id);
        };

        if (record && record.id) {
            return setPrintId();
        }
    }, [record]);

    if (!record) {
        return null;
    }

    const isDisabled = (
        disabled ||
        !record ||
        !record.id
    );

    if (isDisabled) {
        return (
            <Button
                variant="text"
                label={label}
                disabled={true}
                className={classes.button}
            >
                <PrintIcon className={classes.leftIcon}/>
                {label}
            </Button>
        )
    }

    return (
        <>
            <Button
                variant="text"
                label={label}
                disabled={isDisabled}
                className={classes.button}
                onClick={handlePrint}
            >
                <PrintIcon className={classes.leftIcon}/>
                {label}
            </Button>
            <div style={{display: 'none'}}>
                <div ref={printRef} className="page-print print-only">
                    {record &&
                        <div>
                            {type === 'Order' &&
                                <OrderPrint record={record}/>
                            }
                            {type === 'Receipt' &&
                                <ReceiptPrint record={record}/>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    )
};

export default PrintToolbarButton;
