import React, {useState} from 'react';
import axios from 'axios';
import {RESOURCES as productResources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import PTheme from '../global/theme';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExportIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
            fontSize: '0.8125rem',
            color: PTheme.palette.toolBarTextColor,
            padding: '4px 5px',
            whiteSpace: 'nowrap'
        },
        leftIcon: {
            fontSize: 20,
            marginRight: theme.spacing(1),
        }
    })
);

const ExportActiveProductsButton = ({buttonType = 'toolbar', disabled = false}) => {

    const [loading, setLoading] = useState(false);

    // Assemble filename and submit request
    const handleExportClick = (event) => {
        setLoading(true);
        let cfg = productResources.ActiveProductsExport.GET_LIST({});

        axios({
            method: cfg.method,
            url: cfg.uri,
            headers: getGetHeaders(),
            responseType: 'arraybuffer'
        })
            .then((result) => {
                if (result && result.data) {
                    const today = new Date();
                    const todayStr = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                    const filename = 'Parrot-Active-Products-' + todayStr;
                    const contentType = 'text/csv;charset=utf-8';
                    const blob = new Blob([result.data], {type: contentType});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    link.target = '_blank';
                    link.title = filename;
                    link.rel = 'noopener noreferrer';
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(function () {
                        // Delay revoking ObjectURL (Firefox)
                        window.URL.revokeObjectURL(url);
                    }, 100);
                    link.remove();
                }
            })
            .catch((err) => {
                console.log('Error retrieving Export: ', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const classes = useStyles();
    const label = 'Export Active Products';

    if (disabled) {
        return (
            <>
                {buttonType === 'dashboard' &&
                    <Button
                        variant="outlined"
                        label={label}
                        disabled={true}
                        className={classes.button}
                    >
                        {label}
                    </Button>
                }
                {buttonType === 'toolbar' &&
                    <Button
                        variant="text"
                        label={label}
                        disabled={true}
                        className={classes.button}
                    >
                        <ExportIcon className={classes.leftIcon}/>
                        {label}
                    </Button>
                }
            </>
        )
    }

    return (
        <>
            {buttonType === 'dashboard' &&
                <Button
                    variant="outlined"
                    disabled={disabled}
                    onClick={handleExportClick}
                >
                    {loading &&
                        <CircularProgress
                            color="primary"
                            size={18}
                            thickness={5}
                            className={classes.leftIcon}
                        />
                    }
                    {label}
                </Button>
            }
            {buttonType === 'toolbar' &&
                <Button
                    variant="text"
                    label={label}
                    disabled={disabled}
                    className={classes.button}
                    onClick={handleExportClick}
                >
                    {!loading &&
                        <ExportIcon className={classes.leftIcon}/>
                    }
                    {loading &&
                        <CircularProgress
                            color="primary"
                            size={18}
                            thickness={5}
                            className={classes.leftIcon}
                        />
                    }
                    {label}
                </Button>
            }
        </>
    )
};

export default ExportActiveProductsButton;
