import React, {useState} from 'react';
import axios from 'axios';
import {RESOURCES as orderResources} from '../orderResources';
import {RESOURCES as receiptResources} from '../receiptResources';
import {getGetHeaders} from '../utils/headers';
import {buildOrderReceiptDetailedExportQuery} from '../utils/dataFunctions';
import PTheme from '../global/theme';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExportIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
            fontSize: '0.8125rem',
            color: PTheme.palette.toolBarTextColor,
            padding: '4px 5px',
            whiteSpace: 'nowrap'
        },
        leftIcon: {
            fontSize: 20,
            marginRight: theme.spacing(1),
        }
    })
);

const ExportDetailedToolbarButton = (
    {
        type = 'Orders',
        filterValues = {},
        disabled = false,
        label = 'Export'
    }) => {

    const [loading, setLoading] = useState(false);

    // Assemble query from current filters and submit request
    const handleExportClick = (event) => {
        setLoading(true);
        const params = buildOrderReceiptDetailedExportQuery(filterValues);
        let cfg;
        const today = new Date();
        const todayStr = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        if (type === 'Receipts') {
            cfg = receiptResources.ReceiptDetailedExport.GET_LIST(params);
        } else {
            cfg = orderResources.OrderDetailedExport.GET_LIST(params);
        }
        const filename = 'Parrot-' + type + '-Detailed-' + todayStr;

        axios({
            method: cfg.method,
            url: cfg.uri,
            headers: getGetHeaders(),
            responseType: 'arraybuffer'
        })
            .then((result) => {
                if (result && result.data) {
                    const contentType = 'text/csv;charset=utf-8';
                    const blob = new Blob([result.data], {type: contentType});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename;
                    link.target = '_blank';
                    link.title = filename;
                    link.rel = 'noopener noreferrer';
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(function () {
                        // Delay revoking ObjectURL (Firefox)
                        window.URL.revokeObjectURL(url);
                    }, 100);
                    link.remove();
                }
            })
            .catch((err) => {
                console.log('Error generating Export: ', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const classes = useStyles();

    if (disabled) {
        return (
            <Button
                variant="text"
                label={label}
                disabled={true}
                className={classes.button}
            >
                <ExportIcon className={classes.leftIcon}/>
                {label}
            </Button>
        )
    }

    return (
        <>
            <Button
                variant="text"
                label={label}
                disabled={disabled}
                className={classes.button}
                onClick={handleExportClick}
            >
                {!loading &&
                    <ExportIcon className={classes.leftIcon}/>
                }
                {loading &&
                    <CircularProgress
                        color="primary"
                        size={18}
                        thickness={5}
                        className={classes.leftIcon}
                    />
                }
                {label}
            </Button>
        </>
    )
};

export default ExportDetailedToolbarButton;
