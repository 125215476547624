import React, {useState, useEffect} from 'react';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useClientUserOrgStyles = makeStyles((theme) => ({
    orgChip: {
        display: 'inline-block',
        margin: '0 1em 1em 0',
        verticalAlign: 'middle'
    }
}));

const ClientUserOrganizationField = ({record}) => {

    const [associatedOrgsState, setAssociatedOrgsState] = useState([]);

    useEffect(
        () => {
            if (
                record.user_orgs
                && record.user_orgs.length > 0
                && JSON.parse(JSON.stringify(record.user_orgs)) !== JSON.parse(JSON.stringify(associatedOrgsState))
            ) {
                setAssociatedOrgsState(record.user_orgs);
            }

        }, [record.user_orgs, associatedOrgsState]
    );

    const renderOrgs = () => {
        return (
            associatedOrgsState.map((org) => (
                <span className={classes.orgChip} key={`associated-orgs-${org.org_id}`}>
                    <Chip label={org.org_name}/>
                </span>
            ))
        )
    };

    const classes = useClientUserOrgStyles();

    return (
        <>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`This Client User may be associated with one or more Clients, allowing the User to log into the 
                Parrot Media Portal and access Products and Orders for those Clients.`}
            />

            {associatedOrgsState.length === 0 &&
            <FieldDescription description={true} text="(No Clients associated with this User)"/>
            }
            {associatedOrgsState.length > 0 &&
            <FieldDescription description={true} text="Clients associated with this User:"/>
            }
            <FieldDivider type="break" margin="bottom"/>

            {[...renderOrgs()]}

            <FieldDivider type="divider" margin="bottom"/>
        </>
    );
}

export default ClientUserOrganizationField;
