import React, {cloneElement} from 'react';
import {
    List,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    FormDataConsumer,
    TopToolbar,
    Toolbar,
    BooleanField,
    DateField,
    TextField,
    DateInput,
    NumberInput,
    SelectInput,
    TextInput,
    CreateButton,
    EditButton,
    ListButton,
    SaveButton,
    ShowButton,
    sanitizeListRestProps,
    useListController,
    useListContext,
    useShowController, BooleanInput
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import ExportBasicToolbarButton from '../components/ExportBasicToolbarButton';
import ExportDetailedToolbarButton from '../components/ExportDetailedToolbarButton';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import OrderReceiptCostTotals from '../components/OrderReceiptCostTotals';
import PrintReceiptLabelButton from '../components/PrintReceiptLabelButton';
import PrintToolbarButton from '../components/admin/PrintToolbarButton';
import ReceiptUsernameField from '../components/ReceiptUsernameField';
import ReceiptItemListViewContainer from '../components/ReceiptItemListViewContainer';
import SelectFromListInput from '../components/SelectFromListInput';
import {getDefaultDateRanges} from '../utils/dataFunctions';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {
    RECEIPT_BROWSE_EXPORT_COLUMNS,
    RECEIPT_BILLING_EXPORT_COLUMNS,
    RECEIPT_QUERY_DEFAULTS,
    ORDER_RECEIPT_TABLE_FILTERS
} from '../global/orderReceiptDefaults';
import {viewStyles} from '../global/styles';
import {makeStyles} from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ReceiptsIcon from '@material-ui/icons/Receipt';
import OrderUsernameField from '../components/OrderUsernameField';
import {BILLING_LABOR_MINUTES, BILLING_LABOR_PER_HOUR_TITLE} from '../global/billingDefaults';

export const ReceiptIcon = ReceiptsIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const defaultDateRanges = getDefaultDateRanges();

/**
 * View: List
 */

/**
 * Filter List View
 *
 * Select View Mode
 * Filter Receipts By: Client, Product SKU, Date Range
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ListFilter = (props) => {

    const onFilterChange = (e) => {
        // Clear table quick-filters when top-level Filters change
        let updateFilters = false;
        let fName, fValue;
        if (e && e.target) {
            fName = e.target.name;
            fValue = e.target.value;
        }
        // If View Mode or Filter By changes:
        if (
            fName && fValue &&
            (fName === 'filter_list_view' || fName === 'filter_by')
        ) {
            const fValues = JSON.parse(JSON.stringify(props.filterValues));
            ORDER_RECEIPT_TABLE_FILTERS.forEach((filter) => {
                if (fValues[filter.key]) {
                    if (filter.clearOnFilterBy.includes(fValue) || fName === 'filter_list_view') {
                        fValues[filter.key] = undefined;
                        updateFilters = true;
                    }
                }
            });
            if (updateFilters && typeof props.setFilters === 'function') {
                fValues[fName] = fValue;
                props.setFilters(fValues);
            }
        }
    };

    return (
        <Filter {...props}>
            <SelectInput
                key="filter_list_view" source="filter_list_view" label="Select Viewing Mode"
                choices={[
                    {id: 'browse', name: 'Browse Mode'},
                    {id: 'billing', name: 'Billing Mode'},
                ]}
                alwaysOn
                allowEmpty={false}
                onChange={(e) => onFilterChange(e)}
                className="input-120"
            />
            <SelectInput
                key="filter_by" source="filter_by" label="Filter By:"
                choices={[
                    {id: 'bill_ref', name: 'Billing Reference'},
                    {id: 'date_range', name: 'Date Range'},
                    {id: 'client', name: 'Client'},
                    {id: 'id', name: 'Receipt ID'},
                    {id: 'sku', name: 'Product SKU'}
                ]}
                alwaysOn
                allowEmpty={true}
                onChange={(e) => onFilterChange(e)}
                className="input-120"
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_org_id"
                resourceName="Organization"
                listLabel={(props.filterValues.filter_by === 'contact') ? 'Select Client' : 'By Client'}
                itemPlural="Clients"
                returnType="string"
                listFilters={{
                    active: true
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={false}
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && (props.filterValues.filter_by === 'client' || props.filterValues.filter_by === 'contact' || props.filterValues.filter_by === 'bill_ref')) ? 'input-select-list' : 'input-hidden'}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_bill_ref_id"
                resourceName="BillingReference"
                listLabel="By Billing Reference"
                itemPlural="Billing References"
                returnType="string"
                listFilters={{
                    active: true,
                    params: {
                        filter: {
                            filter_org_id: props.filterValues.filter_org_id
                        },
                    }
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={false}
                onChangeFunc={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'bill_ref') ? 'input-select-list' : 'input-hidden'}
            />
            <TextInput
                key="filter_sku"
                label="Enter Receipt ID"
                source="filter_id"
                placeholder=""
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'id') ? 'input-180' : 'input-hidden'}
            />
            <TextInput
                key="filter_sku"
                label="Enter full Product SKU"
                source="filter_sku"
                placeholder="SKU-00012345"
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'sku') ? 'input-180' : 'input-hidden'}
            />
            <DateInput
                key="filter_from"
                source="filter_from" label="From"
                inputProps={{
                    min: defaultDateRanges.from,
                    max: defaultDateRanges.to
                }}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={
                    (
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'date_range') ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'client' && props.filterValues.filter_org_id)
                    )
                        ? 'input-180' : 'input-hidden'
                }
            />
            <DateInput
                key="filter_to"
                source="filter_to" label="To"
                inputProps={{
                    min: defaultDateRanges.from,
                    max: defaultDateRanges.to
                }}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={
                    (
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'date_range') ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'client' && props.filterValues.filter_org_id)
                    )
                        ? 'input-180' : 'input-hidden'
                }
            />
        </Filter>
    )
};

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Receipts"><ReceiptIcon style={viewStyles.avatarSvg}/></Avatar>Receipts</span>;

const ListActions = (props) => {
    const {
        className,
        ids,
        filters,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter
    } = useListContext();

    const view = (filterValues && filterValues.filter_list_view && filterValues.filter_list_view === 'billing') ? 'Billing' : 'Browse';
    let exportColumns = RECEIPT_BROWSE_EXPORT_COLUMNS;
    if (view === 'Browse') {
        exportColumns = RECEIPT_BROWSE_EXPORT_COLUMNS;
    }
    if (view === 'Billing') {
        exportColumns = RECEIPT_BILLING_EXPORT_COLUMNS;
    }
    const sort = {
        sort_by: props.currentSort.field ?? 'created_on',
        sort_order: props.currentSort.order ?? 'DESC'
    };

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath}/>
            <ExportBasicToolbarButton
                type="Receipts"
                label="Export"
                filename={`Parrot-Receipts-${view}-${defaultDateRanges.to}`}
                filterValues={filterValues}
                sort={sort}
                exportColumns={exportColumns}
                disabled={(!ids || ids.length === 0)}
            />
            <ExportDetailedToolbarButton
                type="Receipts"
                label="Detailed Export"
                filterValues={filterValues}
                disabled={(!ids || ids.length === 0)}
            />
        </TopToolbar>
    );
};

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listSort = {field: RECEIPT_QUERY_DEFAULTS.sort_by, order: RECEIPT_QUERY_DEFAULTS.sort_order};
const listPagination = {page: RECEIPT_QUERY_DEFAULTS.page, perPage: RECEIPT_QUERY_DEFAULTS.per_page};


/**
 * Receipt List with column assembly
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ReceiptList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const lc = useListController(props);
    const fv = lc.filterValues;
    const filterDefaultValues = {
        filter_list_view: 'browse',
        filter_by: 'date_range',
        filter_from: defaultDateRanges.monthBack,
        filter_to: defaultDateRanges.to
    };

    const billRefCol = {
        Header: 'Billing Reference',
        accessor: 'bill_ref_desc',
        sortBy: 'bill_ref_id',
        sortType: 'basic',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.bill_ref_desc) ? row.original.bill_ref_desc : ''}</span>
    };
    const clientCol = {
        Header: 'Client',
        accessor: 'org_name',
        sortType: 'basic',
        sortBy: 'org_id',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.org_name) ? row.original.org_name : ''}</span>
    };
    const courierCostCol = {
        Header: 'Courier Cost',
        accessor: 'courier_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span
            className="right">{(row.original.courier_cost_str) ? row.original.courier_cost_str : '0.00'}</span>
    };
    const laborCostCol = {
        Header: 'Labor Cost',
        accessor: 'labor_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.labor_cost_str) ? row.original.labor_cost_str : '0.00'}</span>
    };
    const materialCostCol = {
        Header: 'Material Cost',
        accessor: 'material_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.material_cost_str) ? row.original.material_cost_str : '0.00'}</span>
    };
    const receivingCostCol = {
        Header: 'Receiving Cost',
        accessor: 'receiving_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.receiving_cost_str) ? row.original.receiving_cost_str : '0.00'}</span>
    };
    const totalCostCol = {
        Header: 'Total',
        accessor: 'total_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.total_cost) ? row.original.total_cost : '0.00'}</span>
    };

    const listColumns = [
        showButtonColumn,
        editButtonColumn,
        {
            Header: 'Receipt ID',
            accessor: 'id',
            sortType: 'basic',
            width: 90,
            maxWidth: 90,
        },
        {
            Header: 'Date',
            accessor: 'created_on',
            sortType: 'basic',
            disableFilters: true,
            Cell: ({row}) => <span>{(row.original.created_on_string) ? row.original.created_on_string : ''}</span>
        }
    ];

    // Exclude columns based on Filter By (Client, Billing Reference)
    if (fv?.filter_by) {
        if (
            (!fv.filter_org_id) ||
            (fv.filter_by !== 'client')
        ) {
            listColumns.push(clientCol);
        }
    } else {
        // If Filter By is unselected, display columns
        listColumns.push(clientCol);
    }

    listColumns.push(
        billRefCol,
        {
            Header: 'Created By',
            accessor: 'created_by',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.created_by) ? row.original.created_by : '(Not Completed)'}</span>
        },
        {
            Header: 'Tracking #',
            accessor: 'tracking_number',
            sortType: 'basic',
            width: 120,
            maxWidth: 120,
        },
        {
            Header: 'Approved',
            accessor: 'approved',
            sortType: 'basic',
            disableFilters: true,
            width: 90,
            maxWidth: 90,
            Cell: ({row}) => (
                <BooleanField record={row.original} label="" source="approved"/>
            ),
        },
        {
            Header: 'Approved by',
            accessor: 'approved_by',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.approved_by) ? row.original.approved_by : ''}</span>
        }
    );


    // Include if in Billing viewing mode
    if (fv?.filter_list_view === 'billing') {
        listColumns.push(
            {
                Header: 'Billed',
                accessor: 'billed',
                sortType: 'basic',
                disableFilters: true,
                width: 90,
                maxWidth: 90,
                Cell: ({row}) => (
                    <BooleanField record={row.original} label="" source="billed"/>
                ),
            },
            {
                Header: 'Billed by',
                accessor: 'billed_by_name',
                sortType: 'basic',
                Cell: ({row}) => <span>{(row.original.billed_by_name) ? row.original.billed_by_name : ''}</span>
            }
        );
        listColumns.push(laborCostCol, materialCostCol, receivingCostCol, courierCostCol, totalCostCol);
    }

    return (
        <List
            title={ListTitle}
            sort={listSort}
            perPage={listPagination.perPage}
            actions={<ListActions/>}
            filters={<ListFilter setFilters={lc.setFilters}/>}
            filterDefaultValues={filterDefaultValues}
            bulkActionButtons={false}
            pagination={false}
            {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.org_name}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable
                    columns={listColumns}
                    sort={listSort}
                    listFilters="filterValues"
                    listFilter="filter_by"
                    messageFilter="Select a Filter to view Receipts."
                    messageEmpty=" No Receipts found. Please select a filter to view Receipts."
                    storeForExport={true}
                    manualQuickFilters={true}
                    manualSortAndPagination={true}
                    {...props}
                />
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title" alt="Receipts"><ReceiptIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `Receipt #${record.id}` : 'Receipt'}</span>;
};

const ShowActions = ({
                         basePath,
                         className,
                         data,
                         hasEdit,
                         hasList
                     }) => {
    return (
        <TopToolbar className={className}>
            {hasList && <ListButton basePath={basePath}/>}
            {hasEdit && <EditButton basePath={basePath} record={data}/>}
            {data && data.id &&
                <>
                    <PrintReceiptLabelButton data={data}/>
                    <PrintToolbarButton record={data} type="Receipt"/>
                </>
            }
        </TopToolbar>
    )
};

export const ReceiptShow = (props) => {
    const controllerProps = useShowController(props);

    return (
        <Show title={<ShowTitle/>} actions={<ShowActions/>} {...props} {...controllerProps}>
            <SimpleShowLayout className="show-layout">
                <FieldDescription
                    text={`
                Review Receipt details below. To update this Receipt's status, click Edit above.
                `}
                    instructions={true} marginBottom={true}
                />
                <TextField label="Receipt ID" source="id" className="input-inline input-120"/>
                <TextField label="Tracking Number" source="tracking_number" className="input-inline"/>
                <TextField label="Client" source="org_name" className="input-inline"/>
                <FieldDivider type="break"/>
                <TextField label="Billing Reference" source="bill_ref_desc" className="field-bg"/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Receipt Details" instructions={true} marginBottom={true}/>
                <TextField label="Created By" source="created_by" className="input-inline input-220"/>
                <DateField label="Created On" source="created_on" className="input-inline input-220"/>
                <FieldDivider type="break"/>

                <TextField label="Admin Notes" source="notes" className="field-bg has-breaks"/>
                <FieldDivider type="break" margin="bottom"/>

                <BooleanField label="Receipt Approved/Final" source="approved" className="input-inline input-220"/>
                <TextField label="Approved By" source="approved_by" className="input-inline input-220"/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Products in Receipt" instructions={true} marginBottom={true}/>
                <ReceiptItemListViewContainer
                    view="show"
                    receiptData={controllerProps.record}
                    hasDelete={false}
                    hasEditName={true}
                    hasEditQty={false}
                    {...props}
                />

                <FieldDivider type="divider" margin="top"/>
                <FieldDescription text="Billing" instructions={true} marginBottom={true}/>

                <BooleanField label="Billed?" source="billed" className="input-inline input-220"/>
                <TextField label="Receiving Cost" source="receiving_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <TextField label="Billed By:" source="billed_by_name" className="input-inline input-220"/>
                <TextField label="Labor Cost" source="labor_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <DateField label="Invoice Date" source="invoice_date" className="input-inline input-220"/>
                <TextField label="Material Cost" source="material_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <TextField label="Labor Time" source="labor_time" className="input-inline input-220"/>
                <TextField label="Courier Cost" source="courier_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <span className="input-inline input-220">
                </span>
                <OrderReceiptCostTotals
                    type="receipt"
                    record={controllerProps.record}
                    className="input-right input-inline input-120 input-border-left input-margin-left"
                />
                <FieldDivider type="break"/>

            </SimpleShowLayout>
        </Show>
    )
};

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ReceiptIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit Receipt {record ? `${record.id}` : 'Receipt'}</span>;
};

const EditActions = ({
                         basePath,
                         className,
                         data,
                         hasList,
                         hasShow,
                         resource
                     }) => (
    <TopToolbar className={className}>
        {hasList && <ListButton basePath={basePath}/>}
        {hasShow && <ShowButton basePath={basePath} record={data}/>}
        {data && data.id &&
            <>
                <PrintReceiptLabelButton data={data}/>
                <PrintToolbarButton record={data} type="Receipt"/>
            </>
        }
    </TopToolbar>
);

const EditToolbar = props => {
    return (
        <Toolbar {...props} classes={useToolbarStyles()}>
            <SaveButton label="Save Receipt"/>
        </Toolbar>
    )
};

export const ReceiptEdit = (props) => (
    <Edit title={<EditTitle/>} actions={<EditActions/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`Edit Receipt details below.`}
            />
            <TextField label="Receipt ID" source="id" formClassName="input-inline input-120"/>
            <TextField label="Client" source="org_name" formClassName="input-inline input-320"/>
            <FieldDivider type="break"/>

            <TextField label="Billing Reference" source="bill_ref_desc" formClassName="input-inline input-280"/>
            <FormDataConsumer formClassName="input-inline input-320" {...props}>
                {({formData, ...rest}) => (
                    <SelectFromListInput
                        inAdminForm={true}
                        source="new_bill_ref_id"
                        resourceName="BillingReference"
                        listLabel="Change Billing Reference (optional)"
                        itemPlural="Billing References"
                        listFilters={{
                            active: true,
                            params: {
                                filter: {
                                    filter_org_id: formData.org_id
                                },
                            }
                        }}
                        className="input-inline input-320"
                        isRequired={false}
                        returnType="string"
                    />
                )}
            </FormDataConsumer>
            <FieldDivider type="break"/>
            <FieldDescription
                instructions={false} marginBottom={false}
                text={`Remove all Billing References for this Receipt`}
            />
            <BooleanInput source="clear_bill_ref_id" label="Clear Billing Reference"
                          formClassName="input-inline input-320"/>

            {/* Edit: Receipt Details */}

            <FieldDivider type="divider"/>
            <FieldDescription text="Receipt Details" instructions={true} marginBottom={true}/>
            <TextField label="Created By" source="created_by" formClassName="input-inline input-280"/>
            <DateField label="Created On" source="created_on" formClassName="input-inline input-220"/>
            <FieldDivider type="break"/>

            <TextInput label="Tracking Number" source="tracking_number" multiline={true} fullWidth={true}/>

            <TextInput label="Admin Notes" source="notes" multiline fullWidth={true}/>

            {/* Edit: Products */}

            <FieldDivider type="divider"/>
            <FieldDescription text="Products in Receipt" instructions={true} marginBottom={true}/>
            <FormDataConsumer {...props}>
                {({formData, ...rest}) => {
                    return (
                        <>
                            <ReceiptItemListViewContainer
                                view="edit"
                                receiptData={formData}
                                hasDelete={true}
                                hasEditName={true}
                                hasEditQty={true}
                                {...props}
                            />
                        </>
                    )
                }}
            </FormDataConsumer>
            <FieldDivider type="break" margin="bottom"/>
            <FieldDivider type="break" margin="bottom"/>

            {/* Edit: Billing */}

            <FieldDivider type="divider"/>
            <FieldDescription text="Billing" instructions={true} marginBottom={true}/>
            <TextField label="" source="billed" formClassName="input-hidden"/>
            <FormDataConsumer formClassName="input-inline input-280 input-col" {...props}>
                {({formData, ...rest}) => (
                    <>
                        <BooleanInput source="set_billed" label="Billed?" formClassName="input-inline input-280"/>
                        <OrderUsernameField type="edit" record={formData}/>
                        <TextInput label="" source="billed_by_name" className="hidden"/>
                        <DateInput source="invoice_date" label="Invoice Date"
                                   formClassName="input-inline input-280"/>
                        <TextInput label="" source="set_billed_by" className="hidden"/>
                    </>
                )}
            </FormDataConsumer>
            <FormDataConsumer formClassName="input-inline input-border-left input-col" {...props}>
                {({formData, ...rest}) => (
                    <>
                        <FieldDescription
                            instructions={false} marginBottom={true}
                            text={`Labor is calculated at ${BILLING_LABOR_PER_HOUR_TITLE}.`}
                        />
                        <FieldDescription text="Labor Time" inline={true} type="input" style={{width: 120}}/>
                        <NumberInput label="Hours" source="labor_time_hours" step={1} min={0}
                                     className="input-right input-inline input-90"/>
                        <SelectInput
                            key="labor_time_mins" source="labor_time_mins" label="Minutes"
                            choices={BILLING_LABOR_MINUTES}
                            allowEmpty={false}
                            className="input-inline input-90"
                        />
                        <FieldDivider type="break"/>

                        <FieldDescription text="Labor Cost" inline={true} type="input" style={{width: 120}}/>
                        <NumberInput label="" source="labor_cost" step={0.1} min={0}
                                     className="input-right input-inline input-90"/>
                        <FieldDivider type="break"/>

                        <FieldDescription text="Receiving Cost" inline={true} type="input" style={{width: 120}}/>
                        <NumberInput label="" source="receiving_cost" step={0.1} min={0}
                                     className="input-right input-inline input-90"/>
                        <FieldDivider type="break"/>

                        <FieldDescription text="Material Cost" inline={true} type="input" style={{width: 120}}/>
                        <NumberInput label="" source="material_cost" step={0.1} min={0}
                                     className="input-right input-inline input-90"/>
                        <FieldDivider type="break"/>

                        <FieldDescription text="Courier Cost" inline={true} type="input" style={{width: 120}}/>
                        <NumberInput label="" source="courier_cost" step={0.1} min={0}
                                     className="input-right input-inline input-90"/>
                        <FieldDivider type="break"/>

                        <FieldDescription text="TOTALS" inline={true} type="input" style={{width: 120}}/>
                        <OrderReceiptCostTotals
                            type="receipt"
                            record={formData}
                            hasLabel={false}
                            className="input-right"
                        />
                    </>
                )}
            </FormDataConsumer>
            <FieldDivider type="break" margin="bottom"/>

            {/* Edit: Approve/Finalize Receipt */}

            <FormDataConsumer {...props}>
                {({formData, ...rest}) => {
                    return (
                        <>
                            {formData.approved &&
                                <FieldDescription
                                    instructions={false} marginBottom={true}
                                    text={`This Receipt has been reviewed and marked as Final. However, you may edit if needed.`}
                                />
                            }
                            {!formData.approved &&
                                <FieldDescription
                                    instructions={true} marginBottom={true}
                                    text={`Review this Receipt to ensure all Product data is correct. If this Receipt is complete, set "Finalize Receipt?".`}
                                />
                            }
                            <BooleanInput source="set_approved" label="Finalize Receipt?"
                                          formClassName="input-inline input-280"/>
                            <FieldDivider type="break"/>
                            <ReceiptUsernameField record={formData}/>
                            <TextInput label="Approved By:" source="approved_by" className="hidden"/>
                            <TextInput label="" source="set_approved_by" className="hidden"/>
                            <FieldDivider type="break" margin="bottom"/>
                        </>
                    )
                }}
            </FormDataConsumer>

            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Receipts List."
            />
        </SimpleForm>
    </Edit>
);
