export const tableStyles = {
    btn: {
        marginTop: -4,
        marginRight: 4,
        padding: 2
    },
    btnIcon: {
        padding: 2
    },
    btnInfo: {
        position: 'absolute',
        top: -4,
        right: 4,
        padding: 2
    },
    inputCheckbox: {
        padding: 2
    },
    inputQty: {
        width: 90
    },
    message: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    messageError: {
        fontSize: 16,
        color: '#b4212d'
    },
    table: {},
    tHead: {},
    tBody: {},
    tr: {},
    trAdded: {
        backgroundColor: 'rgba(180, 220, 180, 0.3)'
    },
    trEditing: {
        backgroundColor: 'rgba(230, 230, 180, 0.3)'
    },
    th: {
        display: 'table-cell',
        position: 'relative',
        float: 'left',
        width: 'calc(100% - 24px)',
        textAlign: 'left',
        lineHeight: '1.2',
        verticalAlign: 'bottom'
    },
    thRight: {
        display: 'table-cell',
        position: 'relative',
        width: 'calc(100% - 24px)',
        textAlign: 'right',
        lineHeight: '1.2',
        verticalAlign: 'bottom'
    },
    td: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
    },
    tdName: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        paddingRight: 34
    },
    tdRight: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        textAlign: 'right'
    },
    tdSku: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        whiteSpace: 'nowrap'
    }
};
