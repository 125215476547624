import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconList from '@material-ui/icons/List';

const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        icon: {
            fontSize: 18,
        },
    })
);

const ListPrimaryButton = ({basePath, className, label = 'List'}) => {
    const classes = useStyles();

    return (
        <Button
            component={Link}
            color="primary"
            variant="contained"
            to={basePath}
            label={label}
        >
            <IconList className={classes.leftIcon}/>
            {label}
        </Button>
    )
};

export default ListPrimaryButton;
