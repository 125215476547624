const PTTheme = {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    palette: {
        canvasColor: '#ffffff',
        primary1Color: '#3a4242',
        primary2Color: '#3a4242',
        primary3Color: '#147800',
        accent1Color: '#3a4242',
        accent2Color: '#f5f5f5',
        accent3Color: '#9e9e9e',
        textColor: '#212121',
        alternateTextColor: '#ffffff',
        tableRowAltColor: '#fafafa',
        borderColor: '#e0e0e0',
        lightTextColor: 'rgba(33,33,33,0.5)', // #212121
        disabledColor: 'rgba(33,33,33,0.3)', // #212121
        correctColor: '#66bb6a',
        errorColor: '#b4212d',
        toolBarTextColor: '#3f51b5'
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#508242'
            },
            outlined: {
                padding: '4px 14px'
            }
        },
        MuiCardActions: {
            root: {
                padding: '2px 10px 1px 12px'
            }
        },
        MuiCardContent: {
            root: {
                padding: '10px 14px 8px 14px'
            }
        },
        MuiCardHeader: {
            root: {
                padding: '10px 14px 6px 14px'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(240, 242, 241, 0.9)',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                }
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
                minWidth: 90,
                '&.third-width': {
                    minWidth: '33%'
                },
                '&.half-width': {
                    minWidth: '50%'
                },
                '&.full-width': {
                    width: '100%'
                }
            },
            fullWidth: {
                width: 'calc(100% - 1em)'
            },
            marginDense: {
                marginTop: 0
            }
        },
        MuiTableCell: {
            head: {
                whiteSpace: 'nowrap'
            },
            sizeSmall: {
                lineHeight: '1.3',
                padding: '6px 12px 5px 12px',
                verticalAlign: 'middle',
                '&.cell-icon': {
                    width: 60,
                    maxWidth: 60,
                    padding: '6px 10px 5px 10px',
                }
            }
        }
    }
};

export default PTTheme;
