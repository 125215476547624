import React from 'react';
import FieldDivider from './FieldDivider';
import {
    PrintBooleanValue,
    PrintDateField,
    PrintLongTextField,
    PrintTextField
} from './PrintFields';
import ReceiptItemListViewContainer from './ReceiptItemListViewContainer';

/**
 * Component containing Receipt data for printing
 *
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */
const ReceiptPrint = ({record}) => {

    return (
        <div className="content">
            <h1>Receipt #{record.id}</h1>

            <PrintTextField label="Receipt ID" value={record.id} classes="input-quarter"/>
            <PrintTextField label="Client" value={record.org_name} classes="input-half"/>
            <FieldDivider type="break"/>

            <h2>Receipt Details</h2>

            <PrintLongTextField label="Tracking Number" value={record.tracking_number}/>
            <FieldDivider type="break"/>

            <table className="table-print">
                <tbody>
                <tr>
                    <td>
                        <PrintTextField label="Created By" value={record.created_by}/>
                    </td>
                    <td>
                        <PrintDateField label="Created On" value={record.created_on}/>
                    </td>
                    <td>
                        <PrintBooleanValue label="Approved?" value={record.approved}/></td>
                    <td>
                        {record.approved_by ?
                            (
                                <PrintTextField label="Approved By" value={record.approved_by}/>
                            ) : null
                        }
                    </td>
                </tr>
                </tbody>
            </table>
            <FieldDivider type="break"/>

            {record.notes &&
                <PrintLongTextField label="Admin Notes" value={record.notes} classes="input-block has-bg has-breaks"/>
            }
            <FieldDivider type="break"/>

            <h2>Product List</h2>

            <ReceiptItemListViewContainer forPrint={true} hasEditQty={false} receiptData={record} receiptId={record.id}/>
        </div>
    )
};

export default ReceiptPrint;
