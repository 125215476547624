import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';
import {
    SimpleShowLayout,
    BooleanField,
    DateField,
    TextField,
} from 'react-admin';
import {RESOURCES as orderResources} from '../orderResources';
import {RESOURCES as receiptResources} from '../receiptResources';
import {getGetHeaders} from '../utils/headers';
import FieldDivider from './FieldDivider';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useDialogStyles = makeStyles({
    root: {
        minWidth: 500,
        margin: 0,
        padding: 12,
    },
    linkButton: {
        display: 'inline-block',
        margin: '4px 1em 2px 0'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 8,
        color: '#666666',
    },
});

/**
 * A Material UI Dialog to display Order or Receipt Details
 *
 * @param openDialog
 * @param itemId
 * @param itemType
 * @param onCloseFunc
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OrderReceiptDetailDialog = (
    {
        openDialog,
        itemId,
        itemType,
        onCloseFunc,
        ...props
    }) => {

    const [record, setRecord] = useState(null);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(
        () => {
            const fetchItem = () => {
                let cfg;
                if (itemType === 'Order') {
                    cfg = orderResources.Order.GET_ONE({id: itemId});
                }
                if (itemType === 'Receipt') {
                    cfg = receiptResources.Receipt.GET_ONE({id: itemId});
                }
                axios({
                    method: 'GET',
                    url: cfg.uri,
                    headers: getGetHeaders()
                }).then((response) => {
                    if (response.data) {
                        setRecord(response.data);
                        setOpen(true);
                        setError(false);
                    } else {
                        setRecord(null);
                        setError(true);
                        setOpen(true);
                    }
                }).catch((error) => {
                    // console.log('error response for ' + itemType + ': ', error);
                    if (error.response) {
                        // Server response outside 2xx
                    } else if (error.request) {
                        // No response
                    }
                    setRecord(null);
                    setError(true);
                    setOpen(true);
                });
            };

            // If current Item ID is set or has changed, fetch that Order or Receipt
            if (
                (itemId && !record) ||
                (itemId && record && (parseInt(record.id, 10) !== parseInt(itemId, 10)))
            ) {
                fetchItem();
            }

        }, [itemId, itemType, record]
    );

    const handleClose = () => {
        setOpen(false);
        setRecord(null);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const classes = useDialogStyles();
    let typeName = (itemType === 'Receipt') ? 'Receipt' : 'Order';
    let link = (itemType === 'Receipt') ? '/Receipt/' + itemId : '/Order/' + itemId;

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="Order-Receipt-Detail"
                open={open}
                transitionDuration={{
                    enter: 100,
                    exit: 150
                }}
            >
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">
                        {typeName} Detail
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    {record && !error &&
                        <>
                            <SimpleShowLayout record={record} className="show-modal">
                                <TextField label={`${typeName} ID`} source="id" className="input-inline input-900"/>
                                <TextField label="Client" source="org_name" className="input-inline input-320"/>
                                <TextField label="Order Status" source="order_status"
                                           className={(itemType === 'Order') ? 'input-inline input-120' : 'input-hidden'}/>
                                <FieldDivider type="divider"/>

                                <TextField label="Client Notes" source="client_notes"
                                           className={(itemType === 'Order') ? 'field-bg' : 'input-hidden'}/>
                                <TextField label="Admin Notes" source="notes" className="field-bg"/>
                                <TextField label="Shipping Destination" source="ship_to.name"
                                           className={(itemType === 'Order') ? 'field-bg' : 'input-hidden'}/>
                                <FieldDivider type="break"/>

                                <TextField label="Created By" source="created_by" className="input-inline input-220"/>
                                <DateField label="Created On" source="created_on" className="input-inline input-220"/>
                                <FieldDivider type="break"/>

                                <BooleanField label="Approved" source="approved"
                                              className={(itemType === 'Receipt') ? 'input-inline input-220' : 'input-hidden'}/>
                                <TextField label="Approved By" source="approved_by"
                                           className={(itemType === 'Receipt') ? 'input-inline input-220' : 'input-hidden'}/>
                                <FieldDivider type="break"/>

                                <DateField
                                    label="Last Modified On:" source="modified"
                                    options={{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }}
                                    className="field-bg"/>
                            </SimpleShowLayout>
                            <FieldDivider type="divider" margin="bottom"/>
                            <Button
                                variant="outlined"
                                component={Link}
                                to={`${link}/show`}
                                className={classes.linkButton}
                            >
                                View
                            </Button>
                            <Button
                                variant="outlined"
                                component={Link}
                                to={link}
                                className={classes.linkButton}
                            >
                                Edit {itemType}
                            </Button>
                        </>
                    }
                    {error &&
                        <p>
                            Sorry, this {itemType} could not be retrieved.
                        </p>
                    }
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default withRouter(OrderReceiptDetailDialog);
