import React, {useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import axios from 'axios';
import {
    TextInput,
    required
} from 'react-admin';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders, getPostHeaders} from '../utils/headers';
import {postProductImage} from '../utils/apiFunctions';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import OrgShowSelectFromListInput from './OrgShowSelectFromListInput';
import ProductImage from '../components/ProductImage';
import SelectFromListInput from './SelectFromListInput';
import {useDialogStyles, useLoadingStyles} from '../global/dialogStyles';
import {viewStyles} from '../global/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const validateName = [required()];

/**
 * A Material UI Dialog to display a Product Create form
 *
 * @param openDialog
 * @param onCloseFunc
 * @param onCompleteFunc
 * @param selectedOrg
 * @param selectedOrgId
 * @param success
 * @param forReceipt
 * @returns {JSX.Element}
 * @constructor
 */
const ProductCreateDialog = (
    {
        openDialog,
        onCloseFunc,
        onCompleteFunc,
        selectedOrg,
        selectedOrgId,
        success,
        forReceipt = true
    }) => {

    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState({
        error: false,
        message: null
    });

    // Effect: Open dialog from parent
    useEffect(() => {
        if (openDialog) {
            setFormMessage({
                error: false,
                message: null
            });
        }
        return setOpen(openDialog);
    }, [openDialog]);

    const validateForm = (values) => {
        return !(
            !values.new_product_show_id ||
            !values.new_product_type_id ||
            !values.new_product_title
        );
    };

    const assembleFormData = (values) => {
        const v = values;
        return {
            description: (v.new_product_description) ? v.new_product_description : '',
            notes: (v.new_product_notes) ? v.new_product_notes : '',
            qty: 0,
            org_id: (v.new_product_org_id) ? v.new_product_org_id : selectedOrgId,
            show_id: v.new_product_show_id,
            title: v.new_product_title,
            type_id: v.new_product_type_id,
            active: true
        };
    };

    const handleSubmit = (values) => {
        const formValues = assembleFormData(values);
        const imageInput = values.product_image_input ?? null;
        let errorMessage = 'Sorry, there was an error creating this Product.';
        const resource = resources.Product.CREATE(formValues);
        setSubmitting(true);
        axios({
            method: resource.method,
            url: resource.uri,
            data: formValues,
            headers: getPostHeaders(true)
        }).then((response) => {
            if (response.data && response.data.id) {
                setSubmitting(false);
                // Post image if uploaded
                if (imageInput && response.data.id) {
                    setFormMessage({
                        error: false,
                        message: 'Product created, uploading image...'
                    });
                    submitProductImage(values, response.data, imageInput);
                } else if (forReceipt) {
                    // Get new Product with all data
                    setFormMessage({
                        error: false,
                        message: 'Product created. Adding to available Products...'
                    });
                    getNewProduct(response.data.id, values);
                } else {
                    // Complete creation
                    setFormMessage({
                        error: false,
                        message: 'Product created.'
                    });
                    completeProduct(response.data);
                }
            } else {
                setSubmitting(false);
                setFormMessage({
                    error: true,
                    message: errorMessage
                });
            }
        }).catch((error) => {
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
            setSubmitting(false);
            setFormMessage({
                error: true,
                message: errorMessage
            });
        });
    };

    const onSaveProgress = (e) => {
    };

    const submitProductImage = (values, data, imageInput) => {
        if (imageInput && data.id) {
            data.product_image_input = imageInput;
            postProductImage(data, onSaveProgress).then(result => {
                if (forReceipt) {
                    // Get new Product with all data
                    setFormMessage({
                        error: false,
                        message: 'Product image uploaded. Adding to available Products...'
                    });
                    getNewProduct(data.id, values);
                } else {
                    // Complete creation
                    setFormMessage({
                        error: false,
                        message: 'Product created.'
                    });
                    completeProduct(data);
                }
            }).catch(error => {
                setFormMessage({
                    error: true,
                    message: 'Sorry, there was an error uploading this Product\'s image.'
                });
            });
        }
    };

    const getNewProduct = (id, values) => {
        const cfg = resources.Product.GET_ONE({id: id});
        axios({
            method: 'GET',
            url: cfg.uri,
            headers: getGetHeaders()
        }).then((response) => {
            if (response.data && response.data.id) {
                if (forReceipt) {
                    response.data.num_boxes = 1;
                    response.data.product_id = response.data.id;
                }
                completeProduct(response.data);
            }
        }).catch((error) => {
            console.log('error response for Product: ', error);
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
            completeProduct(null);
        });
    };

    const completeProduct = (data) => {
        if (typeof onCompleteFunc === 'function') {
            onCompleteFunc(data);
        }
        if (!data) {
            setFormMessage({
                error: true,
                message: 'Sorry, there was an error adding this Product.'
            });
        } else {
            setFormMessage({
                error: false,
                message: 'Product created.'
            });
        }
        setTimeout(() => {
            handleClose(null);
        }, 1000);
    };

    const handleClose = () => {
        setOpen(false);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const classes = useDialogStyles();
    const loadingClasses = useLoadingStyles();
    let title = 'Create Product';
    let instructions = 'Add a Product by selecting a';
    if (!selectedOrgId) {
        instructions += ' Client, Title and Category, and adding a Name.';
    } else {
        instructions += ' Title and Category, and adding a Name.';
        if (selectedOrg && selectedOrg.name) {
            title += ' for ' + selectedOrg.name;
        }
    }

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={false}
                onClose={handleClose}
                aria-labelledby="Product-Create"
                open={open}
                className={classes.root}
                transitionDuration={{
                    enter: 100,
                    exit: 150
                }}
            >
                <MuiDialogTitle disableTypography className={classes.title}>
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <Form
                        onSubmit={handleSubmit}
                        mutators={{
                            setValue: ([field, value], state, {changeValue}) => {
                                changeValue(state, field, () => value)
                            }
                        }}
                        render={({form, handleSubmit, values}) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={instructions}
                                    />
                                    <OrgShowSelectFromListInput
                                        selectOrg={!selectedOrgId}
                                        selectedOrgId={selectedOrgId}
                                        orgLabel="Select a Client"
                                        orgSrc="new_product_org_id"
                                        showLabel="Select a Title"
                                        showSrc="new_product_show_id"
                                        addLabel={false}
                                        inline={true}
                                        className="input-inline input-400"
                                        includeTitleCreation={true}
                                        isRequired={true}
                                    />
                                    <FieldDivider type="break" margin="bottom"/>

                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text="Select a Category for this Product. If no specific Category applies, you may select a top-level Category."
                                    />
                                    <SelectFromListInput
                                        inAdminForm={true}
                                        source="new_product_type_id"
                                        resourceName="ProductType"
                                        listLabel="Select a Category"
                                        itemPlural="Categories"
                                        listFilters={{
                                            active: true
                                        }}
                                        className="input-block input-400"
                                        isRequired={true}
                                        returnType="string"
                                    />
                                    <FieldDivider type="break" margin="bottom"/>

                                    <TextInput label="Name of Product" source="new_product_title"
                                               validate={validateName}
                                               fullWidth={true}/>
                                    <TextInput label="Description of Product" source="new_product_description"
                                               multiline fullWidth={true}/>
                                    <ProductImage renderType="create" record={values} allowUpload={true}/>
                                    <TextInput label="Admin Notes" source="new_product_notes"
                                               multiline fullWidth={true}/>
                                    <FieldDivider type="break" margin="bottom"/>
                                    <FieldDescription
                                        marginBottom={true}
                                        description={true}
                                        text="After clicking Create Product, this Product should be available to select."
                                    />
                                    <hr style={viewStyles.break}/>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        style={viewStyles.buttonInline}
                                        disabled={(validateForm(values) === false || submitting || success)}
                                        onClick={() => handleSubmit()}
                                    >
                                        Create Product
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={viewStyles.buttonInline}
                                        disabled={(submitting || success)}
                                        onClick={() => handleClose()}
                                    >
                                        Cancel
                                    </Button>
                                    {submitting &&
                                        <>
                                            <CircularProgress size={30} className={loadingClasses.root}/>
                                            <p className={loadingClasses.text}>Creating Product...</p>
                                        </>
                                    }
                                    {formMessage.message &&
                                        <p className={(formMessage.error) ? loadingClasses.textError : loadingClasses.text}>
                                            {formMessage.message}
                                        </p>
                                    }
                                </form>
                            )
                        }}
                    >
                    </Form>
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default ProductCreateDialog;
