import React, {useEffect, useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import OrderReceiptItemDeleteDialog from './OrderReceiptItemDeleteDialog';
import ProductEditTitle from './ProductEditTitle';
import ProductThumbnail from './ProductThumbnail';
import ProductDetailDialog from '../components/ProductDetailDialog';
import ProductEditDialog from './ProductEditDialog';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoIcon from '@material-ui/icons/Info';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles({
    btn: {
        marginTop: -4,
        marginRight: 0,
        padding: 2
    },
    btnEdit: {
        marginTop: -3,
        marginRight: 0,
        marginLeft: 4,
        padding: 2
    },
    btnEditNotes: {
        display: 'inline-block',
        position: 'absolute',
        top: 4,
        left: 0,
        padding: 2
    },
    btnInfo: {
        position: 'absolute',
        top: '50%',
        right: 4,
        padding: 2,
        transform: 'translateY(-50%)'
    },
    btnText: {
        marginRight: 6,
        padding: '0px 10px'
    },
    message: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    checkbox: {
        '& .MuiSvgIcon-root': {
            fontSize: 28
        }
    },
    messageError: {
        fontSize: 16,
        color: '#b4212d'
    },
    rowLocation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap'
    },
    rowLocationLink: {
        color: '#2d5033',
        fontWeight: 600,
    },
    rowLocationLinkEmpty: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 600,
    },
    saving: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    table: {},
    tHead: {},
    tBody: {},
    tr: {},
    trNotes: {
        backgroundColor: 'rgba(0, 0, 0, 0.03)'
    },
    th: {
        lineHeight: '1.2',
        verticalAlign: 'bottom',
        padding: '5px 5px 5px 5px'
    },
    thNotes: {
        position: 'relative',
        fontWeight: 500,
        padding: '10px 8px 9px 32px'
    },
    td: {
        position: 'relative',
        padding: '5px 5px 5px 5px',
        whiteSpace: 'normal',
        wordBreak: 'normal'
    },
    tdName: {
        position: 'relative',
        whiteSpace: 'normal',
        wordBreak: 'normal',
        padding: '5px 5px 5px 5px'
    },
    tdSmall: {
        position: 'relative',
        whiteSpace: 'normal',
        wordBreak: 'normal',
        padding: '5px 8px 5px 8px'
    },
    tdNoWrap: {
        position: 'relative',
        padding: '5px 5px 5px 5px',
        whiteSpace: 'nowrap'
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
});

/**
 * A component to display a list of Products for a Receipt
 *
 * @param onDeleteFunc
 * @param onEditNameFunc
 * @param onEditProductFunc
 * @param onEditQtyFunc
 * @param onEditNotesFunc
 * @param onRemoveFunc
 * @param itemList
 * @param itemToUpdate
 * @param hasEditName
 * @param hasEditProduct
 * @param hasEditQty
 * @param hasEditNotes
 * @param hasDelete
 * @param hasInfo
 * @param hasRemove
 * @param listType
 * @param saving
 * @param error
 * @return {JSX.Element}
 * @constructor
 */
const ReceiptItemsList = ({
                              onDeleteFunc,
                              onEditNameFunc,
                              onEditProductFunc,
                              onEditQtyFunc,
                              onEditNotesFunc,
                              onRemoveFunc,
                              itemList = [],
                              itemToUpdate = null,
                              hasEditName = false,
                              hasEditProduct = true,
                              hasEditQty = false,
                              hasEditNotes = false,
                              hasDelete = false,
                              hasInfo = true,
                              hasRemove = true,
                              listType = 'edit',
                              saving = false,
                              error = null
                          }) => {

    const [currentItem, setCurrentItem] = useState(null);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [currentNoteItem, setCurrentNoteItem] = useState(null);
    const [qty, setQty] = useState(-1);
    const [newQty, setNewQty] = useState(-1);
    const [numBoxes, setNumBoxes] = useState(-1);
    const [saveRequest, setSaveRequest] = useState(false);
    const [saveMessage, setSaveMessage] = useState({
        error: false,
        message: ''
    });
    // State: Product Edit Item with Dialog
    const [itemEditState, setItemEditState] = useState({
        dialog: false,
        item: null
    });
    // State: Receipt Item to Delete with Dialog
    const [itemDeleteState, setItemDeleteState] = useState({
        dialog: false,
        item: null
    });
    // State: Product Detail Item with Dialog
    const [itemDetailState, setItemDetailState] = useState({
        dialog: false,
        item: null
    });

    // Effect: Reset items after an item has been updated
    useEffect(
        () => {
            if (currentItem && saveRequest && !saving) {
                handleEditQtyClose();
                setSaveMessage({error: false, message: (error) ? error : 'Item saved.'})
            }
        }, [currentItem, saveRequest, saving, error]
    );

    // All Views: Handle event from Info button
    const handleInfoClick = (item) => {
        setItemDetailState({dialog: !!(item), item: item});
    };

    const handleCloseItemDialog = () => {
        setItemDetailState({dialog: false, item: null});
    };

    // Create View: Handle event from Remove Item button
    const handleRemoveClick = (id) => {
        if (typeof onRemoveFunc === 'function') {
            // Create Receipt form needs to update its Available Products table
            if (listType === 'create') {
                let idx = itemList.findIndex(r => r.product_id === id);
                if (idx !== -1) {
                    itemList[idx].qty_requested = 0;
                    itemList[idx].num_boxes = 1;
                    onRemoveFunc({
                        item: itemList[idx],
                        new_qty: 0,
                        qty_requested: 0,
                        num_boxes: 1,
                        add: false,
                        added: false,
                        updateAvailable: true
                    });
                }
            } else {
                onRemoveFunc(id);
            }
        }
    };

    // Edit View: Handle event from Edit Product button
    const handleEditProductOpen = (item) => {
        setItemEditState({dialog: !!(item), item: item});
    };

    // Edit View: Handle event from Edit Qty button
    const handleEditQtyOpen = (id) => {
        let idx = itemList.findIndex(r => r.product_id === id);
        if (idx !== -1) {
            const qty = itemList[idx].qty;
            const num_boxes = itemList[idx].num_boxes;
            // Display the New Qty and New Num Boxes inputs
            setQty(qty);
            setNewQty(qty);
            setNumBoxes(num_boxes);
            setCurrentItem(itemList[idx]);
            setCurrentItemId(id);
            setSaveMessage({error: false, message: ''});
        }
    };

    // Edit View: Handle event from change in New Qty or New Box Number fields
    const handleEditChange = (e, type, id) => {
        const value = (e.target && e.target.value) ? e.target.value : '';
        let updatedValue = value;
        if (value) {
            updatedValue = parseInt(value, 10);

        }
        if (type === 'qty') {
            setNewQty(updatedValue);
        }
        if (type === 'num_boxes') {
            setNumBoxes(updatedValue);
        }
    };

    // Edit View: Handle event from Qty Save button
    const handleSaveEditQty = () => {
        if (typeof onEditQtyFunc === 'function') {
            setSaveRequest(true);
            currentItem.qty_requested = newQty;
            currentItem.num_boxes = numBoxes;
            onEditQtyFunc({
                item: currentItem,
                new_qty: newQty,
                num_boxes: numBoxes,
                add: true,
                updateAvailable: true
            });
        }
    };

    const handleEditProduct = (product) => {
        if (typeof onEditProductFunc === 'function') {
            onEditProductFunc(product);
        }
    };

    const handleEditProductName = (product) => {
        if (typeof onEditNameFunc === 'function') {
            onEditNameFunc(product);
        }
    };

    // Edit View: Handle event from Edit Qty Cancel button
    const handleEditQtyClose = () => {
        setQty(-1);
        setNewQty(-1);
        setNumBoxes(-1);
        setCurrentItem(null);
        setCurrentItemId(null);
        setSaveRequest(false);
    };

    const handleEditNotesClick = (item) => {
        setCurrentNoteItem(item);
    };

    const handleUpdateNotes = (e, item) => {
        item.notes = e.target.value;
        setCurrentNoteItem(item);
    };

    const handleSaveNotes = () => {
        if (typeof onEditNotesFunc === 'function') {
            onEditNotesFunc({item: currentNoteItem});
        }
        setCurrentNoteItem(null);
    };

    // Edit View: Handle event from Delete Item button
    const handleDeleteDialogClick = (item) => {
        setItemDeleteState({dialog: !!(item), item: item});
    };

    const handleDelete = () => {
        const item = itemDeleteState.item;
        if (typeof onDeleteFunc === 'function') {
            setSaveRequest(true);
            onDeleteFunc(item.id);
        }
        handleDeleteDialogClose();
    };

    const handleEditDialogClose = () => {
        setItemEditState({dialog: false, item: null});
    };

    const handleDeleteDialogClose = () => {
        setItemDeleteState({dialog: false, item: null});
    };

    // Generate rows of Locations per item row
    const setRowLocations = (locations) => {
        if (!locations) {
            locations = [{id: 0, location: "(None)", qty: 0}];
        }

        return (
            locations.map((item, index) => {
                const isEmpty = (item.qty < 1);
                const locClass = (isEmpty) ? classes.rowLocationLinkEmpty : classes.rowLocationLink;
                return (
                    <span key={`receipt-item-locations-${item.id}-${index}`} className={classes.rowLocation}>
                        {item.id === 0 &&
                            <span>(None)</span>
                        }
                        {item.id !== 0 &&
                            <>
                                <Link to={`/WarehouseLocation/${item.location}/show`}
                                      title={`Location ${item.location}`} className={locClass}>
                                    {item.location}
                                </Link>
                            </>
                        }
                    </span>
                    )
                }
            )
        )
    };

    // Generate Receipt Item list rows
    const setRows = () => {
        const list = itemList;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Items Added</span>
            )
        }
        let cols = 4;
        if (hasRemove) {
            cols += 1;
        }
        if (hasEditQty) {
            cols += 2;
        }
        if (hasDelete) {
            cols += 1;
        }

        return (
            itemList.map((item, index) => (
                <Fragment key={`receipt-item-group-${item.title}-${index}`}>
                    <TableRow className={classes.tr} key={`receipt-item-${item.title}-${index}`}>
                        {hasEditQty &&
                            <TableCell className={classes.tdSmall}>
                                <Checkbox
                                    color="primary"
                                    className={classes.checkbox}
                                />
                            </TableCell>
                        }
                        <TableCell className="td-sku">
                            {item.sku}
                            {hasInfo &&
                                <IconButton
                                    aria-label="Info"
                                    className={classes.btnInfo}
                                    onClick={() => handleInfoClick(item)}
                                >
                                    <InfoIcon/>
                                </IconButton>
                            }
                            {hasEditProduct &&
                                <IconButton
                                    aria-label="Edit Product"
                                    className={classes.btnEdit}
                                    onClick={() => handleEditProductOpen(item)}
                                    disabled={!!(currentItemId && currentItemId !== item.product_id)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            }
                        </TableCell>

                        <TableCell className={classes.td}>
                            <ProductThumbnail id={item.id} label="Image" title={item.title} thumbnail={item.thumbnail}/>
                        </TableCell>

                        <TableCell className="td-title">{item.show_name}</TableCell>

                        <TableCell className={classes.td}>{item.type_name}</TableCell>

                        <TableCell className={classes.tdName}>
                            {!hasEditName &&
                                <span>{item.title} &nbsp;</span>
                            }
                            {hasEditName &&
                                <ProductEditTitle item={item} onSaveEditProductTitle={(e) => handleEditProductName(e)}/>
                            }
                        </TableCell>

                        <TableCell className={classes.td}>
                            {[...setRowLocations(item.warehouse_locations)]}
                        </TableCell>

                        {!hasEditQty &&
                            <TableCell align="right" className={classes.tdNoWrap}>{item.num_boxes}</TableCell>
                        }
                        {hasEditQty &&
                            <TableCell align="right" className={classes.td}>
                                {(!currentItemId || currentItemId !== item.product_id) &&
                                    <span className={classes.noWrap}>{item.num_boxes}</span>
                                }
                                {currentItemId && currentItemId === item.product_id &&
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        id={`edit-receipt-item-box-number-${index}`}
                                        label=""
                                        defaultValue={numBoxes}
                                        disabled={(saving)}
                                        className="input-table-small number mr-0"
                                        onChange={(e) => handleEditChange(e, 'num_boxes', item.product_id)}
                                        InputProps={{
                                            inputProps: {
                                                min: 1,
                                                step: 1
                                            }
                                        }}
                                    />
                                }
                            </TableCell>
                        }

                        {!hasEditQty &&
                            <TableCell align="right" className={classes.tdNoWrap}>{item.qty}</TableCell>
                        }
                        {hasEditQty &&
                            <TableCell align="right" className={classes.td}>
                                {(!currentItemId || currentItemId !== item.product_id) &&
                                    <span className={classes.noWrap}>{item.qty}</span>
                                }
                                {currentItemId && currentItemId === item.product_id &&
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        id={`edit-receipt-item-qty-${index}`}
                                        label=""
                                        defaultValue={newQty}
                                        disabled={(saving)}
                                        className="input-table-small number mr-0"
                                        onChange={(e) => handleEditChange(e, 'qty', item.product_id)}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                                step: 1
                                            }
                                        }}
                                    />
                                }
                            </TableCell>
                        }

                        {hasEditQty &&
                            <TableCell className={classes.td}>
                                {(!currentItemId || currentItemId !== item.product_id) &&
                                    <IconButton
                                        aria-label="Edit"
                                        className={classes.btn}
                                        onClick={() => handleEditQtyOpen(item.product_id)}
                                        disabled={!!(currentItemId && currentItemId !== item.product_id)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                }
                                {currentItemId && currentItemId === item.product_id &&
                                    <>
                                        {!saving &&
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btnText}
                                                    startIcon={<SaveIcon/>}
                                                    onClick={() => handleSaveEditQty()}
                                                    disabled={(qty === newQty && numBoxes === item.num_boxes)}
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="default"
                                                    className={classes.btnText}
                                                    startIcon={<CloseIcon/>}
                                                    onClick={() => handleEditQtyClose()}
                                                >
                                                    Cancel
                                                </Button>
                                            </span>
                                        }
                                        {saving &&
                                            <span className={classes.saving}>Saving...</span>
                                        }
                                    </>
                                }
                            </TableCell>
                        }

                        {hasRemove &&
                            <TableCell className={classes.td}>
                                <IconButton
                                    aria-label="Remove"
                                    className={classes.btn}
                                    onClick={() => handleRemoveClick(item.product_id)}
                                >
                                    <RemoveCircleOutlineIcon/>
                                </IconButton>
                            </TableCell>
                        }

                        {hasDelete &&
                            <TableCell className={classes.td}>
                                <IconButton
                                    aria-label="Delete"
                                    className={classes.btn}
                                    onClick={() => handleDeleteDialogClick(item)}
                                    disabled={!!(currentItemId)}
                                >
                                    <HighlightOffIcon/>
                                </IconButton>
                            </TableCell>
                        }
                    </TableRow>
                    {(item.notes !== null && (hasEditNotes || item.notes !== '')) &&
                        <TableRow className={classes.trNotes} key={`receipt-item-notes-${item.title}-${index}`}>
                            <TableCell colSpan={2} className={classes.td}></TableCell>
                            <TableCell align="right" className={classes.thNotes}>
                                {hasEditNotes &&
                                    <IconButton
                                        aria-label="Edit Notes"
                                        className={classes.btnEditNotes}
                                        onClick={() => handleEditNotesClick(item)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                }
                                <span>Notes:</span>
                            </TableCell>
                            <TableCell colSpan={cols} className={classes.td}>
                                {(!currentNoteItem || currentNoteItem.product_id !== item.product_id) &&
                                    <span>
                                        {item.notes}
                                    </span>
                                }
                                {currentNoteItem && currentNoteItem.product_id === item.product_id &&
                                    <>
                                        <TextField
                                            variant="outlined"
                                            id={`edit-product-item-notes-${item.id}`}
                                            label=""
                                            defaultValue={item.notes}
                                            className="input-table-small update mr-1"
                                            onChange={(e) => handleUpdateNotes(e, item)}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.btnText}
                                            startIcon={<SaveIcon/>}
                                            onClick={() => handleSaveNotes()}
                                        >
                                            Update
                                        </Button>
                                    </>
                                }
                            </TableCell>
                            <TableCell className={classes.td}></TableCell>
                        </TableRow>
                    }
                </Fragment>
            ))
        )
    };

    const classes = useStyles();

    if (itemList.length === 0) {
        return (
            <div className="receipt-item-list-container">
                <Table className={classes.table} size="small" stickyHeader aria-label="Added Items">
                    <TableHead className={classes.tHead}>
                        <TableRow>
                            <TableCell className={classes.th}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tBody}>
                        <TableRow className={classes.tr}>
                            <TableCell className={classes.th}>No Products in this Receipt</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        )
    }

    return (
        <>
            {itemList.length > 0 &&
                <div className="receipt-item-list-container">
                    <Table className={classes.table} size="small" stickyHeader aria-label="Added Items">
                        <TableHead className={classes.tHead}>
                            <TableRow>
                                {hasEditQty &&
                                    <TableCell className={classes.th}></TableCell>
                                }
                                <TableCell className={classes.th}>SKU</TableCell>
                                <TableCell className={classes.th}>Image</TableCell>
                                <TableCell className={classes.th}>Title</TableCell>
                                <TableCell className={classes.th}>Category</TableCell>
                                <TableCell className={classes.th}>Product</TableCell>
                                <TableCell className={classes.th}>Locations</TableCell>
                                <TableCell align="right" className={classes.th}># Boxes</TableCell>
                                <TableCell align="right" className={classes.th}>
                                    <span className="hide-print">Received<br/></span>Qty
                                </TableCell>
                                {hasEditQty &&
                                    <TableCell className={classes.th}>Edit<br/>Qty</TableCell>
                                }
                                {hasRemove &&
                                    <TableCell className={classes.th}>Remove</TableCell>
                                }
                                {hasDelete &&
                                    <TableCell className={classes.th}>Delete</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tBody}>
                            {[...setRows()]}
                        </TableBody>
                    </Table>
                    {saveMessage.message !== '' &&
                        <span className={(saveMessage.error) ? classes.messageError : classes.message}>
                            {saveMessage.message}
                        </span>
                    }
                    <ProductDetailDialog
                        openDialog={itemDetailState.dialog}
                        productId={(itemDetailState.item) ? itemDetailState.item.product_id : null}
                        onCloseFunc={handleCloseItemDialog}
                    />
                    <ProductEditDialog
                        openDialog={itemEditState.dialog}
                        productId={(itemEditState.item) ? itemEditState.item.product_id : null}
                        onCompleteFunc={(e) => handleEditProduct(e)}
                        onCloseFunc={() => handleEditDialogClose()}
                    />
                    <OrderReceiptItemDeleteDialog
                        type="Receipt"
                        openDialog={itemDeleteState.dialog}
                        item={(itemDeleteState.item) ? itemDeleteState.item : null}
                        onCloseFunc={() => handleDeleteDialogClose()}
                        onDeleteFunc={() => handleDelete()}
                    />
                </div>
            }
        </>
    )
}

export default ReceiptItemsList;
