import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import {sortArrayByKey} from '../utils/dataFunctions';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import InventoryLedgerTableRow from './InventoryLedgerTableRow';
import OrderReceiptDetailDialog from './OrderReceiptDetailDialog';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useLedgerTableStyles = makeStyles((theme) => ({
    accordionDetail: {
        flexDirection: 'column'
    },
    accordionSummary: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    },
    tbody: {
        maxHeight: 200,
        overflow: 'auto'
    },
    th: {
        lineHeight: '1.4',
        padding: '8px 12px 8px 12px',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '8px 12px 8px 12px'
    },
    tdIcon: {
        width: 122,
        maxWidth: 122,
        position: 'relative',
        padding: '4px 8px 4px 8px'
    },
    tdNum: {
        width: 90,
        maxWidth: 90,
        position: 'relative',
        padding: '4px 8px 4px 8px'
    },
}));

/**
 * A component for retrieving and displaying an Inventory Ledger List
 *
 * @param record
 * @param allowAdjustment
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const InventoryLedgerTable = ({record, allowAdjustment = false, ...props}) => {

    const [listPopulated, setListPopulated] = useState(false);
    const [ledgerList, setLedgerList] = useState({
        entries: []
    });
    const [itemDetailState, setItemDetailState] = useState({
        dialog: false,
        type: null,
        id: null
    });
    const classes = useLedgerTableStyles();

    useEffect(
        () => {
            const fetchEntries = () => {
                const params = {
                    active: true,
                    id: record.id,
                    params: {
                        filter: {},
                        pagination: {page: 1, perPage: 10000},
                        sort: {field: "modified", order: "DESC"}
                    }
                };

                const cfg = resources.InventoryLedger.GET_LIST(params);
                axios({
                    method: 'GET',
                    url: cfg.uri,
                    headers: getGetHeaders(),
                    maxBodyLength: Infinity,
                    maxContentLength: Infinity
                }).then((response) => {
                    if (response.data && response.data.length > 0) {
                        const sortedList = sortArrayByKey(response.data, 'modified', 'DESC');
                        setLedgerList(ledgerList => ({...ledgerList, entries: sortedList}));
                    }
                    setListPopulated(true);
                }).catch((error) => {
                    if (error.response) {
                        // Server response outside 2xx
                    } else if (error.request) {
                        // No response
                    }
                    setListPopulated(true);
                });
            };

            if (record.id && !listPopulated) {
                return fetchEntries();
            }

        }, [record, listPopulated]
    );

    const handleEntryInfoClick = ({type, id}) => {
        setItemDetailState({dialog: !!(id), type: type, id: id});
    };

    const handleCloseItemDialog = () => {
        setItemDetailState({dialog: false, type: null, id: null});
    };

    const setRows = () => {
        return (
            ledgerList.entries.map((entry) => (
                <InventoryLedgerTableRow
                    key={`inventory-ledger-row-${entry.id}`}
                    entry={entry}
                    onEntryClick={(e) => handleEntryInfoClick(e)}
                />
            ))
        )
    };

    return (
        <>
            <FieldDivider type="break" margin="bottom"/>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="Inventory-Ledger-content"
                    id="Inventory-Ledger-header"
                    className={classes.accordionSummary}
                >
                    <FieldDescription header={true} margin="bottom" text="Product Inventory History"/>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                    {ledgerList.entries.length === 0 &&
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text={`This Product has no inventory history from Orders, Receipts, or manual adjustments.`}
                    />
                    }
                    <FieldDivider type="break" margin="bottom"/>

                    {ledgerList.entries.length > 0 &&
                    <div className="inventory-history-container">
                        <Table size="small" stickyHeader aria-label="Inventory Ledger Entries">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.th}>Order/Receipt</TableCell>
                                    <TableCell className={classes.th}>Date<br/>Adjusted</TableCell>
                                    <TableCell align="right" className={classes.th}>Amount<br/>Adjusted</TableCell>
                                    <TableCell align="right" className={classes.th}>Updated<br/>Total</TableCell>
                                    <TableCell className={classes.th}>Notes</TableCell>
                                    <TableCell className={classes.th}>Adjusted by</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tbody}>
                                {[...setRows()]}
                            </TableBody>
                        </Table>
                    </div>
                    }
                </AccordionDetails>
            </Accordion>
            <OrderReceiptDetailDialog
                openDialog={itemDetailState.dialog}
                itemType={itemDetailState.type}
                itemId={itemDetailState.id}
                onCloseFunc={handleCloseItemDialog}

            />
            <FieldDivider type="break" margin="bottom"/>
            <FieldDivider type="break" margin="bottom"/>
        </>
    );
}

export default InventoryLedgerTable;
