import React from 'react';
import {
    FormDataConsumer,
    SimpleForm,
    TextInput,
    required,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import Create from '../components/admin/Create';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from "../components/FieldDivider";
import OrgShowSelectFromListInput from '../components/OrgShowSelectFromListInput';
import ProductImage from '../components/ProductImage';
import SelectFromListInput from '../components/SelectFromListInput';
import {postProductImage} from '../utils/apiFunctions';
import {viewStyles} from '../global/styles';
import Avatar from '@material-ui/core/Avatar';
import CategoryIcon from '@material-ui/icons/Category';

export const ProductIcon = CategoryIcon;

const validateName = [required()];

const onSaveProgress = (e) => {
};

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<ProductIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Product</span>;

const createDefaultValues = {
    qty: 0
};

export const ProductCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onCreateSuccess = ({data, response}) => {
        if (data.product_image_input && response.id) {
            // If a Product Image was added, save it to the server with the new Product ID
            data.id = response.id;
            data.org_id = response.org_id;
            notify('Product created, saving Product Image');
            postProductImage(data, onSaveProgress).then(result => {
                notify('Product Image saved');
                redirect('/Product');
                refresh();
            }).catch(error => {
                console.log('Product Image Upload error: ', error);
                notify('Product Image error');
            });
        } else {
            // Otherwise, finish saving and redirect
            notify('Product created');
            redirect('/Product');
            refresh();
        }
    };
    return (
        <Create title={CreateTitle} onSuccess={onCreateSuccess} {...props}>
            <SimpleForm submitOnEnter={false} initialValues={createDefaultValues}>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text="Add a Product by selecting its Title and Category, and adding a Name."
                />
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text="To set the total Quantity of this Product, edit the Product after creation or create a Receipt."
                />
                <OrgShowSelectFromListInput
                    {...props}
                    selectOrg={true}
                    orgLabel="Select a Client"
                    orgSrc="selected_org_id"
                    showLabel="Select a Title"
                    showSrc="selected_show_id"
                    addLabel={false}
                    inline={true}
                    includeTitleCreation={true}
                    isRequired={true}
                />
                <FieldDivider type="break" margin="bottom"/>

                <FieldDescription
                    instructions={true} marginBottom={true}
                    text="Select a Category for this Product. If no specific Category applies, you may select a top-level Category."
                />
                <SelectFromListInput
                    inAdminForm={true}
                    source="type_id"
                    resourceName="ProductType"
                    listLabel="Select a Category"
                    itemPlural="Categories"
                    listFilters={{
                        active: true
                    }}
                    className="input-block input-400"
                    isRequired={true}
                    returnType="string"
                />
                <FieldDivider type="break" margin="bottom"/>

                <TextInput label="Name of Product" source="title" validate={validateName} fullWidth={true}/>

                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <ProductImage renderType="create" record={formData} allowUpload={true}/>
                    )}
                </FormDataConsumer>

                <TextInput label="Description of Product" source="description" multiline fullWidth={true}/>
                <TextInput label="Admin Notes" source="notes" multiline fullWidth={true}/>
                <FieldDescription
                    description={true}
                    text="After clicking Save, you will be redirected to the Products List."
                />
            </SimpleForm>
        </Create>
    )
};
