import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useChildCategoryRowStyles = makeStyles((theme) => ({
    td: {
        position: 'relative',
        padding: '6px 12px 6px 12px'
    }
}));

/**
 * A component for displaying a child Category row
 *
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */
const ChildProductTypeTableRow = ({item}) => {

    const classes = useChildCategoryRowStyles();

    return (
        <TableRow>
            <TableCell className={classes.td}>{item.id}</TableCell>
            <TableCell className={classes.td}>{item.name}</TableCell>
        </TableRow>
    );
}

export default ChildProductTypeTableRow;
