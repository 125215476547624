import React from "react";
import {ListButton, ShowButton, TopToolbar} from "react-admin";

/**
 * Custom Edit Actions Toolbar
 *
 * @param basePath
 * @param className
 * @param data
 * @param hasList
 * @param hasShow
 * @param resource
 * @returns {JSX.Element}
 * @constructor
 */
const EditActions = ({
                         basePath,
                         className,
                         data,
                         hasList,
                         hasShow,
                         resource
                     }) => {
    return (
        <>
            <TopToolbar className={className}>
                {hasList && <ListButton basePath={basePath}/>}
                {hasShow && <ShowButton basePath={basePath} record={data}/>}
            </TopToolbar>
        </>
    )
};

export default EditActions;
