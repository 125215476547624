import React, {useEffect, useState} from 'react';
import ProductDetailDialog from '../components/ProductDetailDialog';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
    btn: {
        marginTop: -4,
        marginRight: 4,
        padding: 2
    },
    btnAdd: {
        padding: 2
    },
    btnInfo: {
        position: 'absolute',
        top: 2,
        right: 4,
        padding: 2
    },
    btnText: {
        marginRight: 6,
        padding: '0px 10px'
    },
    inputQty: {
        width: 80
    },
    message: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    messageError: {
        fontSize: 16,
        color: '#b4212d'
    },
    saving: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    tr: {},
    trAdded: {
        backgroundColor: 'rgba(180, 220, 180, 0.3)'
    },
    th: {
        lineHeight: '1.2',
        padding: '5px 14px 5px 14px',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '5px 14px 5px 14px'
    },
    tdName: {
        position: 'relative',
        padding: '5px 34px 5px 14px'
    }
});

/**
 * A component to display available Products to add to a Warehouse Location
 *
 * @param products
 * @param onAddFunc
 * @param itemList
 * @return {JSX.Element}
 * @constructor
 */
const LocationAvailableProductsList = (
    {
        onAddFunc,
        products = [],
        itemList = []
    }) => {

    const [availableProducts, setAvailableProducts] = useState([]);
    const [itemDetailState, setItemDetailState] = useState({
        dialog: false,
        item: null
    });

    // Effect: Update available Products when list changes
    useEffect(
        () => {
            if (itemList && itemList.length) {
                let list = [];
                for (let i = 0; i < itemList.length; i++) {
                    const item = itemList[i];
                    item.added = false;
                    item.qty_available = (item.qty >= 0) ? item.qty : 0;
                    item.qty_requested = 0;
                    list.push(item);
                }
                setAvailableProducts(list)
            } else {
                setAvailableProducts([]);
            }
        }, [itemList]
    );

    const handleInfoClick = (item) => {
        setItemDetailState({dialog: !!(item), item: item});
    };

    const handleCloseItemDialog = () => {
        setItemDetailState({dialog: false, item: null});
    };

    // Handle Qty Requested input event
    const handleAddChange = (e, item) => {
        let requested = e?.target?.value ?? 0;
        if (requested < 0) {
            requested = 0;
        }
        if (item) {
            let list = JSON.parse(JSON.stringify(availableProducts));
            const idx = list.findIndex(r => r.id === item.id);
            if (idx !== -1) {
                if (requested > item.qty_available) {
                    requested = item.qty_available;
                }
                list[idx].qty_requested = requested;
            }
            setAvailableProducts(list);
        }
    };

    // Handle Add Product Click
    const handleAddSubmit = (item) => {
        if (typeof onAddFunc === 'function') {
            // Pass request to parent
            onAddFunc(item);
        }
    };

    // Generate Available Product list rows
    const setRows = () => {
        if (!availableProducts || availableProducts.length === 0) {
            return (
                [
                    <TableRow className={classes.tr} key="available-product-empty">
                        <TableCell colSpan={6} className={classes.td}>No Products Available</TableCell>
                    </TableRow>
                ]
            )
        }

        return (
            availableProducts.map((item, index) => (
                <TableRow
                    className={(item.added) ? classes.trAdded : classes.tr}
                    key={`available-product-${item.title}-${index}`}>

                    <TableCell className={classes.td}>{item.sku}</TableCell>

                    <TableCell className={classes.td}>{item.show_name}</TableCell>

                    <TableCell className={classes.td}>{item.type_name}</TableCell>

                    <TableCell className={classes.tdName}>
                        {item.title} &nbsp;
                        <IconButton
                            aria-label="Info"
                            className={classes.btnInfo}
                            onClick={() => handleInfoClick(item)}
                        >
                            <InfoIcon/>
                        </IconButton>
                    </TableCell>

                    <TableCell align="right" className={classes.td}>{item.qty}</TableCell>

                    <TableCell align="right" className={classes.td}>
                        <TextField
                            type="number"
                            variant="outlined"
                            id={`add-product-item-qty-${index}`}
                            label=""
                            placeholder="0"
                            className="input-table-small number"
                            onChange={(e) => handleAddChange(e, item)}
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: item.qty_available,
                                    step: 1
                                }
                            }}
                        />
                    </TableCell>

                    <TableCell align="right" className={classes.td}>
                        <IconButton
                            aria-label="Add"
                            className={classes.btnAdd}
                            disabled={(!item.qty_requested || item.qty_requested === 0 || item.qty_requested === '' || item.qty_requested < 1)}
                            onClick={() => handleAddSubmit(item)}
                        >
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </TableCell>

                </TableRow>
            ))
        )
    };

    const classes = useStyles();

    return (
        <>
            {availableProducts.length > 0 &&
            <div className="order-item-list-container">
                <Table size="small" stickyHeader aria-label="Added Items">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.th}>SKU</TableCell>
                            <TableCell className={classes.th}>Title</TableCell>
                            <TableCell className={classes.th}>Type</TableCell>
                            <TableCell className={classes.th}>Product</TableCell>
                            <TableCell align="right" className={classes.th}>Total<br/>Qty</TableCell>
                            <TableCell align="right" className={classes.th}>Add To<br/>Location</TableCell>
                            <TableCell align="right" className={classes.th}>Add</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...setRows()]}
                    </TableBody>
                </Table>
                <ProductDetailDialog
                    openDialog={itemDetailState.dialog}
                    productId={(itemDetailState.item) ? itemDetailState.item.id : null}
                    onCloseFunc={handleCloseItemDialog}
                />
            </div>
            }
        </>
    )
}

export default LocationAvailableProductsList;
