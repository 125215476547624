import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../resources';
import {getPostHeaders} from '../utils/headers';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import ProductLocationAddInput from './ProductLocationAddInput';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

const useProductLocationStyles = makeStyles((theme) => ({
    btnUpdate: {
        verticalAlign: 'top'
    },
    th: {
        lineHeight: '1.4',
        padding: '8px 16px 8px 16px',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '8px 16px 8px 16px'
    },
    tdLight: {
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

/**
 * A component to display a Product's current Warehouse Locations with optional Add Location
 *
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */
const ProductLocationsInput = ({record}) => {

    const [locationsState, setLocationsState] = useState([]);
    const [recordLocationsState, setRecordLocationsState] = useState([]);
    const [messageState, setMessageState] = useState(null);
    if (recordLocationsState) {
    }

    useEffect(
        () => {
            const setLocations = () => {
                if (record.warehouse_locations && record.warehouse_locations.length > 0) {
                    const locations = [];
                    for (let i = 0; i < record.warehouse_locations.length; i++) {
                        const location = record.warehouse_locations[i];
                        location.new_qty = location.qty;
                        location.edited = false;
                        locations.push(location);
                    }
                    // Store a record with possible edited quantities
                    setLocationsState(locations);
                    // Store the original record once available
                    setRecordLocationsState(record.warehouse_locations);
                }
            };

            if (record.warehouse_locations && record.warehouse_locations.length > 0) {
                return setLocations();
            }

        }, [record.warehouse_locations]
    );

    // Add Product to or remove from Location
    const handleAddOrRemoveProductInLocation = (location, add) => {
        let locations = JSON.parse(JSON.stringify(locationsState));
        const idx = locations.findIndex(r => r.location === location);
        if (idx !== -1) {
            // Set qty to 1 if adding (regardless of Product qty), or set qty to 0 if removing entirely
            locations[idx].new_qty = (add) ? 1 : 0;
            locations[idx].edited = true;
            setLocationsState(locations);
            postProductLocation(locations[idx], false);
            setMessageState(null);
        }
    };

    // Post an API request to update Warehouse Location
    const postProductLocation = (location, isNew = true) => {
        // If new, submit the requested quantity. If an update, submit the difference.
        const qty = (isNew) ? location.new_qty : (location.new_qty - location.qty);
        const params = {
            location: location.location,
            product_id: record.id,
            qty: qty
        };
        let cfg = resources.WarehouseLocation.CREATE(params);

        axios({
            method: cfg.method,
            url: cfg.uri,
            data: cfg.data,
            headers: getPostHeaders(true),
            responseType: 'json'
        })
            .then((result) => {
                if (result && result.data && result.data.location) {
                    if (result.data.location.id) {
                        location.id = result.data.location.id;
                    }
                    updateLocationsList(location);
                }
            })
            .catch((error) => {
                setMessageState('Sorry, this Location could not be updated for this Product.')
            });
    };

    // Update the rendered list of Locations for this Product
    const updateLocationsList = (location) => {
        let locations = JSON.parse(JSON.stringify(locationsState));
        const idx = locations.findIndex(r => r.location === location.location);
        if (idx === -1) {
            location.qty = location.new_qty;
            location.edited = false;
            locations.push(location);
        }
        if (idx !== -1) {
            locations[idx].qty = location.new_qty;
            locations[idx].new_qty = location.new_qty;
            locations[idx].edited = false;
        }
        setLocationsState(locations);
    };

    const handleAddLocation = (addedLocation) => {
        let locations = JSON.parse(JSON.stringify(locationsState));
        const idx = locationsState.findIndex(r => r.location === addedLocation.location);
        if (idx === -1) {
            addedLocation.new_qty = parseInt(addedLocation.qty, 10);
            addedLocation.edited = false;
            locations.push(addedLocation);
            postProductLocation(addedLocation, true);
        }
        setLocationsState(locations);
    };

    const setRows = () => {
        return (
            locationsState.map((loc, i) => {
                    return (
                        <TableRow key={`product-location-${loc.location}-${i}`}>
                            <TableCell className={(loc.qty < 1) ? classes.tdLight : classes.td}>
                                {loc.location}
                            </TableCell>
                            <TableCell align="right" className={(loc.qty < 1) ? classes.tdLight : classes.td}>
                                {(loc.qty < 1) ? 'Empty' : 'Yes'}
                            </TableCell>
                            <TableCell className={classes.td}>
                                {loc.qty < 1 &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        aria-label="Add to Location"
                                        startIcon={<AddCircleOutline/>}
                                        onClick={() => handleAddOrRemoveProductInLocation(loc.location, true)}
                                        className={classes.btnUpdate}
                                    >
                                        Add to Location
                                    </Button>
                                }
                                {loc.qty > 0 &&
                                    <Button
                                        variant="contained"
                                        color="default"
                                        size="small"
                                        aria-label="Remove from Location"
                                        startIcon={<RemoveCircleOutline/>}
                                        onClick={() => handleAddOrRemoveProductInLocation(loc.location, false)}
                                        className={classes.btnUpdate}
                                    >
                                        Remove from Location
                                    </Button>
                                }
                            </TableCell>
                        </TableRow>
                    )
                }
            )
        )
    };

    const classes = useProductLocationStyles();

    return (
        <>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription header={true} margin="bottom" text="Warehouse Locations"/>
            <FieldDivider type="break" margin="bottom"/>

            {locationsState.length === 0 &&
                <>
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text={`This Product may be stored in one or more Warehouse Locations. To add some of this Product 
                    to a Location, select a Location and add the Product.`}
                    />
                    <FieldDescription description={true} text="(This Product is not stored in any Locations)"/>
                </>
            }
            {locationsState.length > 0 &&
                <>
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text={`This Product may be stored in one or more Warehouse Locations. If the Product is 
                        currently stored in a Location, it may be removed. If the Location used to store this 
                        Product but does no longer, it will show as "Empty".`}
                    />
                    <FieldDescription
                        instructions={true} marginBottom={false}
                        text={`To entirely remove this Product from a Location, click the Location's "Remove from Location" button.`}
                    />
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text={`To re-add this Product to a former Location, click the Location's "Add to Location" button.`}
                    />
                    <FieldDivider type="break" margin="bottom"/>

                    <Table size="small" stickyHeader aria-label="Warehouse Locations">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.th}>Warehouse Location</TableCell>
                                <TableCell align="right" className={classes.th}>Stored in<br/>Location</TableCell>
                                <TableCell className={classes.th}>Change Product Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...setRows()]}
                        </TableBody>
                    </Table>
                </>
            }

            {!messageState &&
                <p className="message">&nbsp;</p>
            }
            {messageState &&
                <p className="message error">{messageState}</p>
            }

            <FieldDivider type="break"/>

            <ProductLocationAddInput formData={record} otherLocations={locationsState}
                                     onAdd={(e) => handleAddLocation(e)}/>

            <FieldDivider type="break" margin="bottom"/>
            <FieldDivider type="divider" margin="bottom"/>
        </>
    );
}

export default ProductLocationsInput;
