/**
 * Extract location elements from window
 *
 * @returns {{search, route: string, href: *, hash}}
 */
export const getLocationElements = () => {
    const href = window.location.href;
    const hash = window.location.hash;
    const route = hash.substr(1);
    return {
        hash: window.location.hash,
        href: href,
        route: route,
        search: window.location.search
    };
};
