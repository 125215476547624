import React from "react";
import {ListButton, EditButton, TopToolbar} from "react-admin";

/**
 * Custom Show Actions Toolbar
 *
 * @param basePath
 * @param className
 * @param data
 * @param hasEdit
 * @param hasList
 * @param resource
 * @returns {JSX.Element}
 * @constructor
 */
const ShowActions = ({
                         basePath,
                         className,
                         data,
                         hasEdit,
                         hasList,
                         resource
                     }) => {
    return (
        <>
            <TopToolbar className={className}>
                {hasList && <ListButton basePath={basePath}/>}
                {hasEdit && <EditButton basePath={basePath} record={data}/>}
            </TopToolbar>
        </>
    )
};

export default ShowActions;
