import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

const useDialogStyles = makeStyles({
    root: {
        margin: 0,
        padding: 24,
    },
    dialog: {
        margin: 2
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 2,
        color: '#666666',
    },
    btn: {
        marginTop: -4,
        marginRight: 4,
        padding: 2
    },
    btnAdd: {
        padding: 2
    },
    btnIcon: {
        padding: 2
    },
    btnInfo: {
        position: 'absolute',
        top: 2,
        right: 4,
        padding: 2
    },
    btnText: {
        marginRight: 6,
        padding: '0px 10px'
    },
    inputCheckbox: {
        padding: 2
    },
    inputQty: {
        width: 90
    },
    saving: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    table: {},
    tHead: {},
    tBody: {},
    tr: {},
    trAdded: {
        backgroundColor: 'rgba(180, 220, 180, 0.3)'
    },
    th: {
        lineHeight: '1.2',
        padding: '5px 12px 5px 12px',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '5px 12px 5px 12px'
    },
    tdName: {
        position: 'relative',
        padding: '5px 32px 5px 12px'
    },
    fieldNotes: {
        position: 'relative',
        width: '100%',
        padding: '5px 12px 5px 12px'
    },
    tdSku: {
        position: 'relative',
        whiteSpace: 'nowrap',
        padding: '5px 12px 5px 12px'
    }
});

/**
 * A Material UI Dialog to display Product Details for editing in an Order or Receipt
 *
 * @param openDialog
 * @param product
 * @param onCloseFunc
 * @param onSaveFunc
 * @param dialogType
 * @return {JSX.Element}
 * @constructor
 */
const OrderReceiptProductEditDialog = (
    {
        openDialog,
        product,
        onCloseFunc,
        onSaveFunc,
        dialogType
    }) => {

    const [productId, setProductId] = useState(null);
    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [numBoxes, setNumBoxes] = useState(0);
    const [qtyRequested, setQtyRequested] = useState(0);

    useEffect(
        () => {
            // If current Product is set or has changed, open dialog
            if (
                product ||
                (product && product.id !== productId)
            ) {
                setProductId(product.id);
                setNumBoxes((product.num_boxes) ? product.num_boxes : 1);
                setQtyRequested((product.qty_requested) ? product.qty_requested : 0);
                setNotes(product.notes);
                setOpen(true);
            }

        }, [product, productId]
    );

    const handleEditUpdate = (e, type) => {
        let value = (e.target?.value) ? parseInt(e.target.value) : 0;
        if (value < 0) {
            value = 0;
        }
        if (type === 'num_boxes') {
            setNumBoxes(value ?? product.num_boxes);
        }
        if (type === 'qty_requested') {
            if (dialogType === 'order' && value > product.qty) {
                value = product.qty;
            }
            setQtyRequested(value ?? product.qty_requested);
        }
    };

    const handleEditNotes = (e) => {
        let value = e.target?.value ?? '';
        setNotes(value);
    };

    const handleSave = () => {
        setOpen(false);
        if (typeof onSaveFunc === 'function') {
            onSaveFunc({
                product: product,
                notes: notes,
                num_boxes: (dialogType === 'receipt') ? numBoxes : undefined,
                qty_requested: qtyRequested
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const classes = useDialogStyles();

    return (
        <>
            <Dialog
                maxWidth={false}
                onClose={handleClose}
                aria-labelledby="Product-Detail"
                open={open}
                className={classes.dialog}
                transitionDuration={{
                    enter: 100,
                    exit: 150
                }}
            >
                <MuiDialogTitle disableTypography className={classes.root}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    {product &&
                        <>
                            <Table className={classes.table} size="small" stickyHeader aria-label="Add Item">
                                <TableHead className={classes.tHead}>
                                    <TableRow>
                                        <TableCell className={classes.th}>SKU</TableCell>
                                        <TableCell className={classes.th}>Title</TableCell>
                                        <TableCell className={classes.th}>Category</TableCell>
                                        <TableCell className={classes.th}>Product</TableCell>
                                        {dialogType === 'order' &&
                                            <>
                                                <TableCell align="right"
                                                           className={classes.th}>Available<br/>Qty</TableCell>
                                                <TableCell align="right"
                                                           className={classes.th}>Requested<br/>Qty</TableCell>
                                            </>
                                        }
                                        {dialogType === 'receipt' &&
                                            <>
                                                <TableCell align="right" className={classes.th}># Boxes</TableCell>
                                                <TableCell align="right"
                                                           className={classes.th}>Received<br/>Qty</TableCell>
                                            </>
                                        }
                                        <TableCell align="right" className={classes.th}>Add</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tBody}>
                                    <TableRow
                                        className={classes.tr}
                                        key={`receipt-item-edit-${product.id}`}>

                                        <TableCell className={classes.tdSku}>{product.sku}</TableCell>
                                        <TableCell className={classes.td}>{product.show_name}</TableCell>
                                        <TableCell className={classes.td}>{product.type_name}</TableCell>
                                        <TableCell className={classes.tdName}>
                                            {product.title} &nbsp;
                                        </TableCell>

                                        <TableCell align="right" className={classes.td}>
                                            {dialogType === 'order' &&
                                                <>{(product && product.qty) ? product.qty : '0'}</>
                                            }
                                            {dialogType === 'receipt' &&
                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    id={`add-${dialogType}-item-num-boxes-${product.id}`}
                                                    label=""
                                                    placeholder="0"
                                                    defaultValue={(product && product.num_boxes) ? product.num_boxes : 1}
                                                    className="input-table-small number mr-0"
                                                    onChange={(e) => handleEditUpdate(e, 'num_boxes')}
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 1,
                                                            step: 1
                                                        }
                                                    }}
                                                />
                                            }
                                        </TableCell>

                                        <TableCell align="right" className={classes.td}>
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                id={`add-${dialogType}-item-qty-${product.id}`}
                                                label=""
                                                placeholder="0"
                                                value={(qtyRequested) ? qtyRequested : 0}
                                                className="input-table-small number mr-0"
                                                onChange={(e) => handleEditUpdate(e, 'qty_requested')}
                                                InputProps={{
                                                    inputProps: {
                                                        max: (dialogType === 'order') ? product.qty : undefined,
                                                        min: 0,
                                                        step: 1
                                                    }
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell align="right" className={classes.td}>
                                            <IconButton
                                                aria-label="Save"
                                                color="primary"
                                                className={classes.btnIcon}
                                                onClick={() => handleSave()}
                                            >
                                                <CheckCircleRoundedIcon/>
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        className={classes.tr}
                                        key={`receipt-item-edit-notes-${product.id}`}>
                                        <TableCell align="right" className={classes.td}>Notes:</TableCell>
                                        <TableCell colSpan={5} className={classes.tdNotes}>
                                            <TextField
                                                type="text"
                                                multiline={true}
                                                minRows={2}
                                                variant="outlined"
                                                id={`add-${dialogType}-item-notes-${product.id}`}
                                                defaultValue={(product && product.notes) ? product.notes : ''}
                                                label=""
                                                className="input-table-small multiline"
                                                InputProps={{
                                                    inputProps: {
                                                        style: {zIndex: 1, padding: '0 0 0 0'}
                                                    }
                                                }}
                                                onChange={(e) => handleEditNotes(e)}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.td}></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {dialogType === 'order' &&
                                <p className="message-qty active mt-1">
                                    <em>Note</em>: This Product has {product.qty}
                                    {(product.qty === 1) ? ' item' : ' items'} available. Requested
                                    Qty is limited to the available quantity.
                                </p>
                            }
                        </>
                    }
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default OrderReceiptProductEditDialog;
