export const PRODUCT_EXPORT_COLUMNS = [
    {key: 'id', label: 'ID'},
    {key: 'sku', label: 'SKU'},
    {key: 'org_name', label: 'Client'},
    {key: 'show_name', label: 'Title'},
    {key: 'title', label: 'Product Name'},
    {key: 'description', label: 'Description'},
    {key: 'parent_type_name', label: 'Parent Category'},
    {key: 'type_name', label: 'Category'},
    {key: 'qty', label: 'Qty'},
    {key: 'locations', label: 'Locations'},
    {key: 'active', label: 'Active'},
    {key: 'notes', label: 'Notes'},
];
