export const BILLING_LABOR_PER_HOUR_TITLE = '$60/hour';

export const BILLING_LABOR_PER_HOUR = 60;

export const BILLING_LABOR_MINUTES = [
    {id: '0', name: '00', value: 0},
    {id: '15', name: '15', value: 15},
    {id: '30', name: '30', value: 30},
    {id: '45', name: '45', value: 45},
];
