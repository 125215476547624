import React, {Fragment} from 'react';
import {stripWhiteSpace} from '../utils/dataFunctions';

const styles = {
    container: {lineHeight: 1.4},
    label: {fontStyle: 'italic'},
    name: {fontWeight: 600}
};

/**
 * A component for displaying Contact Details from a record
 *
 * @param record
 * @return {JSX.Element}
 * @constructor
 */
const ContactDetails = ({record}) => {
    if (!record || !record.ship_to) {
        return (
            <Fragment>
            </Fragment>
        );
    }
    const city = stripWhiteSpace(record.ship_to.city);
    const country = stripWhiteSpace(record.ship_to.country);
    const state = stripWhiteSpace(record.ship_to.state);
    const email = stripWhiteSpace(record.ship_to.email);
    const phone = stripWhiteSpace(record.ship_to.phone);

    return (
        <div style={styles.container}>
            <span style={styles.name}>{record.ship_to.name}</span><br/>
            {record.ship_to.first_name} {record.ship_to.last_name}<br/>
            {record.ship_to.address}<br/>
            {record.ship_to.address2}<br/>
            {city}
            {state && state !== '' &&
                <Fragment>
                    , {state}
                </Fragment>
            } {record.ship_to.zip}
            <br/>
            {country && country !== '' &&
                <Fragment>
                    {country}
                    <br/>
                </Fragment>
            }
            {email && email !== '' &&
                <Fragment>
                    <span style={styles.label}>Email</span>: {email}<br/>
                </Fragment>
            }
            {phone && phone &&
                <Fragment>
                    <span style={styles.label}>Phone</span>: {phone}
                </Fragment>
            }
        </div>
    );
}

export default ContactDetails;
