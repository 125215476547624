import React, {useEffect, useState} from 'react';
import {useForm} from 'react-final-form';
import {connect} from 'react-redux';
import {getUserInfoFromToken} from '../utils/tokenFunctions';
import {TextInput} from 'react-admin';

/**
 * Component for setting a Keycloak username value for an Order or Receipt
 *
 */
const OrderUsernameField = (props) => {

    const orderForm = useForm();
    const [username, setUsername] = useState('');

    // Effect: Set Username from token
    useEffect(() => {
        const getUsernameFromToken = () => {
            const userInfo = getUserInfoFromToken(props.tokens.token);
            setUsername(userInfo.preferred_username);
        };

        if (props.tokens?.token) {
            return getUsernameFromToken();
        }
    }, [props.tokens.token]);

    // Effect: Populate field if Edit form approved status changes
    useEffect(() => {
        const updateApprovalUsername = () => {
            // If Billed status has been set to true, store the current username
            if (!props.record.billed && props.record.set_billed) {
                orderForm.change('set_billed_by', username);
            }
            // If Billed status has been set to false, clear the "Billed by" username
            if (!props.record.set_billed) {
                orderForm.change('set_billed_by', '');

            }
        };

        if (props.type === 'edit') {
            return updateApprovalUsername();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.record.billed, props.record.set_billed, username]);

    return (
        <>
            <TextInput label="Billed By:" source="set_billed_by" className="input-inline input-280" disabled/>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        tokens: {
            token: state.tokens.token
        }
    };
};

export default connect(
    mapStateToProps
)(OrderUsernameField);
