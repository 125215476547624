import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    BooleanField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton,
    required, FormDataConsumer
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDivider from '../components/FieldDivider';
import ListEmpty from '../components/ListEmpty';
import FieldDescription from '../components/FieldDescription';
import SelectFromListInput from '../components/SelectFromListInput';
import {BILLING_REFERENCE_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';

export const BillingReferenceIcon = DescriptionIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const validateDescription = [required()];

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectFromListInput
            inAdminForm={true}
            source="filter_org_id"
            resourceName="Organization"
            listLabel="Select Client"
            itemPlural="Clients"
            returnType="string"
            listFilters={{
                active: props.filterValues.active
            }}
            isRequired={false}
            alwaysOn
            size="small"
            hasClear={false}
            className="input-320"
        />
        <SelectInput source="active" label="Active"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Billing References"><BillingReferenceIcon style={viewStyles.avatarSvg}/></Avatar>Billing References</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [showButtonColumn, editButtonColumn, ...BILLING_REFERENCE_COLUMNS];

const listSort = {field: 'dscription', order: 'ASC'};

export const BillingReferenceList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={20}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              empty={<ListEmpty
                  props={{namePlural: 'Billing References', nameSingle: 'Billing Reference', connector: 'a'}}/>}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.description}
                    secondaryText=''
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable columns={listColumns} sort={listSort}
                           messageEmpty=" No Billing References found." {...props}/>
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Billing References"><BillingReferenceIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.description}` : 'Billing References'}</span>;
};

export const BillingReferenceShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Billing Reference details below. To edit this Billing Reference, click Edit above.
                `}
            />
            <TextField label="ID" source="id" className="input-inline input-120"/>
            <TextField label="Client" source="org_name" className="input-inline input-280"/>
            <TextField label="Description" source="description" className="field-bg"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<BillingReferenceIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Billing Reference</span>;

export const BillingReferenceCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add a Billing Reference by selecting a Client and entering a description for the Reference. 
                Billing References are used to group Orders.
                `}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="org_id"
                resourceName="Organization"
                listLabel="Select a Client"
                itemPlural="Clients"
                listFilters={{
                    active: true
                }}
                returnType="string"
                className="input-full"
                isRequired={true}
                hasClear={false}
            />
            <FieldDivider type="break" margin="bottom"/>
            <TextInput label="Description of Billing Reference" source="description"
                       fullWidth={true} validate={validateDescription}/>
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Billing References List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<BillingReferenceIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.description}` : 'Billing Reference'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const BillingReferenceEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`Edit Billing Reference details below. ID cannot be edited.`}
            />
            <TextField label="ID" source="id" formClassName="input-inline input-120"/>
            <TextField disabled label="Current Client" source="org_name" formClassName="input-inline input-320"/>

            <FieldDescription
                description={true}
                text={`
                    If this Billing Reference should be associated with a different Client, select a new Client. 
                    Otherwise, leave blank.
                    `}
            />
            <FormDataConsumer formClassName="input-full" {...props}>
                {({formData, ...rest}) => (
                    <SelectFromListInput
                        inAdminForm={true}
                        source="new_org_id"
                        resourceName="Organization"
                        listLabel="Change Client (optional)"
                        itemPlural="Clients"
                        listFilters={{
                            active: true
                        }}
                        className="input-full"
                        isRequired={false}
                        returnType="string"
                    />
                )}
            </FormDataConsumer>
            <FieldDivider type="break" margin="bottom"/>
            <FieldDivider type="break" margin="bottom"/>

            <TextInput label="Description of Billing Reference" source="description"
                       fullWidth={true} validate={validateDescription}/>
            <FieldDescription
                description={true}
                text="If this Billing Reference is no longer to be used, mark it as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Billing References List."
            />
        </SimpleForm>
    </Edit>
);

