import React from 'react';
import {EditButton} from 'react-admin';
import FieldDescription from './FieldDescription';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
    table: {marginTop: 12},
    tHead: {},
    tBody: {},
    tr: {},
    th: {
        lineHeight: '1.2',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '5px 16px 5px 16px'
    }
});

/**
 * A component to display Products currently stored in a Warehouse Location
 *
 * @param hasEdit
 * @param itemList
 * @return {JSX.Element}
 * @constructor
 */
const LocationsList = ({hasEdit, itemList = []}) => {

    // Generate Location list rows
    const setRows = () => {
        const list = itemList;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Locations</span>
            )
        }

        return (
            itemList.map((item, index) => (
                <TableRow className={classes.tr} key={`location-item-${item.title}-${index}`}>
                    <TableCell className={classes.td}>{item.location}</TableCell>
                    <TableCell className={classes.td}>{item.product_title}</TableCell>
                    <TableCell align="right" className={classes.td}>
                        {(item.qty > 0) ? 'Yes' : 'Empty'}
                    </TableCell>
                    {hasEdit &&
                    <TableCell className={classes.td}>
                        <EditButton basePath="/WarehouseLocation" record={item} label="" className="table-icon-button"/>
                    </TableCell>
                    }
                </TableRow>
            ))
        )
    };

    const classes = useStyles();

    return (
        <>
            {itemList.length > 0 &&
            <>
                <FieldDescription
                    instructions={true}
                    text={`Products currently stored in Location ${itemList[0].location}`}
                />
                <div className="location-list-container">
                    <Table className={classes.table} size="small" stickyHeader aria-label="Products">
                        <TableHead className={classes.tHead}>
                            <TableRow>
                                <TableCell className={classes.th}>Location</TableCell>
                                <TableCell className={classes.th}>Product</TableCell>
                                <TableCell align="right" className={classes.th}>Stored in<br/>Location</TableCell>
                                {hasEdit &&
                                <TableCell className={classes.th}>
                                </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tBody}>
                            {[...setRows()]}
                        </TableBody>
                    </Table>
                </div>
            </>
            }
        </>
    )
}

export default LocationsList;
