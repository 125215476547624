import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    BooleanField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton,
    required
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import OrganizationClientUserField from '../components/OrganizationClientUserField';
import {CLIENT_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';

export const OrganizationIcon = BusinessIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const validateTextInput = [required()];

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="active" label="Active"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Clients"><OrganizationIcon style={viewStyles.avatarSvg}/></Avatar>Clients</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [showButtonColumn, editButtonColumn, ...CLIENT_COLUMNS];

const listSort = {field: 'name', order: 'ASC'};

export const OrganizationList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.city + ', ' + record.state}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable columns={listColumns} sort={listSort} messageEmpty=" No Clients found." {...props}/>
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Clients"><OrganizationIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Client'}</span>;
};

export const OrganizationShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Client details below. To edit this Client, click Edit above.
                `}
            />
            <TextField label="ID" source="id" className="input-inline input-120"/>
            <TextField label="Group Name" source="machine_name" className="input-inline input-280"/>
            <TextField label="Name of Client" source="name" className="field-bg"/>
            <TextField label="Email Address" source="email" className="field-bg"/>
            <TextField label="Phone" source="phone" className="field-bg"/>
            <TextField label="Address" source="address" className="field-bg"/>
            <TextField label="City" source="city" className="input-inline input-180"/>
            <TextField label="State" source="state" className="input-inline input-120"/>
            <TextField label="Zip" source="zip" className="input-inline input-120"/>
            <BooleanField label="Active" source="active"/>
            <OrganizationClientUserField record={props.record} {...props}/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<OrganizationIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Client</span>;

export const OrganizationCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add a Client below. Once created, this Client may be associated with Titles, 
                Products, Contacts, Orders, Receipts, and Billing References.
                `}
            />
            <TextInput label="Name of Client" source="name" fullWidth={true} validate={validateTextInput}/>
            <TextInput label="Email Address" source="email" formClassName="input-inline third"/>
            <TextInput label="Phone" source="phone" formClassName="input-inline half"/>
            <TextInput label="Address" source="address" fullWidth={true}/>
            <TextInput label="City" source="city" formClassName="input-inline third"/>
            <TextInput label="State" source="state" formClassName="input-inline third"/>
            <TextInput label="ZIP" source="zip" formClassName="input-inline third"/>
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Clients List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<OrganizationIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.name}` : 'Client'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const OrganizationEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Edit Client details below. Client ID and Group Name are unique and cannot be edited. If this Client is 
                no longer applicable or active, mark it as Inactive.
                `}
            />
            <TextInput disabled label="ID" source="id" formClassName="input-inline input-120"/>
            <TextInput disabled label="Group Name" source="machine_name" formClassName="input-inline input-280"/>
            <TextInput label="Name of Client" source="name" fullWidth={true} validate={validateTextInput}/>
            <TextInput label="Email Address" source="email" formClassName="input-inline half"/>
            <TextInput label="Phone" source="phone" formClassName="input-inline half"/>
            <TextInput label="Address" source="address" fullWidth={true}/>
            <TextInput label="City" source="city" formClassName="input-inline third"/>
            <TextInput label="State" source="state" formClassName="input-inline third"/>
            <TextInput label="zip" source="zip" formClassName="input-inline third"/>
            <FieldDescription
                description={true}
                text="If this Client is no longer active, mark it as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Clients List."
            />
        </SimpleForm>
    </Edit>
);

