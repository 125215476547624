import React, {useEffect, useState} from 'react';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import ReceiptItemsAvailableListContainer from './ReceiptItemsAvailableListContainer';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useAddItemStyles = makeStyles((theme) => ({
    accordionDetail: {
        flexDirection: 'column'
    },
    accordionSummary: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }
}));

/**
 * A component for displaying an Add Items component
 *
 * @param record
 * @param itemsInReceipt
 * @param onAddFunc
 * @param onEditNameFunc
 * @param refresh
 * @return {JSX.Element}
 * @constructor
 */
const ReceiptEditAddItemContainer = ({record, itemsInReceipt, onAddFunc, onEditNameFunc, refresh = false}) => {

    const [refreshList, setRefreshList] = useState(false);

    useEffect(() => {
        if (refresh) {
            setRefreshList(true);
            setTimeout(function () {
                setRefreshList(false);
            }, 100);
        }
    }, [refresh]);

    const classes = useAddItemStyles();

    const handleAddItem = (e) => {
        if (typeof onAddFunc === 'function') {
            onAddFunc(e);
        }
    };

    const handleEditProductName = (product) => {
        setRefreshList(true);
        setTimeout(function () {
            setRefreshList(false);
        }, 100);
        if (typeof onEditNameFunc === 'function') {
            onEditNameFunc(product);
        }
    };

    if (!record || !record.org_id) {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <FieldDivider type="break" margin="bottom"/>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="Available-Products-content"
                    id="Available-Products-header"
                    className={classes.accordionSummary}
                >
                    <FieldDescription instructions={true} margin="bottom" text="Add Products to this Receipt"/>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>

                    <ReceiptItemsAvailableListContainer
                        displayFields={true}
                        type="edit"
                        orgId={record.org_id}
                        onProductAdd={(e) => handleAddItem(e)}
                        onProductNameEdit={(e) => handleEditProductName(e)}
                        itemsInReceipt={itemsInReceipt}
                        refreshList={refreshList}
                    />

                </AccordionDetails>
            </Accordion>
            <FieldDivider type="break" margin="bottom"/>
        </>
    );
}

export default ReceiptEditAddItemContainer;
