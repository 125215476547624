/**
 * Resources for API
 *      Client User
 *      Client User / Organization
 *      Contact
 *      Employee
 *      Client (Organization)
 *      Product Category (Type)
 *      Shipping Company
 *      Shipping Method
 *      Title (Show)
 */

export const RESOURCES = {
    'ClientUser': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Client Users',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/users/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/create'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/user/update'
            };
        }
    },
    'ClientUsersByOrg': {
        GET_LIST: function (params) {
            let org_id = (params && params.org_id) ? params.org_id : null;
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Client Users',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/orgUsers/' + org_id + '/' + active
            };
        },
    },
    'ClientUserOrg': {
        UPDATE: function (params) {
            return {
                'method': 'PUT',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/addUserToOrg/' + params.username + '/' + params.org_id
            };
        },
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/removeUserFromOrg/' + params.username + '/' + params.org_id
            };
        }
    },
    'Contact': {
        GET_LIST: function (params) {
            let org_id = (params && params.params.filter.filter_org_id) ? params.params.filter.filter_org_id : null;
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Contacts',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contacts/' + org_id + '/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contact/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contact/create'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contact/update'
            };
        }
    },
    'Employee': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Employees',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/employees/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/employee/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/employee/create'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/employee/update'
            };
        }
    },
    'Organization': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Clients',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organizations/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/create'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/update'
            };
        }
    },
    'ProductType': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Product Types',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productTypes/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productType/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productType/create'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productType/update'
            };
        }
    },
    'ParentProductType': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Product Types',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/parentProductTypes/' + active
            };
        }
    },
    'ChildProductType': {
        GET_LIST: function (params) {
            let product_type_id = (params?.params?.filter?.product_type_id) ? params.params.filter.product_type_id : params.id;
            return {
                'method': 'GET',
                'title': 'Product Types',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productTypesByParent/' + product_type_id
            };
        }
    },
    'ShippingCompany': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Shipping Companies',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/companies/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/company/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/company/add'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/company/update'
            };
        }
    },
    'ShippingMethod': {
        GET_LIST: function (params) {
            let company_id = (params && params.params.filter.filter_company_id) ? params.params.filter.filter_company_id : null;
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Shipping Methods',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/types/' + company_id + '/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/type/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/type/add'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/type/update'
            };
        }
    },
    'Show': {
        GET_LIST: function (params) {
            let active = (params && params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Titles',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shows/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/show/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/show/create'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/show/update'
            };
        }
    },
    'ShowByOrg': {
        GET_LIST: function (params) {
            let org_id = (params && params.org_id) ? params.org_id : null;
            let active = (params && params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Titles',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/all/shows/' + org_id + '/' + active
            };
        }
    },
    'WarehouseLocation': {
        GET_LIST: function (params) {
            const filter = params.params.filter;
            let uri;
            if (filter && filter.filter_location) {
                uri = '/api/v1/mgmt/contents/warehouse/location/' + filter.filter_location;
            } else {
                uri = '/api/v1/mgmt/all/warehouse/locations';
            }
            return {
                'method': 'GET',
                'title': 'Warehouse Locations',
                'uri': localStorage.getItem('server') + uri
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contents/warehouse/location/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/warehouse/add'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/warehouse/update'
            };
        },
        DELETE: function (params) {
            return {
                'method': 'DELETE',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/warehouse/' + params.id
            };
        },
    },
    'WarehouseLocationByLocation': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contents/warehouse/location/' + params.location
            };
        },
    },
    'WarehouseLocationLedgerByLocation': {
        GET_LIST: function (params) {
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'title': 'Warehouse Location Ledgers',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/warehouseLedger/locationId/' + id
            }
        }
    },
    'WarehouseLocationLedgerByProduct': {
        GET_LIST: function (params) {
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'title': 'Warehouse Location Ledgers',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/warehouseLedger/productId/' + id
            }
        }
    },
    'WarehouseLocationProduct': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productWarehouseLocations'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/warehouse/add'
            };
        }
    }
};

export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "Organization"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    };
}
