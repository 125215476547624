import React from 'react';
import FieldDivider from './FieldDivider';
import ChildProductTypeTableRow from './ChildProductTypeTableRow';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useChildCategoryTableStyles = makeStyles((theme) => ({
    tbody: {
        maxHeight: 200,
        overflow: 'auto'
    },
    th: {
        lineHeight: '1.4',
        padding: '8px 12px 8px 12px',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '8px 12px 8px 12px'
    }
}));

/**
 * A component for retrieving and displaying a list of child Categories
 *
 * @param categoryList
 * @return {JSX.Element}
 * @constructor
 */
const ChildProductTypeTable = ({categoryList = []}) => {

    const classes = useChildCategoryTableStyles();

    const setRows = () => {
        return (
            categoryList.map((item) => (
                <ChildProductTypeTableRow
                    key={`child-category-row-${item.id}`}
                    item={item}
                />
            ))
        )
    };

    return (
        <>
            {categoryList?.length > 0 &&
            <div className="child-category-container">
                <Table size="small" stickyHeader aria-label="Child Categories">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.th}>ID</TableCell>
                            <TableCell className={classes.th}>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                        {[...setRows()]}
                    </TableBody>
                </Table>
            </div>
            }
            <FieldDivider type="break" margin="bottom"/>
        </>
    );
}

export default ChildProductTypeTable;
