import React from 'react';
import {
    Link,
    Redirect,
    withRouter
} from 'react-router-dom';
import {connect} from 'react-redux';
import {Title} from 'react-admin';
import ExportActiveProductsButton from '../components/ExportActiveProductsButton';
import {checkAuthentication} from '../utils/authFunctions';
import {assembleDefaultQueryString} from '../utils/dataFunctions';
import {isSysopOrAdmin} from '../utils/tokenFunctions';
import OrdersNotification from '../components/OrdersNotification';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const Dashboard = ({keycloakReady, keycloakAuthenticated, tokens, location}) => {

    let isAuthenticated = checkAuthentication(tokens.token, keycloakAuthenticated);
    const userIsSysopOrAdmin = (tokens.token) ? isSysopOrAdmin(tokens.token) : false;

    // If route has a query string, redirect
    if (isAuthenticated && location.pathname !== '/') {
        return <Redirect to="/"/>
    }

    const defaultOrderQueryString = assembleDefaultQueryString('Order');

    return (
        <>
            {keycloakReady && isAuthenticated && !userIsSysopOrAdmin &&
            <div className="list-card-container">
                <Card className="list-card">
                    <Title title="Dashboard"/>
                    <CardHeader title="Welcome to the Parrot Communications Inventory"/>
                    <CardContent>
                        <p>This Admin is for Parrot Media personnel and system administrators.</p>
                    </CardContent>
                </Card>
            </div>
            }

            {keycloakReady && isAuthenticated && userIsSysopOrAdmin &&
            <>
                <div className="list-card-container">
                    <Card className="list-card">
                        <Title title="Dashboard"/>
                        <CardHeader title="Welcome to the Parrot Communications Inventory"/>
                        <CardContent>
                            <p>Add and manage Clients and their Titles, set Product Categories, create and manage Products,
                                Orders and Receipts, and manage Shipping information.</p>
                        </CardContent>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card">
                        <CardHeader title="Products"/>
                        <CardContent>
                            <p>View, create and manage Products for specific Clients.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Product">
                                Browse Products
                            </Button>
                            <Button variant="outlined" component={Link} to="/Product/create">
                                Add a Product
                            </Button>
                            <ExportActiveProductsButton buttonType="dashboard"/>
                        </CardActions>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Orders"/>
                        <CardContent>
                            <p>View, create and manage outgoing Orders.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to={`/Order${defaultOrderQueryString}`}>
                                Browse Orders
                            </Button>
                            <Button variant="outlined" component={Link} to="/Order/create">
                                Add an Order
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className="list-card half last">
                        <CardHeader title="Receipts"/>
                        <CardContent>
                            <p>View, create and manage incoming Receipts.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Receipt">
                                Browse Receipts
                            </Button>
                            <Button variant="outlined" component={Link} to="/Receipt/create">
                                Add a Receipt
                            </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Clients"/>
                        <CardContent>
                            <p>View, create and manage Clients.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Organization">
                                Browse Clients
                            </Button>
                            <Button variant="outlined" component={Link} to="/Organization/create">
                                Add a Client
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className="list-card half last">
                        <CardHeader title="Titles"/>
                        <CardContent>
                            <p>View, create and manage Titles (Shows, Events, Properties, other entities).</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Show">
                                Browse Titles from All Clients
                            </Button>
                            <Button variant="outlined" component={Link} to="/Show/create">
                                Add a Title
                            </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Contacts"/>
                        <CardContent>
                            <p>View, create and manage Client Contacts.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Contact">
                                Browse Contacts
                            </Button>
                            <Button variant="outlined" component={Link} to="/Contact/create">
                                Add a Contact
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className="list-card half last">
                        <CardHeader title="Product Categories"/>
                        <CardContent>
                            <p>View, create and manage Product Categories and parent Categories.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/ProductType">
                                Browse Product Categories
                            </Button>
                            <Button variant="outlined" component={Link} to="/ProductType/create">
                                Add a Category
                            </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Warehouse Locations"/>
                        <CardContent>
                            <p>View, create and manage Warehouse Locations for Products.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/WarehouseLocation">
                                Browse Locations
                            </Button>
                            <Button variant="outlined" component={Link} to="/WarehouseLocation/create">
                                Add a Location
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className="list-card half last">
                        <CardHeader title="Billing References"/>
                        <CardContent>
                            <p>View, create and manage Billing References for Orders.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/BillingReference">
                                Browse References
                            </Button>
                            <Button variant="outlined" component={Link} to="/BillingReference/create">
                                Add a Reference
                            </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Shipping Companies"/>
                        <CardContent>
                            <p>View, create and manage Shipping Carriers.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/ShippingCompany">
                                Browse Carriers
                            </Button>
                            <Button variant="outlined" component={Link} to="/ShippingCompany/create">
                                Add a Carrier
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className="list-card half last">
                        <CardHeader title="Shipping Methods"/>
                        <CardContent>
                            <p>View, create and manage Shipping Methods for Carriers.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/ShippingMethod">
                                Browse All Methods
                            </Button>
                            <Button variant="outlined" component={Link} to="/ShippingMethod/create">
                                Add a Shipping Method
                            </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Client Users"/>
                        <CardContent>
                            <p>View, create and manage Client Users and their associated Clients.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/ClientUser">
                                Browse Client Users
                            </Button>
                            <Button variant="outlined" component={Link} to="/ClientUser/create">
                                Add a Client User
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            </>
            }

            {keycloakReady && isAuthenticated && tokens.token &&
                <>
                    <OrdersNotification/>
                </>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        keycloakReady: state.keycloakState.keycloakReady,
        tokens: {
            token: state.tokens.token
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(Dashboard)
);
