import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../resources';
import {getGetHeaders} from '../utils/headers';
import {getInitialStoredOrders, removeStoredOrder} from '../utils/orderReceiptStorageFunctions';
import StoredOrdersReceiptsList from './StoredOrdersReceiptsList';

/**
 * A component for retrieving Clients and Contacts for stored Orders
 *
 * @param onResume
 * @param onDelete
 * @param type
 * @param refresh
 * @returns {JSX.Element}
 * @constructor
 */
const StoredOrdersReceipts = ({
                               onResume,
                               onDelete,
                               type = 'order',
                               refresh = false
                           }) => {

    const [storedList, setStoredList] = useState([]);
    const [storedRetrieved, setStoredRetrieved] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [clientsRetrieved, setClientsRetrieved] = useState(false);

    // Effect: Fetch Clients for display
    useEffect(
        () => {
            if (!clientsRetrieved) {
                const fetchItems = () => {
                    const params = {
                        active: true
                    };

                    const cfg = resources.Organization.GET_LIST(params);
                    axios({
                        method: 'GET',
                        url: cfg.uri,
                        headers: getGetHeaders(),
                        maxBodyLength: Infinity,
                        maxContentLength: Infinity
                    }).then((response) => {
                        if (response.data && response.data.length > 0) {
                            setClientList(response.data);
                            setClientsRetrieved(true);
                        }
                        // resolve(response);
                    }).catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                        } else if (error.request) {
                            // No response
                        }
                    });
                };

                if (!clientsRetrieved) {
                    fetchItems();
                }
            }
        }, [clientsRetrieved]
    );

    // Effect: Set stored Orders list
    useEffect(() => {
        const setList = () => {
            const list = getInitialStoredOrders(type);
            setStoredList(list);
            setStoredRetrieved(true);
        };

        if (!storedRetrieved) {
            return setList();
        }

    }, [storedRetrieved, type]);

    // Effect: Refresh stored list upon new form updates
    useEffect(() => {
        const refreshList = () => {
            setStoredRetrieved(false);
        };

        if (refresh) {
            return refreshList();
        }

    }, [refresh, type]);

    const handleResume = (item) => {
        if (typeof onResume === 'function') {
            onResume(item);
        }
    };

    const handleDeleted = (id) => {
        removeStoredOrder(type, id);
        if (typeof onDelete === 'function') {
            onDelete(id);
        }
    };

    return (
        <StoredOrdersReceiptsList
            type={type}
            clients={clientList}
            storedList={storedList}
            onResume={(e) => handleResume(e)}
            onDeleted={(e) => handleDeleted(e)}
        />
    );
}

export default StoredOrdersReceipts;
