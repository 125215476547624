import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../resources';
import {getDeleteHeaders, getGetHeaders, getPostHeaders} from '../utils/headers';
import {sortArrayByKey} from '../utils/dataFunctions';
import AutocompleteSelect from './AutocompleteSelect';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';

const useClientUserOrgStyles = makeStyles((theme) => ({
    orgSelect: {
        display: 'inline-block',
        width: 'calc(100% - 200px)',
        margin: '0 1em 0 0',
        verticalAlign: 'middle'
    },
    orgAddBtn: {
        margin: '4px 0 0 0',
        verticalAlign: 'top',
    },
    orgChip: {
        display: 'inline-block',
        margin: '0 1em 1em 0',
        verticalAlign: 'middle'
    }
}));

const ClientUserOrganizationInput = ({formData}) => {

    const [orgListState, setOrgListState] = useState([]);
    const [allowSetOrg, setAllowSetOrg] = useState(true);
    const [selectedOrgState, setSelectedOrgState] = useState(null);
    const [associatedOrgsState, setAssociatedOrgsState] = useState([]);
    const [messageState, setMessageState] = useState(null);

    useEffect(
        () => {
            const params = {
                active: true,
                params: {
                    filter: {},
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: "name", order: "ASC"}
                }
            };

            const cfg = resources.Organization.GET_LIST(params);
            axios({
                method: 'GET',
                url: cfg.uri,
                headers: getGetHeaders(),
                maxBodyLength: Infinity,
                maxContentLength: Infinity
            }).then((response) => {
                if (response.data && response.data.length > 0) {
                    let choices = assembleOrgChoices(response.data);
                    setOrgListState(choices);
                }
            }).catch((error) => {
                console.log('error response for Clients: ', error);
                if (error.response) {
                    // Server response outside 2xx
                } else if (error.request) {
                    // No response
                }
            });

            // Assemble display name for select options
            const assembleOrgChoices = (choices) => {
                for (let choice of choices) {
                    choice.display_name = choice.name;
                    choice.org_id = choice.id;
                    choice.org_name = choice.name;
                }
                choices = sortArrayByKey(choices, 'name', 'ASC');
                return choices;
            };

        }, []
    );

    useEffect(
        () => {
            if (
                allowSetOrg &&
                formData.user_orgs
                && formData.user_orgs.length > 0
                && JSON.parse(JSON.stringify(formData.user_orgs)) !== JSON.parse(JSON.stringify(associatedOrgsState))
            ) {
                // Update the list of Organizations only upon newly received data
                setAssociatedOrgsState(formData.user_orgs);
                setAllowSetOrg(false);
            }

        }, [formData.user_orgs, associatedOrgsState, allowSetOrg]
    );

    // Handle event for Organization selection
    const handleOrgSelect = (e) => {
        setMessageState(null);
        const value = (e && e.target && e.target.value) ? e.target.value : e;
        setSelectedOrgState((value) ? value : null);
    };

    // Handle event for Add Client
    const addOrg = () => {
        const associatedIdx = associatedOrgsState.findIndex(r => r.org_id.toString() === selectedOrgState.toString());
        const orgIdx = orgListState.findIndex(r => r.org_id.toString() === selectedOrgState.toString());
        const org = (orgIdx !== -1) ? orgListState[orgIdx] : null;
        if (org && associatedIdx === -1) {
            // Make API call to add Organization object to the list
            postOrgAssociation('add', org);
        }
    };

    // Handle event for Remove Client
    const removeOrg = (orgId) => {
        const associatedIdx = associatedOrgsState.findIndex(r => r.org_id.toString() === orgId.toString());
        const org = (associatedIdx !== -1) ? associatedOrgsState[associatedIdx] : null;
        if (org && associatedIdx !== -1) {
            // Make API call to remove Organization object from the list
            postOrgAssociation('remove', org);
        }
    };

    // Make API call to add or remove Organization association
    const postOrgAssociation = (type, org) => {
        const params = {
            username: formData.username,
            org_id: org.org_id
        };
        const headers = (type === 'remove') ? getDeleteHeaders(true) : getPostHeaders(true);
        const cfg = (type === 'remove') ? resources.ClientUserOrg.DELETE(params) : resources.ClientUserOrg.UPDATE(params);
        axios({
            method: cfg.method,
            url: cfg.uri,
            data: cfg.data,
            headers: headers,
            responseType: 'json'
        })
            .then((result) => {
                if (result && result.error) {
                    if (type === 'add' && result.error.indexOf('already associated') !== -1) {
                        setMessageState('Sorry, this Client User is already associated with this Client.')
                    }
                    if (type === 'remove' && result.error.indexOf('not found') !== -1) {
                        setMessageState('Sorry, there was an error removing this Client association.')
                    }
                } else {
                    setMessageState(null);
                    if (type === 'add') {
                        addToAssociatedOrgs(org);
                    }
                    if (type === 'remove') {
                        removeFromAssociatedOrgs(org);
                    }
                }
            })
            .catch((error) => {
                const typeStr = (type === 'remove') ? 'removing' : 'adding';
                if (error.response && error.response.data) {
                    if (type === 'add' && error.response.data.error.indexOf('already associated') !== -1) {
                        setMessageState('Sorry, this Client User is already associated with this Client.')
                    }
                    if (type === 'remove' && error.response.data.error.indexOf('not found') !== -1) {
                        setMessageState('Sorry, there was an error removing this Client association.')
                    }
                } else if (error.request) {
                    // No response
                    setMessageState('Sorry, there was an error ' + typeStr + ' this User.')
                    console.log('Client Association Error request: ', error);
                } else {
                    setMessageState('Sorry, there was an error ' + typeStr + ' this User.')
                    console.log('Client Association Error: ', error);
                }
            });
    };

    // Add Client association and update array
    const addToAssociatedOrgs = (org) => {
        setAssociatedOrgsState([...associatedOrgsState, org]);
    };

    // Remove Client association and update array
    const removeFromAssociatedOrgs = (org) => {
        const idx = associatedOrgsState.findIndex(r => r.org_id.toString() === org.org_id.toString());
        setAssociatedOrgsState([...associatedOrgsState.slice(0, idx), ...associatedOrgsState.slice(idx + 1)]);
    };

    const classes = useClientUserOrgStyles();

    return (
        <>
            <FieldDivider type="divider" margin="bottom"/>

            <FieldDescription
                instructions={true} marginBottom={true}
                text={`This Client User may be associated with one or more Clients, allowing the User to log into the 
                Parrot Media Portal and access Products and Orders for those Clients.`}
            />

            <FieldDescription
                instructions={true} marginBottom={true}
                text="Select from the list and click Add Client to associate the Client with this User."
            />
            <AutocompleteSelect
                source="org_id"
                label="Associate a Client"
                optionText="display_name"
                choices={orgListState}
                allowEmpty={true}
                required={false}
                options={{
                    placeholder: "Associate a Client",
                    required: false,
                }}
                onChange={value => handleOrgSelect(value)}
                className={classes.orgSelect}
            />
            <Button
                variant="contained"
                color="primary"
                disabled={(selectedOrgState === null)}
                onClick={() => addOrg()}
                startIcon={<AddIcon/>}
                className={classes.orgAddBtn}
            >
                Add Client
            </Button>
            <FieldDivider type="break"/>
            {!messageState &&
            <p className="message">&nbsp;</p>
            }
            {messageState &&
            <p className="message error">{messageState}</p>
            }

            {associatedOrgsState.length > 0 &&
            <>
                <FieldDescription instructions={true} text={`Associated Clients (click X icon to remove):`}/>
                <FieldDivider type="break" margin="bottom"/>
            </>
            }

            {associatedOrgsState.map((org) => (
                <span className={classes.orgChip} key={`associated-orgs-${org.org_id}`}>
                    <Chip label={org.org_name} onDelete={() => removeOrg(org.org_id)}/>
                </span>
            ))}

            <FieldDivider type="divider" margin="bottom"/>
        </>
    );
}

export default ClientUserOrganizationInput;
