import React, {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';
import Emitter from '../utils/eventEmitter';
import '../css/receipt.css';

const printStyle = `
@media print {
  @page {size: 8in 4in; margin: 0; page-break-after: auto;}
  @page :header {display: none;}
  @page :footer {display: none;}
  html, body {background: #ffffff; height: 100vh; overflow: initial !important;}
}`;

const printStyleSingle = `
@media print {
  @page {size: 8in 4in; margin: 0; page-break-after: auto;}
  @page :header {display: none;}
  @page :footer {display: none;}
  html, body {background: #ffffff; height: 100vh; overflow: hidden !important;}
}`;

const ReceiptPrintLabel = ({data, items}) => {

    const printLabelRef = useRef();
    const [record, setRecord] = useState(null);

    const handlePrintLabels = useReactToPrint({
        content: () => printLabelRef.current,
        copyStyles: true,
        documentTitle: 'Parrot Media Receipt Label',
        pageStyle: ((items.length > 0 && items.length === 1 && items[0].num_boxes === 1))
            ? printStyleSingle
            : printStyle
    });

    // Effect: Update layout with Receipt data
    useEffect(
        () => {
            const setLayout = () => {
                setRecord(data);
            };
            return setLayout();
        }, [data]
    );

    // Effect: Handle print event
    useEffect(
        () => {
            const printLabel = () => {
                handlePrintLabels();
            };

            // Add a listener to handle print button click
            Emitter.on('printReceipt', printLabel);

            return () => Emitter.off('printReceipt', printLabel);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    /**
     * Assemble list of print labels
     *
     * @param items
     * @returns {*}
     */
    const assembleLabels = (items) => {
        let newList = [];
        for (let i = 0; i < items.length; i++) {
            const item = JSON.parse(JSON.stringify(items[i]));
            item.key = i;
            item.box_idx = 1;
            // If this Receipt item arrived in multiple boxes, print one label per box
            const num = item?.num_boxes ?? 1;
            for (let b = 0; b < num; b++) {
                const boxItem = JSON.parse(JSON.stringify(item));
                boxItem.box_idx = b;
                boxItem.box_idx++;
                newList.push(boxItem);
            }
        }
        return newList;
    };

    const setRows = () => {
        const labels = assembleLabels(items);
        return (
            labels.map((item, index) => {
                const box_idx = (item.box_idx) ? item.box_idx : '';
                const num_boxes = (item.num_boxes) ? item.num_boxes : '0';
                const qty = (item.qty) ? item.qty : '';
                const show = (item.show_name) ? item.show_name : '';
                const sku = (item.sku) ? item.sku : '';
                const title = (item.title) ? item.title : '';
                const tracking_number = (record.tracking_number) ? record.tracking_number : '';
                const type = (item.type_name) ? item.type_name : '';

                return (
                    <div className="receipt-print-item" key={`receipt-print-item-${item.id}-${index}`}>
                        <h2>{record.org_name}</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td className="label">Title</td>
                                <td className="line" colSpan={6}>{show}</td>
                            </tr>
                            <tr>
                                <td className="label lines-2">Product</td>
                                <td className="line" colSpan={6}>{title}</td>
                            </tr>
                            <tr>
                                <td className="label">Type</td>
                                <td className="line half">{type}</td>
                                <td className="label center">Qty</td>
                                <td className="line right">{qty}</td>
                                <td className="label center">Box</td>
                                <td className="line right nowrap">{box_idx} of {num_boxes}</td>
                            </tr>
                            <tr>
                                <td className="label sku">SKU</td>
                                <td className="line sku" colSpan={6}>{sku}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="receipt-id">Receipt ID # {record.id}</div>
                        <div className="tracking-number">Tracking # {tracking_number}</div>
                    </div>
                )
            })
        )
    };

    return (
        <div style={{display: 'none'}}>
            <div ref={printLabelRef} className="receipt-label print-only">
                {record &&
                    <div className="content">
                        {[...setRows()]}
                    </div>
                }
            </div>
        </div>
    )

};

export default ReceiptPrintLabel;
