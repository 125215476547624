import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    BooleanField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import {SHIPPING_COMPANY_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

export const ShippingCompanyIcon = LocalShippingIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="active" label="Active"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Companies"><ShippingCompanyIcon
    style={viewStyles.avatarSvg}/></Avatar>Companies</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [showButtonColumn, editButtonColumn, ...SHIPPING_COMPANY_COLUMNS];

const listSort = {field: 'name', order: 'ASC'};

export const ShippingCompanyList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.id}
                />
            ) : (
                <DataTable
                    columns={listColumns}
                    sort={listSort}
                    messageEmpty=" No Shipping Companies found."
                    {...props}
                />
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Companies"><ShippingCompanyIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Company'}</span>;
};

export const ShippingCompanyShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Company details below. To edit this Company, click Edit above.
                `}
            />
            <TextField label="ID" source="id" className="input-inline input-120"/>
            <TextField label="Group Name" source="machine_name" className="input-inline input-280"/>
            <TextField label="Name of Company" source="name" className="field-bg"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<ShippingCompanyIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Company</span>;

export const ShippingCompanyCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add a Company by entering all fields below. Once created, a Group Name will be created for this Company 
                to associate with Shows, Products, and Orders.
                `}
            />
            <TextInput label="Name of Company" source="name" fullWidth={true}/>
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Companies List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ShippingCompanyIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.name}` : 'Company'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const ShippingCompanyEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Edit Company details below. Company ID and Group Name are unique and cannot be edited. If this Company is 
                no longer applicable or active, mark it as Inactive.
                `}
            />
            <TextInput disabled label="ID" source="id" formClassName="input-inline input-120"/>
            <TextInput disabled label="Group Name" source="machine_name" formClassName="input-inline input-280"/>
            <TextInput label="Name of Company" source="name" fullWidth={true}/>
            <FieldDescription
                description={true}
                text="If this Company is no longer active, mark it as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Companies List."
            />
        </SimpleForm>
    </Edit>
);

