import React from 'react';
import Emitter from '../utils/eventEmitter';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';

const PrintReceiptLabelButton = ({data}) => {

    const handlePrintClick = () => {
        Emitter.emit('printReceipt', data.id);
    };

    return (
        <Button
            color="primary"
            startIcon={<PrintIcon style={{marginRight: -2}}/>}
            onClick={() => handlePrintClick()}
            className="MuiButton-textSizeSmall MuiButton-sizeSmall"
            style={{marginLeft: 2}}
        >
            Print Labels
        </Button>
    )
};

export default PrintReceiptLabelButton;
