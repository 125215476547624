import React, {useState} from 'react';
import axios from 'axios';
import {useForm} from 'react-final-form';
import {NumberInput, SelectInput, TextInput, regex} from 'react-admin';
import {RESOURCES as resources} from '../resources';
import {getGetHeaders} from '../utils/headers';
import FieldDescription from '../components/FieldDescription';
import LocationsList from "./LocationsList";
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
    disabledType: {
        height: 0,
        overflow: 'hidden',
        opacity: 0,
        visibility: 'hidden'
    },
    icon: {
        display: 'inline-block',
        fontSize: 20,
        margin: '0 4px 10px 0',
        verticalAlign: 'middle'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 0 0.5em 0',
    },
    label: {
        display: 'block',
        fontSize: 16,
        fontWeight: 500,
        margin: '0 0 4px 0'
    },
    messageContainer: {
        display: 'block',
        margin: '10px 0 10px 0'
    },
    messageError: {
        display: 'inline-block',
        fontSize: 14,
        color: '#b4212d',
        verticalAlign: 'middle'
    },
    messageValid: {
        display: 'inline-block',
        fontSize: 14,
        color: '#508242',
        verticalAlign: 'middle'
    }
}));

const validateLocation = regex(/^[^ /]*$/, 'Locations cannot contain slashes or spaces.');

/**
 * A component to assemble and validate a unique Warehouse Location
 *
 * @param formData
 * @param orgId
 * @param onProductAdd
 * @returns {JSX.Element}
 * @constructor
 */
const LocationCreateInput = ({formData, orgId, onProductAdd}) => {

    const warehouseForm = useForm();

    const [isCustomState, setIsCustomState] = useState(false);
    const [rackNumberState, setRackNumberState] = useState(1);
    const [shelfLetterState, setShelfLetterState] = useState('');
    const [shelfNumberState, setShelfNumberState] = useState(1);
    const [shelfPositionState, setShelfshelfPositionState] = useState('L');
    const [locationAssembledState, setLocationAssembledState] = useState('');
    const [locationCustomState, setLocationCustomState] = useState('');
    const [locationsState, setLocationsState] = useState([]);
    const [availableState, setAvailableState] = useState({
        available: false,
        message: false
    });

    const resetValues = () => {
        warehouseForm.change('location', null);
        warehouseForm.change('org_id', null);
        warehouseForm.change('product_id', null);
        warehouseForm.change('qty', 1);
    };

    const handleInputType = (e) => {
        setIsCustomState(e.target.value);
        // Reset the 'location' value in the form
        resetValues();
    };

    const handleInputChange = (type, e) => {
        let value = (e && e.target && e.target.value) ? e.target.value : '';
        let isCustom = false;
        let customValue = '';

        if (type === 'rack_number') {
            if (value > 999) {
                value = 999;
            }
            setRackNumberState(value);
        }
        if (type === 'shelf_letter') {
            value = value.replace(/[^a-zA-Z]+/g, '');
            setShelfLetterState(value.toUpperCase());
        }
        if (type === 'shelf_number') {
            if (value > 999) {
                value = 999;
            }
            setShelfNumberState(value);
        }
        if (type === 'shelf_position') {
            setShelfshelfPositionState(value);
        }
        if (type === 'custom_location') {
            // Custom values override assembled values
            customValue = (value && value !== '') ? value.toUpperCase() : '';
            isCustom = true;
            setLocationCustomState(customValue);
        }

        if (!isCustom) {
            const rackNumber = (type === 'rack_number') ? value : rackNumberState;
            const shelfLetter = (type === 'shelf_letter') ? value : shelfLetterState;
            const shelfNumber = (type === 'shelf_number') ? value : shelfNumberState;
            const position = (type === 'shelf_position') ? value : shelfPositionState;
            const assembled = 'R' + rackNumber.toString() + '-' + shelfLetter + shelfNumber.toString() + '-' + position;
            setLocationAssembledState(assembled);
        }
        setAvailableState({available: false, message: false});
        if (locationsState.length > 0) {
            setLocationsState([]);
        }
        // Reset the 'location' value in the form
        resetValues();
    };

    const customAvailableButtonDisabled = (str) => {
        return !str ||
            str === '' ||
            str.indexOf(' ') !== -1 ||
            str.indexOf('/') !== -1;
    };

    const handleAvailabilityCheck = (type) => {
        let value = (type === 'custom') ? locationCustomState : locationAssembledState;
        let available = true;
        const cfg = resources.WarehouseLocationByLocation.GET_ONE({location: value});
        axios({
            method: 'GET',
            url: cfg.uri,
            headers: getGetHeaders()
        }).then((response) => {
            if (response && response.data) {
                const data = response.data;
                if (data && data.length > 0) {
                    let locations = [];
                    for (let i = 0; i < data.length; i++) {
                        locations.push(data[i]);
                    }
                    setLocationsState(locations);
                    // This Location exists with a Product in it, so is not "available"
                    available = false;
                }
            }
        }).catch((error) => {
            console.log('error response for Product: ', error);
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
        }).finally(() => {
            const locationValue = (available) ? (type === 'custom') ? locationCustomState : locationAssembledState : null;
            // Set the 'location' value in the form
            warehouseForm.change('location', locationValue);
            // Set messaging based on availability
            setAvailableState({available: available, message: true});
        });
    };

    const classes = useStyles();
    const disableCustomCheck = customAvailableButtonDisabled(locationCustomState);

    return (
        <>
            <SelectInput source="is_custom" label="Assemble Location or Custom?"
                         defaultValue={isCustomState}
                         choices={[
                             {id: false, name: 'Assemble Value'},
                             {id: true, name: 'Custom Value'}
                         ]}
                         allowEmpty={false}
                         onChange={(e) => handleInputType(e)}
                         className="input-280"
            />

            {/* Assembled Location */}

            <div className={(isCustomState) ? classes.disabledType : ''}>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text={`Assemble a Location value: Rack Number, Shelf Letter, Shelf Number, and Shelf Position. 
                    Then click Check Availability to verify.`}
                />
                <div className={classes.inputContainer}>
                    <NumberInput label="Rack" source="rack_number" className="input-inline input-90"
                                 min={0} max={999} step={1}
                                 value={rackNumberState}
                                 onChange={(e) => handleInputChange('rack_number', e)}/>
                    <TextInput label="Shelf" source="shelf_letter" className="input-inline input-90"
                               inputProps={{
                                   maxLength: 2
                               }}
                               value={shelfLetterState}
                               onChange={(e) => handleInputChange('shelf_letter', e)}/>
                    <NumberInput label="Shelf #" source="shelf_number" className="input-inline input-90"
                                 min={0} max={999} step={1}
                                 value={shelfNumberState}
                                 onChange={(e) => handleInputChange('shelf_number', e)}/>
                    <SelectInput source="shelf_position" label="Position"
                                 choices={[
                                     {id: 'L', name: 'Left'},
                                     {id: 'R', name: 'Right'},
                                     {id: 'C', name: 'Center'}
                                 ]}
                                 allowEmpty={false}
                                 className="input-inline input-120"
                                 onChange={(e) => handleInputChange('shelf_position', e)}
                    />
                    <TextInput label="Location Value" source="location_value_assembled"
                               value={locationAssembledState}
                               disabled
                               className="input-inline input-180"/>
                </div>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text="Upon assembling or changing a Location value, click Check Availability to verify."
                />
            </div>

            {/* Custom Location */}

            <div className={(!isCustomState) ? classes.disabledType : ''}>
                <FieldDescription
                    description={true} marginBottom={true}
                    text={`Or, if you need a custom Location value, enter it below.`}
                />
                <div className={classes.inputContainer}>
                    <TextInput label="Custom Location" source="custom_location"
                               onChange={(e) => handleInputChange('custom_location', e)}
                               className="input-inline input-280"
                               validate={validateLocation}
                    />
                    <TextInput label="Location Value" source="location_value_custom"
                               value={locationCustomState}
                               disabled
                               className="input-inline input-180"/>
                </div>
                <FieldDescription
                    description={true} marginBottom={true}
                    text="Upon entering or changing a custom Location value, click Check Availability to verify."
                />
            </div>

            {/* Availability Check */}

            {!isCustomState &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={(!locationAssembledState || locationAssembledState === '')}
                    onClick={() => handleAvailabilityCheck('assembled')}
                    startIcon={<VisibilityIcon/>}
                    className="input-inline"
                >
                    Check Availability
                </Button>
            }
            {isCustomState &&
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={disableCustomCheck}
                    onClick={() => handleAvailabilityCheck('custom')}
                    startIcon={<VisibilityIcon/>}
                >
                    Check Availability
                </Button>
            }

            {/* Availability Messaging */}

            <div className={classes.messageContainer}>
                {availableState.message &&
                    <>
                        {!availableState.available &&
                            <div className="btns-container">
                                <HighlightOffIcon className={classes.icon}/>
                                <span className={classes.messageError}>
                            A Location with this value exists and is listed below with any Products it
                            contains. Please assemble or add a different Location value.
                        </span>
                            </div>
                        }
                        {availableState.available &&
                            <div className="btns-container">
                                <CheckCircleOutlinedIcon className={classes.icon}/>
                                <span className={classes.messageValid}>
                            Warehouse Location {(isCustomState) ? locationCustomState : locationAssembledState} is available to create.
                        </span>
                            </div>
                        }
                    </>
                }
            </div>

            {/* Locations List */}

            <LocationsList itemList={locationsState} hasEdit={false}/>

        </>
    )
}

export default LocationCreateInput;
