import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {SelectInput} from 'react-admin';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from './FieldDivider';

const titleActiveStyles = {
    description: {
        flex: '1 1 40%',
        display: 'block',
        color: '#524b50',
        minHeight: '2em',
        fontSize: '12px'
    },
    underline: {
        textDecoration: 'underline'
    }
};

/**
 * A component to enable or disable a Title based on active Products
 *
 * @param formData
 * @returns {JSX.Element}
 * @constructor
 */
const TitleActiveInput = ({formData}) => {

    const [titleId] = useState(formData.id ?? '');
    const [hasProducts, setHasProducts] = useState(false);
    const [productsRetrieved, setProductsRetrieved] = useState(false);

    // Effect: Fetch active Products by Title ID
    useEffect(
        () => {
            if (titleId && !productsRetrieved) {
                const fetchItems = () => {
                    const params = {
                        active: true,
                        params: {
                            filter: {
                                filter_by: 'show',
                                filter_show_id: titleId
                            }
                        }
                    };

                    const cfg = resources.Product.GET_LIST(params);
                    axios({
                        method: 'GET',
                        url: cfg.uri,
                        headers: getGetHeaders(),
                        maxBodyLength: Infinity,
                        maxContentLength: Infinity
                    }).then((response) => {
                        if (response.data && response.data.length > 0) {
                            setHasProducts(true);
                        }
                    }).catch((error) => {
                        console.log('error response for Products: ', error);
                    }).finally(() => {
                        setProductsRetrieved(true);
                    });
                };

                if (titleId || !productsRetrieved) {
                    fetchItems();
                }
            }

        }, [titleId, productsRetrieved]
    );

    let disabled = (formData.active && hasProducts);

    return (
        <>
            {!formData.active &&
                <FieldDescription
                    description={true}
                    text="If this Title should be available and have Products associated with it, mark it as Active."
                />
            }
            {!disabled && formData.active &&
                <FieldDescription
                    description={true}
                    text="If this Title is no longer to be referenced, mark it as Inactive."
                />
            }
            {disabled && formData.active &&
                <>
                    <FieldDescription
                        description={true}
                        text="This Title has active Products associated with it, and cannot be marked as Inactive."
                    />
                    <span style={titleActiveStyles.description}>
                        If you wish to mark this Title as Inactive, search
                        for <Link to={`/Product`} title="Products" style={titleActiveStyles.underline}>Products</Link> with
                        this Title and move them to another Title, or mark them as Inactive.
                    </span>
                </>
            }

            <FieldDivider type="break" margin="bottom"/>
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
                         disabled={disabled}
                         className="input input-220"
            />
        </>
    )
}

export default TitleActiveInput;
