import axios from 'axios';
import {RESOURCES as resources} from './resources';
import {RESOURCES as orderResources} from './orderResources';
import {RESOURCES as productResources} from './productResources';
import {RESOURCES as receiptResources} from './receiptResources';
import {getGetHeaders, getPostHeaders} from './utils/headers';
import {formatGetParams, formatPostData, interceptLoadData} from './dataProviderPreProcess';
import {getListFromDataObject, preProcessSingleListData, postProcessData, setUniqueKeys} from './dataProviderPostProcess';
import {generateUUID} from './utils/dataFunctions';

const resourceMap = {
    "ClientUser": resources.ClientUser,
    "ClientUserOrg": resources.ClientUserOrg,
    "Contact": resources.Contact,
    "Employee": resources.Employee,
    "Organization": resources.Organization,
    "ProductType": resources.ProductType,
    "ParentProductType": resources.ParentProductType,
    "ShippingCompany": resources.ShippingCompany,
    "ShippingMethod": resources.ShippingMethod,
    "Show": resources.Show,
    "WarehouseLocation": resources.WarehouseLocation,

    "BillingReference": orderResources.BillingReference,
    "Order": orderResources.Order,
    "OrderCreate": orderResources.OrderCreate,
    "OrderStart": orderResources.OrderStart,
    "OrderItem": orderResources.OrderItem,
    "OrdersByStatus": orderResources.OrdersByStatus,

    "Receipt": receiptResources.Receipt,
    "ReceiptCreate": receiptResources.ReceiptCreate,
    "ReceiptStart": receiptResources.ReceiptStart,
    "ReceiptItem": receiptResources.ReceiptItem,

    "Product": productResources.Product,
    "ProductById": productResources.ProductById,
    "ProductBySku": productResources.ProductBySku,
    "ProductsByOrg": productResources.ProductsByOrg,
    "ProductsByShow": productResources.ProductsByShow,
    "ProductsByTitle": productResources.ProductsByTitle,
    "ProductsByType": productResources.ProductsByType,
    "InventoryLedgerAdjustment": productResources.InventoryLedgerAdjustment,
    "InventoryLedger": productResources.InventoryLedger
};

/**
 * Parse data for Get or Post returns for React-Admin use
 */
const parseResponse = (resource, type, rdata, total, parseParams, params) => {

    let data = rdata;
    if (!data) {
        return;
    }

    // Get List
    if (type === 'GET_LIST') {
        let keys = [];

        for (let i = 0; i < data.length; i++) {
            if (parseParams && parseParams.hasNameKeys) {
                // Resources with "name" as unique key
                keys.push(data[i].name);
                data[i].id = data[i].name;
            } else if (parseParams && parseParams.needsId) {
                // Resources that require a unique ID
                data[i].id = generateUUID();
                keys.push(data[i].id);
            } else if (parseParams && parseParams.idKey) {
                // Resources with a unique key that is not "id"
                let idValue = null;
                if (parseParams.parentKey) {
                    idValue = data[i][parseParams.parentKey][parseParams.idKey];
                } else {
                    idValue = data[i][parseParams.idKey];
                }
                keys.push(idValue);
                data[i].id = idValue;
            } else {
                keys.push(data[i].id);
            }
        }

        let dataTotal = (total) ? total : rdata.length;
        if (!dataTotal) {
            dataTotal = 0;
        }

        return {
            "data": data,
            "ids": keys,
            "total": dataTotal
        }
    }

    // Get One: Return either first element in data array or data
    if (type === 'GET_ONE') {
        // Process any single item comprised of an array
        data = preProcessSingleListData(resource, type, params, rdata);

        if (parseParams && parseParams.needsId) {
            // If the Resource needs a generated ID:
            const id = generateUUID();
            if (data[0]) {
                data[0].id = id;
            } else {
                data.id = id;
            }
        }
        if (parseParams && parseParams.idKey) {
            // If the Resource has a unique key to use as the ID:
            if (data[0]) {
                if (parseParams.parentKey) {
                    data[0].id = data[0][parseParams.parentKey][parseParams.idKey];
                } else {
                    data[0].id = data[0][parseParams.idKey];
                }
            } else {
                if (parseParams.parentKey) {
                    data.id = data[parseParams.parentKey][parseParams.idKey];
                } else {
                    data.id = data[parseParams.idKey];
                }
            }
        }
        return data[0] ? {"data": data[0]} : {"data": data};
    }

    // Create and Edit: Return data
    if (type === 'CREATE' || type === 'UPDATE') {
        return data;
    }

    // Delete: Return data
    if (type === 'DELETE') {
        return data;
    }

    return {"data": {}}
};

const getParams = {
    resource: '',
    params: {}
};

const getParrotData = (type, resource, params, resolve, reject) => {
    // If request lacks data and should not automatically get data, resolve as empty
    const emptyData = {data: [{id: '', name: ''}], ids: [''], total: 0};
    if (interceptLoadData(type, resource, params)) {
        resolve(emptyData);
        return;
    }

    // If request is repeated, return
    if (
        type === 'GET_LIST' &&
        resource === getParams.resource &&
        JSON.stringify(params) === JSON.stringify(getParams.params)
    ) {
        return;
    }
    // Store the previous call
    getParams.resource = resource;
    getParams.params = JSON.parse(JSON.stringify(params));

    const formattedParams = formatGetParams(type, resource, params);

    let cfg = resourceMap[resource][type](formattedParams);
    let rdata;

    return axios({
        method: cfg.method,
        url: cfg.uri,
        headers: getGetHeaders(),
        maxBodyLength: Infinity,
        maxContentLength: Infinity
    }).then((response) => {

        if (type === 'GET_LIST' && !response.data) {
            resolve(emptyData);
        }
        if (response.data) {
            // Mark resources with no ids or variable unique identifiers
            const parseParams = setUniqueKeys(resource);

            // If the response is an object, convert it and extract the array and total
            let objectResponse = getListFromDataObject(resource, type, response.data);

            // Parse the response
            rdata = parseResponse(resource, type, objectResponse.list, objectResponse.total, parseParams, params);

            // If no payload is available, display an error
            if (!rdata || (rdata.data && typeof rdata.data !== 'object')) {
                reject('No ' + cfg.title + ' found.');
                return;
            }

            // Post-process data for specific resources
            rdata = postProcessData(resource, type, params, rdata);
            if (type === 'GET_LIST' && (!rdata.ids.length || !rdata.data.length)) {
                resolve(emptyData);
            } else {
                resolve(rdata);
            }
        }
        resolve(response);
    }).catch((error) => {
        if (error.response) {
            // Server response outside 2xx
            reject(error.response.data);
        } else if (error.request) {
            // No response
            reject(error.request);
        }
        reject(error);
    });
};

const sendParrotData = (type, resource, params, resolve, reject) => {

    const formattedData = formatPostData(type, resource, params.data);
    let cfg = resourceMap[resource][type](formattedData);

    return axios({
        method: cfg.method,
        url: cfg.uri,
        data: formattedData,
        headers: getPostHeaders(true)
    }).then((response) => {
        if (!response) {
            reject('Data Error');
        } else {
            if (response.error) {
                reject('Data Error: ' + response.message);
            }

            // If no data key is available:
            if (!params.data && params.previousData) {
                params.data = params.previousData;
            }
            // Create now requires an ID
            if (type === 'CREATE' && !params.data.id) {
                params.data.id = 1;
            }

            const responseData = (response.data) ? response.data : response;

            setTimeout(function () {
                let resolveObj = {
                    'data': params.data,
                    'response': responseData
                };
                resolve(resolveObj);
            }, 600);
        }
    }).catch((error) => {
        console.log('sendParrotData error ', error);
        if (error.response) {
            // Server response outside 2xx
            reject(error.response.data);
        } else if (error.request) {
            // No response
            reject(error.request);
        }
        reject(error);
    });
};

const dataProvider = (type, resource, params) => {
    if (type === 'GET_LIST' || type === 'GET_ONE' || type === 'GET_MANY' || type === 'GET_MANY_REFERENCE') {
        return new Promise((resolve, reject) => {
            getParrotData(type, resource, params, resolve, reject);
            return true;
        })
    }
    if (type === 'CREATE' || type === 'UPDATE' || type === 'DELETE' || type === 'POST_GET_LIST') {
        return new Promise((resolve, reject) => {
            sendParrotData(type, resource, params, resolve, reject);
            return true;
        })
    }
};

export default dataProvider;
