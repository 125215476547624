let authProviderToken = null;

/**
 * Store token for use with this provider
 *
 * @param token
 */
export function setAuthProviderToken(token) {
    if (authProviderToken !== token) {
        authProviderToken = token;
    }
}

const authProvider = {
    // Called when user attempts to log in (unused)
    login: () => {
        return Promise.resolve();
    },

    // Called when user clicks on RA logout button (unused)
    logout: () => {
        return Promise.resolve();
    },

    // Called when API returns an error
    checkError: ({status}) => {
        // console.log('authProvider checkError: ', status);
        if (!authProviderToken || status === 401 || status === 403 || status === 'missing required token') {
            return Promise.reject();
        }
        return Promise.resolve();
    },

    // Called when user navigates to a new location, to check for authentication
    checkAuth: (props) => {
        // console.log('authProvider checkAuth: ', props);
        // return Promise.resolve();
        const openRoutes = [
            'dashboard'
        ];
        const allowed = (props.route && (openRoutes.findIndex(r => r === props.route) !== -1));
        return (authProviderToken || allowed)
            ? Promise.resolve()
            : Promise.reject();
    },

    // Called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        // console.log('authProvider getPermissions');
        // return Promise.resolve('test');

        return (authProviderToken)
            ? Promise.resolve()
            : Promise.reject();
    },
};

export default authProvider;
