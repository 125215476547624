import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {RESOURCES as resources} from '../resources';
import {getGetHeaders} from '../utils/headers';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useClientUserOrgStyles = makeStyles((theme) => ({
    userChip: {
        display: 'inline-block',
        margin: '0 1em 1em 0',
        verticalAlign: 'middle'
    }
}));

const OrganizationClientUserField = ({record}) => {

    const [associatedUsersState, setAssociatedUsersState] = useState([]);

    useEffect(
        () => {
            const params = {
                active: true,
                org_id: record.id,
                params: {
                    filter: {},
                    pagination: {page: 1, perPage: 10000},
                    sort: {field: "name", order: "ASC"}
                }
            };

            const cfg = resources.ClientUsersByOrg.GET_LIST(params);
            axios({
                method: 'GET',
                url: cfg.uri,
                headers: getGetHeaders(),
                maxBodyLength: Infinity,
                maxContentLength: Infinity
            }).then((response) => {
                if (response.data && response.data.length > 0) {
                    setAssociatedUsersState(response.data);
                }
            }).catch((error) => {
                console.log('error response for Clients: ', error);
                if (error.response) {
                    // Server response outside 2xx
                } else if (error.request) {
                    // No response
                }
            });

        }, [record.id]
    );

    const renderUsers = () => {
        return (
            associatedUsersState.map((user) => (
                <span className={classes.userChip} key={`associated-users-${user.username}`}>
                    <Chip
                        clickable
                        component={Link}
                        to={`/ClientUser/${user.username}`}
                        label={`${user.first_name} ${user.last_name}`}
                    />
                </span>
            ))
        )
    };

    const classes = useClientUserOrgStyles();

    return (
        <>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`This Client may be associated with one or more Client Users. Each User may log into the 
                Parrot Media Portal and access Products and Orders for this Client.`}
            />

            {associatedUsersState.length === 0 &&
            <FieldDescription description={true} text={`No Users are associated with this Client. To add a Client 
            User, go to Client Users and add or edit one.`}/>
            }
            {associatedUsersState.length > 0 &&
            <>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text={`If a User should be removed from this Client, click the User to edit.`}
                />
            </>
            }
            <FieldDivider type="break" margin="bottom"/>

            {[...renderUsers()]}

            <FieldDivider type="divider" margin="bottom"/>
        </>
    );
}

export default OrganizationClientUserField;
