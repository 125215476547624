import React from 'react';
import {toCurrency} from '../utils/dataFunctions';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useTotalsStyles = makeStyles({
    root: {
        width: 110,
        textAlign: 'right',
        '&.MuiFormControl-root': {
            width: 110,
        },
        '& .MuiInputLabel-shrink': {
            left: 'auto',
            right: 0,
            fontWeight: '600'
        },
        '& .MuiInput-root': {
            fontWeight: '600',
        },
        '& .MuiInputBase-root': {
            fontWeight: '600',
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none'
        }
    }
});

const OrderReceiptCostTotals = ({record, type, hasLabel = true}) => {
    const labor_cost = parseFloat((record.labor_cost && record.labor_cost !== 'NaN') ? record.labor_cost : '0.00');
    const material_cost = parseFloat((record.material_cost && record.material_cost !== 'NaN') ? record.material_cost : '0.00');
    const receiving_cost = parseFloat((record.receiving_cost && record.receiving_cost !== 'NaN') ? record.receiving_cost : '0.00');
    const shipping_cost = parseFloat((record.shipping_cost && record.shipping_cost !== 'NaN') ? record.shipping_cost : '0.00');
    const courier_cost = parseFloat((record.courier_cost && record.courier_cost !== 'NaN') ? record.courier_cost : '0.00');
    let total;
    if (type === 'order') {
        total = labor_cost + material_cost + shipping_cost + courier_cost;
    }
    if (type === 'receipt') {
        total = labor_cost + material_cost + receiving_cost + courier_cost;
    }
    const totalCurrency = toCurrency(total);

    const classes = useTotalsStyles();

    return (
        <TextField
            variant={(hasLabel) ? undefined : 'outlined'}
            id="order-receipt-cost-totals"
            label={(hasLabel) ? 'TOTAL' : ''}
            inputProps={{
                'aria-label': 'naked',
                readOnly: true
            }}
            aria-readonly={true}
            value={totalCurrency}
            className={classes.root}
        />
    );
}

export default OrderReceiptCostTotals;
