import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    TextField,
    FormDataConsumer,
    SelectInput,
    TextInput,
    SaveButton,
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import ClientUserOrganizationField from '../components/ClientUserOrganizationField';
import ClientUserOrganizationInput from '../components/ClientUserOrganizationInput';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import {CLIENT_USER_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

export const ClientUserIcon = PersonIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="active" label="Active Client Users"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar
    style={viewStyles.avatar} className="avatar-title" alt="Client Users"><ClientUserIcon
    style={viewStyles.avatarSvg}/></Avatar>Client Users</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [showButtonColumn, editButtonColumn, ...CLIENT_USER_COLUMNS];

const listSort = {field: 'username', order: 'ASC'};

export const ClientUserList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.first_name + ' ' + record.last_name}
                    secondaryText={record => record.department}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable columns={listColumns} sort={listSort} messageEmpty=" No Client Users found." {...props}/>
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Client User"><ClientUserIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.username}` : 'Client User'}</span>;
};

export const ClientUserShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Client User details below. To edit this Client User or associate one or more Clients with this 
                User, click Edit above.
                `}
            />
            <TextField label="Username" source="username"/>
            <TextField label="First Name" source="first_name" className="input-inline input-220"/>
            <TextField label="Last Name" source="last_name" className="input-inline input-220"/>
            <FieldDivider type="break"/>
            <TextField label="Email" source="email" className="field-bg"/>
            <TextField label="Admin Notes" source="notes" className="field-bg"/>
            <TextField label="Active" source="active"/>
            <ClientUserOrganizationField record={props.record} {...props}/>
        </SimpleShowLayout>
    </Show>
);

const usernameValidation = (value, allValues) => {
    if (value) {
        if (value !== value.toLowerCase()) {
            return 'Usernames must be lowercase.';
        }
        const usernameRegex = /^[a-z0-9_]+$/;
        if (!usernameRegex.test(value)) {
            return 'Usernames must contain no special characters.';
        }
    }
    return null;
};
const validateUsername = [usernameValidation];

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<ClientUserIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Client User</span>;

const createRedirect = (basePath, id, data) => `/ClientUser/${data.username}/username`;

export const ClientUserCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect={createRedirect} submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add a Client User by entering a unique Username, First and Last Name, and Email.
                `}
            />
            <FieldDescription
                description={true}
                text={`
                Username must be lowercase, with no special characters except underscores.
                `}
            />
            <TextInput label="Username" source="username" validate={validateUsername}/>
            <TextInput label="First Name" source="first_name" formClassName="input-inline input-220"/>
            <TextInput label="Last Name" source="last_name" formClassName="input-inline input-220"/>
            <FieldDivider type="break"/>
            <TextInput label="Email Address" source="email" formClassName="input-inline half"/>
            <TextInput label="Admin Notes" source="notes" multiline fullWidth={true}/>
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Client Users List. You may then edit this Client User and associate one or more Clients."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ClientUserIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.username}` : 'Client User'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const ClientUserEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Edit Client User details below. User may be associated with one or more Clients.
                `}
            />
            <TextInput disabled label="Username" source="username"/>
            <TextInput label="First Name" source="first_name" formClassName="input-inline input-220"/>
            <TextInput label="Last Name" source="last_name" formClassName="input-inline input-220"/>
            <FieldDivider type="break"/>
            <TextInput label="Email Address" source="email" formClassName="input-inline half"/>
            <TextInput label="Admin Notes" source="notes" multiline fullWidth={true}/>
            <FieldDescription
                description={true}
                text="If this Client User is no longer valid, mark as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />

            <FormDataConsumer {...props}>
                {({formData, ...rest}) => (
                    <ClientUserOrganizationInput formData={formData}/>
                )}
            </FormDataConsumer>

            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Client Users List."
            />
        </SimpleForm>
    </Edit>
);

