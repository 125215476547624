import axios from 'axios';
import {RESOURCES as orderResources} from '../orderResources';
import {RESOURCES as receiptResources} from '../receiptResources';
import {getGetHeaders} from './headers';
import {sortArrayByKey} from './dataFunctions';

/**
 * Get started Orders or Receipts stored in localstorage
 *
 * @param type
 * @returns {any|null}
 */
export const getStoredOrders = (type) => {
    let stored = (type === 'receipt')
        ? localStorage.getItem('stored_receipts')
        : localStorage.getItem('stored_orders');
    if (stored) {
        return JSON.parse(stored);
    }
    return null;
};

export const getInitialStoredOrders = (type) => {
    let list = getStoredOrders(type);
    if (list) {
        list = sortArrayByKey(list, 'id', 'ASC');
    }
    return list || [];
};

/**
 * Get a single stored Order or Receipt by ID
 *
 * @param type
 * @param id
 * @returns {*|null}
 */
export const getStoredOrderById = (type, id) => {
    let list = getStoredOrders(type);
    if (list) {
        return list.find(r => r.id.toString() === id.toString());
    }
    return null;
};

/**
 * Determine if a stored Order or Receipt may be resumed or deleted
 *
 * @param type
 * @param id
 * @returns {Promise<boolean>}
 */
export const allowStoredOrderReceipt = async (type, id) => {
    const typeStr = (type === 'receipt') ? 'Receipt' : 'Order';
    let allowed = true;
    let cfg;
    if (typeStr === 'Order') {
        cfg = orderResources.Order.GET_ONE({id: id});
    }
    if (typeStr === 'Receipt') {
        cfg = receiptResources.Receipt.GET_ONE({id: id});
    }
    await axios({
        method: 'GET',
        url: cfg.uri,
        headers: getGetHeaders()
    }).then((response) => {
        if (response.data && response.data.id && !response.data.created_on) {
            // Order or Receipt is in a started state and may be resumed or deleted
            allowed = true;
        } else {
            // Order or Receipt does not exist or has been completed
            allowed = false;
        }
    }).catch((error) => {
        // Order or Receipt does not exist
        allowed = false;
    });
    return allowed;
};

/**
 * Store data for an Order or Receipt in progress
 *
 * @param type
 * @param data
 */
export const updateStoredOrders = (type, data) => {
    if (data.id) {
        let list = getStoredOrders(type);
        if (list) {
            let storedIdx = list.findIndex(r => r.id.toString() === data.id.toString());
            if (storedIdx !== -1) {
                // Merge data into stored item
                list[storedIdx] = {...list[storedIdx], ...data};
            } else {
                list.push(data);
            }
        } else {
            list = [data];
        }
        storeOrders(type, JSON.stringify(list));
    }
};

/**
 * Delete an Order or Receipt from storage
 *
 * @param type
 * @param id
 */
export const removeStoredOrder = (type, id) => {
    let list = getStoredOrders(type);
    let storedIdx = list.findIndex(r => r.id.toString() === id.toString());
    if (storedIdx !== -1) {
        list.splice(storedIdx, 1);
        storeOrders(type, JSON.stringify(list));
    }
};

/**
 * Store Order or Receipt in localstorage
 *
 * @param type
 * @param str
 */
const storeOrders = (type, str) => {
    const store = (type === 'receipt') ? 'stored_receipts' : 'stored_orders';
    localStorage.setItem(store, str);

};
