import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders, getPostHeaders} from '../utils/headers';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    btns: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: 80
    },
    btn: {
        marginTop: -4,
        marginRight: 4,
        padding: 2
    },
    btnEdit: {
        marginTop: -3,
        marginRight: 4,
        marginLeft: 2,
        padding: 2
    },
    btnText: {
        display: 'inline-block',
        margin: '0 0 4px 0',
        padding: '0px 10px'
    },
    input: {
        width: 'auto',
        margin: '0 6px 0 0'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        position: 'relative',
        width: '100%'
    },
    saving: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    textError: {
        borderColor: 'red'
    }
});

/**
 * Field and editable input for Product name in tables
 *
 * @param item
 * @param onSaveEditProductTitle
 * @return {*}
 * @constructor
 */
const ProductEditTitle = ({item, onSaveEditProductTitle}) => {

    const [product, setProduct] = useState(null);
    const [editing, setEditing] = useState(false);
    const [newTitle, setNewTitle] = useState(item.title);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        const updateItem = () => {
            setNewTitle(item.title);
        };
        return updateItem();
    }, [item]);

    const startEdit = () => {
        setEditing(true);
        getProduct();
    };

    const handleChange = (e) => {
        setNewTitle(e.target.value);
        if (e.target.value === '') {
            setError({
                error: true,
                message: 'Product Title is required'
            });
        } else {
            setError(null);
        }
    };

    const stopEdit = () => {
        setEditing(false);
        setSaving(false);
        setError(null);
        setNewTitle(product.title ?? item.title);
    };

    const saveEdit = () => {
        if (newTitle !== '') {
            setSaving(true);
            postEditProduct();
        }
    };

    const getProduct = () => {
        const id = item.product_id ?? item.id;
        const cfg = resources.Product.GET_ONE({id: id});
        axios({
            method: 'GET',
            url: cfg.uri,
            headers: getGetHeaders()
        }).then((response) => {
            if (response.data && response.data.id) {
                setProduct(response.data);
            }
        }).catch((error) => {
            console.log('Product error: ', error);
            if (error.response) {
            } else if (error.request) {
            }
            setProduct(null);
        });

    };

    const postEditProduct = () => {
        let params = {
            id: product.id,
            org_id: product.org_id,
            show_id: product.show_id,
            type_id: product.type_id,
            thumbnail: product.thumbnail,
            sku: product.sku,
            title: newTitle,
            description: product.description,
            notes: product.notes,
            qty: product.qty,
            active: product.active
        };
        const resource = resources.Product.UPDATE({params});
        let errorMessage = 'Error saving Product Title.';
        axios({
            method: resource.method,
            url: resource.uri,
            data: params,
            headers: getPostHeaders(true)
        }).then((response) => {
            if (response.data && response.data.id) {
                let productObj = JSON.parse(JSON.stringify(product));
                productObj.title = newTitle;
                setProduct(productObj);
                stopEdit();
                if (typeof onSaveEditProductTitle === 'function') {
                    onSaveEditProductTitle(productObj);
                }
            } else {
                setSaving(false);
                setError({
                    error: true,
                    message: errorMessage
                });
            }
        }).catch((error) => {
            if (error.response) {
            } else if (error.request) {
                // No response
            }
            setSaving(false);
            setError({
                error: true,
                message: errorMessage
            });
        });
    };

    const classes = useStyles();

    return (
        <span className={classes.container}>
            {!editing &&
                <span>
                    <span>{item.title} &nbsp;</span>
                    <IconButton
                        aria-label="Edit"
                        className={classes.btnEdit}
                        onClick={() => startEdit()}
                    >
                        <EditIcon/>
                    </IconButton>
                </span>
            }
            {editing &&
                <span className={classes.container}>
                    <TextField
                        variant="outlined"
                        id={`edit-product-item-title-${item.id}`}
                        label=""
                        multiline={true}
                        minRows={2}
                        defaultValue={newTitle}
                        disabled={(saving)}
                        className="input-table-small product-name mr-1"
                        error={!!(error)}
                        onChange={(e) => handleChange(e)}
                    />
                    {!saving &&
                        <span className={classes.btns}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.btnText}
                                onClick={() => saveEdit()}
                                disabled={(!product || (product.title === newTitle) || (newTitle === ''))}
                            >
                                Update
                            </Button>
                            <Button
                                variant="contained"
                                color="default"
                                className={classes.btnText}
                                onClick={() => stopEdit()}
                            >
                                Cancel
                            </Button>
                        </span>
                    }
                    {saving &&
                        <span className={classes.saving}>Saving...</span>
                    }
                </span>
            }
        </span>
    )
};

export default ProductEditTitle;
