import axios from 'axios';
import {getDeleteHeaders, getPostHeaders} from './headers';
import {RESOURCES as resources} from '../resources';
import {RESOURCES as orderResources} from '../orderResources';
import {RESOURCES as productResources} from '../productResources';
import {RESOURCES as receiptResources} from '../receiptResources';

/*
 * API assembly
 * - asyncTimeout
 * - asyncAPICall
 * - runFileUploadTask
 * - assemblePostTasks
 *
 * API calls
 * - postProductImage
 * - postProductUpdate
 * - postOrderUpdate
 * - postProductWarehouseLocation
 * - postInventoryAdjustment
 * - postDeleteItemById
 */

/**
 * Return a Promise for a timeout
 *
 * @param delay
 * @return {Promise<string>}
 */
const asyncTimeout = (delay) => {
    return (
        new Promise(resolve => {
            setTimeout(() => resolve(delay), delay)
        }))
        .then(d => `Waited ${d} seconds`);
};

/**
 * Return a Promise for API calls
 *
 * @param data
 * @return {Promise<unknown>}
 */
const asyncAPICall = (data) => {
    if (data.isAdjustment) {
        // Post a Product Inventory Adjustment
        return postInventoryAdjustment(data.data, data.progressFunc);
    } else if (data.isOrderValueUpdate) {
        // Post an Order Update for a single value
        return postOrderUpdate(data, data.progressFunc);
    } else if (data.isProductValueUpdate) {
        // Post a Product Update for a single value
        return postProductUpdate(data, data.progressFunc);
    } else if (data.product_image_input) {
        // Post a Product Image Upload
        return postProductImage(data.data, data.progressFunc);
    }
    return postProductImage(data.data, data.progressFunc);
};

/**
 * Execute a Promise-based task
 *
 * @param spec
 * @return {*}
 */
export const runFileUploadTask = (spec) => {
    return (spec && spec.task === 'wait') ? asyncTimeout(spec.duration) : asyncAPICall(spec.data);
};

/**
 * Assemble API calls in a queue
 *
 * @param data
 * @param progressFunc
 */
export const assemblePostTasks = async (data, progressFunc) => {
    // Assemble an array of actions to execute sequentially
    let tasks = [];
    let taskLength = 0;

    // Add Product Image file
    if (data.product_image_input && data.product_image_input.rawFile) {
        tasks.push({
            index: taskLength,
            task: 'post',
            data: {
                data: data,
                isAdjustment: false,
                isOrderValueUpdate: false,
                isProductValueUpdate: false,
                index: taskLength,
                progressFunc: progressFunc
            }
        });
        taskLength++;
    }

    // Add Product Inventory Adjustment
    if (data.inventory_adjustment) {
        tasks.push({
            index: taskLength,
            task: 'post',
            data: {
                data: data,
                isAdjustment: true,
                isOrderValueUpdate: false,
                isProductValueUpdate: false,
                index: taskLength,
                progressFunc: progressFunc
            }
        });
        taskLength++;
    }

    // Add update to single value for Order or Product
    if (data.selectedIds && data.updateValue !== undefined && data.updateValue !== null) {
        for (let i = 0; i < data.selectedIds.length; i++) {
            tasks.push({
                index: taskLength,
                task: 'post',
                data: {
                    data: data,
                    isAdjustment: false,
                    isOrderValueUpdate: data.isOrderValueUpdate,
                    isProductValueUpdate: data.isProductValueUpdate,
                    updateId: data.selectedIds[i],
                    updateType: data.updateType,
                    updateValue: data.updateValue,
                    index: taskLength,
                    progressFunc: progressFunc
                }
            });
            taskLength++;
        }
    }

    // Start a Promise sequence with all tasks
    const starterPromise = Promise.resolve(null);
    await tasks.reduce(
        (p, spec) => p.then(() => runFileUploadTask(spec)
            .then((result) => {
                // If all tasks in the list have completed:
                if (spec.index >= (taskLength - 1)) {
                    return true;
                }
            })), starterPromise)
        .catch((error) => {
            console.log('Error: ', error);
            return false;
        });
};

/**
 * Post an Image for a specific Product
 *
 * @param data
 * @param progressFunc
 * @return {Promise<unknown>}
 */
export const postProductImage = (data, progressFunc = null) => {
    return new Promise(function (resolve, reject) {
        // If a rawFile File object has been provided by the ImageInput:
        if (data && data.product_image_input.rawFile) {

            // Lift loading progress if requested
            const progressConfig = {
                onUploadProgress: function (progressEvent) {
                    if (typeof progressFunc === 'function') {
                        progressFunc('fileUpload', {
                            loaded: progressEvent.loaded,
                            total: progressEvent.total
                        });
                    }
                }
            };

            const onFailure = function (result) {
                return reject('Product Image Upload failed: ', result);
            };

            const onSuccess = function (result) {
                return resolve(result);
            };

            // Add the ImageInput's rawFile value
            const fd = new FormData();
            fd.append('file', data.product_image_input.rawFile);
            fd.append('timestamp', new Date().getTime().toString());

            const params = {
                data: fd,
                org_id: data.org_id,
                product_id: data.id,
                show_id: data.show_id
            };
            let cfg = productResources.ProductImage.CREATE(params);

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers,
                    onUploadProgress: progressConfig.onUploadProgress
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Product Image Upload Error response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Product Image Upload Error request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Product Image Upload Error: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('File Upload try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Update a Product with a single value
 *
 * @param data
 * @param progressFunc
 * @returns {Promise<unknown>}
 */
export const postProductUpdate = (data, progressFunc = null) => {
    return new Promise(function (resolve, reject) {
        if (data && data.isProductValueUpdate) {

            const onFailure = function (result) {
                return reject('Failed to update Product: ', result);
            };

            const onSuccess = function (result) {
                return resolve(result);
            };

            let cfg;
            const params = {
                product_id: data.updateId
            };
            if (data.updateType === 'type') {
                params.type_id = data.updateValue;
                cfg = productResources.ProductUpdateCategory.UPDATE(params);
            }
            if (data.updateType === 'client') {
                params.show_id = data.updateValue;
                cfg = productResources.ProductUpdateTitle.UPDATE(params);
            }
            if (data.updateType === 'show') {
                params.show_id = data.updateValue;
                cfg = productResources.ProductUpdateTitle.UPDATE(params);
            }
            if (data.updateType === 'active') {
                params.active = data.updateValue;
                cfg = productResources.ProductUpdateActive.UPDATE(params);
            }

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers
                })
                    .then((result) => {
                        if (result.data && result.data.message && result.data.message.indexOf('updated') !== -1) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Failed to update Product: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Failed to update Product: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Failed to update Product: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Update Product try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Update an Order with a single value
 *
 * @param data
 * @param progressFunc
 * @returns {Promise<unknown>}
 */
export const postOrderUpdate = (data, progressFunc = null) => {
    return new Promise(function (resolve, reject) {
        if (data && data.isOrderValueUpdate) {

            const onFailure = function (result) {
                return reject('Failed to update Order: ', result);
            };

            const onSuccess = function (result) {
                return resolve(result);
            };

            let cfg;
            const params = {
                id: data.updateId
            };
            if (data.updateType === 'billed') {
                params.billed = data.updateValue;
                cfg = orderResources.OrderUpdateBilled.UPDATE(params);
            }

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers
                })
                    .then((result) => {
                        if (result.data && result.data.id) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Failed to update Order: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Failed to update Order: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Failed to update Order: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Update Order try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Post a Product and quantity in a Warehouse Location
 *
 * @param data
 * @param progressFunc
 * @returns {Promise<unknown>}
 */
export const postProductWarehouseLocation = (data, progressFunc = null) => {
    return new Promise(function (resolve, reject) {
        if (data && data.location) {

            // Lift loading progress if requested
            const progressConfig = {
                onUploadProgress: function (progressEvent) {
                    if (typeof progressFunc === 'function') {
                        progressFunc('fileUpload', {
                            loaded: progressEvent.loaded,
                            total: progressEvent.total
                        });
                    }
                }
            };

            const onFailure = function (result) {
                return reject('Failed to save Product in Warehouse Location: ', result);
            };

            const onSuccess = function (result) {
                return resolve(result);
            };

            const params = {
                location: data.location,
                product_id: parseInt(data.product_id, 10),
                qty: parseInt(data.qty, 10)
            };

            let cfg = resources.WarehouseLocation.CREATE(params);

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers,
                    onUploadProgress: progressConfig.onUploadProgress
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Failed to save Product in Warehouse Location; response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Failed to save Product in Warehouse Location; request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Failed to save Product in Warehouse Location: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Save Product in Warehouse Location try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Post an Inventory Adjustment for a Product
 *
 * @param data
 * @param progressFunc
 * @returns {Promise<unknown>}
 */
export const postInventoryAdjustment = (data, progressFunc = null) => {
    return new Promise(function (resolve, reject) {
        if (data && data.inventory_adjustment) {

            const onFailure = function (result) {
                return reject('Failed to save Inventory Adjustment: ', result);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            const params = {
                product_id: data.id,
                qty: data.inventory_adjustment.qty,
                info: data.inventory_adjustment.info
            };

            let cfg = productResources.InventoryLedgerAdjustment.UPDATE(params);

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers,
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Failed to save Inventory Adjustment; response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Failed to save Inventory Adjustment; request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Failed to save Inventory Adjustment: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Save Inventory Adjustment try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Post a request to delete an item by ID
 *
 * @param record
 * @param resourceName
 * @param resourceFile
 * @return {Promise<unknown>}
 */
export const postDeleteItemById = (record, resourceName, resourceFile = 'default') => {
    return new Promise(function (resolve, reject) {
        if (record && record.id) {

            const onFailure = function (result) {
                return reject('Delete failed: ', result);
            };

            const onSuccess = function (result) {
                return resolve(result);
            };

            const params = {
                id: record.id
            };
            let cfg;
            if (resourceFile === 'product') {
                cfg = productResources[resourceName].DELETE(params);
            } else if (resourceFile === 'order') {
                cfg = orderResources[resourceName].DELETE(params);
            } else if (resourceFile === 'receipt') {
                cfg = receiptResources[resourceName].DELETE(params);
            } else {
                cfg = resources[resourceName].DELETE(params);
            }

            try {
                axios({
                    method: 'DELETE',
                    url: cfg.uri,
                    headers: getDeleteHeaders(true)
                }).then((response) => {
                    if (response && response.data && response.data.message) {
                        setTimeout(() => {
                            // refresh();
                            return onSuccess(response.data);
                        }, 1000);
                    }
                }).catch((error) => {
                    if (error.response) {
                        // Server response outside 2xx
                        console.log('Delete Error response: ', error);
                        onFailure(error.response);
                    } else if (error.request) {
                        // No response
                        console.log('Delete Error request: ', error);
                        onFailure(error.request);
                    } else {
                        console.log('Delete Error: ', error);
                        onFailure(error);
                    }
                });
            } catch (err) {
                console.log('Order try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }

    });
};

