import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(
    theme => ({
        button: {
            marginBottom: '1em'
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        icon: {
            fontSize: 18,
        },
    })
);

const DeletePrimaryButton = ({onClickFunc, label = 'Delete'}) => {

    const handleClick = () => {
        if (typeof onClickFunc === 'function') {
            onClickFunc();
        }
    };

    const classes = useStyles();

    return (
        <Button
            color="default"
            variant="contained"
            label={label}
            onClick={() => handleClick()}
            className={classes.button}
        >
            <DeleteIcon className={classes.leftIcon}/>
            {label}
        </Button>
    )
};

export default DeletePrimaryButton;
