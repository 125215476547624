import React from 'react';
import TextField from '@material-ui/core/TextField';

export const PrintBooleanField = ({label, value, classes}) => {
    const fieldValue = (value) ? value : '';
    return (
        <TextField label={label} defaultValue={fieldValue}
                   variant="filled" className={`input input-inline ${classes}`}/>
    );
};

export const PrintBooleanValue = ({label, value, classes}) => {
    if (label) {
        return (
            <TextField label={label} defaultValue={value}
                       variant="filled" className={`input input-inline ${classes}`}/>
        );
    }
    return (value === true) ? 'Yes' : 'No';
};

export const PrintTextField = ({label, value, classes, variant = 'filled'}) => {
    const fieldValue = (value) ? value : '';
    return (
        <TextField label={label} defaultValue={fieldValue}
                   variant={variant} className={`input input-inline ${classes}`}/>
    );
};

export const PrintLongTextField = ({label, value, classes, variant = 'filled'}) => {
    const fieldValue = (value) ? value : '';
    return (
        <>
            <div className="field-label">{label}</div>
            <div className="field-textfield has-breaks">{fieldValue}</div>
        </>

    );
};

export const PrintCurrencyField = ({label, value, classes, right = true, border = false, variant = 'filled'}) => {
    const fieldValue = (value) ? value : '0.00';
    let classStr = 'input input-inline input-120';
    if (right) {
        classStr += ' input-right';
    }
    if (border) {
        classStr += ' input-border-left';
    }
    return (
        <TextField label={label} defaultValue={fieldValue}
                   variant={variant} className={`${classStr}`}/>
    );
};

export const PrintCurrencyValue = ({value}) => {
    return (value) ? value : '0.00';
};

export const PrintDateField = ({label, value, classes, variant = 'filled'}) => {
    if (!value || value === '') {
        return (
            <TextField label={label} defaultValue=""
                       variant={variant} className={`input input-inline ${classes}`}/>
        );
    }
    const date = new Date(value);
    const str = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    return (
        <TextField label={label} defaultValue={str.toString()}
                   variant={variant} className={`input input-inline ${classes}`}/>
    );
};

export const PrintTimeField = ({label, value, classes, right = true, border = false, variant = 'filled'}) => {
    const fieldValue = (value) ? value : '00:00';
    let classStr = 'input input-inline ' + classes;
    if (right) {
        classStr += ' input-right';
    }
    if (border) {
        classStr += ' input-border-left';
    }
    return (
        <TextField label={label} defaultValue={fieldValue}
                   variant={variant} className={`${classStr}`}/>
    );
};

export const PrintTimeValue = ({value}) => {
    return (value) ? value : '00:00';
};
