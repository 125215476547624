import axios from 'axios';
import {getPostHeaders} from './headers';
import {RESOURCES as resources} from '../orderResources';

/**
 * Post a request to Start, Create or Update an Order
 *
 * @param type
 * @param data
 * @return {Promise<unknown>}
 */
export const postOrder = (type, data) => {
    return new Promise(function (resolve, reject) {
        if (data) {

            const onFailure = function (result) {
                let message = result.data?.error ?? result;
                return reject(message);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            const params = {
                data: data
            };
            let cfg;
            switch (type) {
                case 'startOrder':
                    cfg = resources.OrderStart.CREATE(params);
                    break;
                case 'createOrder':
                    cfg = resources.OrderCreate.CREATE(params);
                    break;
                case 'updateOrder':
                    cfg = resources.Order.UPDATE(params);
                    break;
                default:
                    break;
            }

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            onFailure(error.response);
                        } else if (error.request) {
                            onFailure(error.request);
                        } else {
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('postOrder try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Post a request to Add or Update a Product in an Order
 *
 * @param type
 * @param data
 * @return {Promise<unknown>}
 */
export const postItem = (type, data) => {
    return new Promise(function (resolve, reject) {
        if (data) {

            const onFailure = function (result) {
                let message = result.data?.error ?? result;
                return reject(message);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            let productToPost = {
                order_id: data.order_id,
                product_id: data.product_id,
                qty: data.qty,
                notes: data.notes
            };
            if (type === 'updateItem') {
                productToPost.id = data.id;
                productToPost.new_qty = data.new_qty;
            }
            if (type === 'updateNotes') {
                productToPost.id = data.id;
            }
            if (type === 'deleteItem') {
                productToPost.id = data.id;
            }
            const params = {
                data: productToPost
            };
            let cfg;
            switch (type) {
                case 'addItem':
                    cfg = resources.OrderItem.CREATE(params);
                    break;
                case 'updateItem':
                    cfg = resources.OrderItem.UPDATE(params);
                    break;
                case 'updateNotes':
                    cfg = resources.OrderItemNotes.UPDATE(params);
                    break;
                case 'deleteItem':
                    cfg = resources.OrderItem.DELETE(params);
                    break;
                default:
                    break;
            }

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            onFailure(error.request);
                        } else {
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('postItem try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};
