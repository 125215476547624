import React, {useState} from 'react';
import {
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import DeleteDialog from './DeleteDialog';
import {postDeleteItemById} from '../utils/apiFunctions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

/**
 * A Delete Button with Dialog display
 *
 * @param record
 * @param resourceName
 * @param resourceParam
 * @param resourceFile
 * @param type
 * @param label
 * @param className
 * @param disabled
 * @return {JSX.Element}
 * @constructor
 */
const DeleteWithDialog = ({record, resourceName, resourceParam = 'id', resourceFile = 'default', type = 'button', label = '', className = '', disabled = false}) => {

    const [openDialog, setOpenDialog] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const handleDeleteClick = () => {
        // Open the dialog for this item
        setOpenDialog(true);
    };

    const postDelete = () => {
        // Post a request to delete this item
        postDeleteItemById(record, resourceName, resourceFile)
            .then(response => {
                const message = (label !== '') ? label + ' deleted' : 'Item deleted';
                notify(message);
                setOpenDialog(false);
                setTimeout(() => {
                    if (type === 'list') {
                        refresh();
                    }
                    if (type === 'button') {
                        redirect('/' + resourceName);
                    }
                }, 1000);
            })
            .catch(error => {
                notify('Sorry, there was an error deleting this item');
                setTimeout(() => {
                    setOpenDialog(false);
                }, 1000);
            });
    };

    return (
        <>
            {type === 'icon' &&
            <IconButton
                color="secondary"
                arial-label="Delete"
                onClick={() => handleDeleteClick()}
                className={className}
                disabled={disabled}
            >
                <HighlightOffIcon/>
            </IconButton>
            }
            {type === 'button' &&
            <Button
                color="secondary"
                startIcon={<HighlightOffIcon/>}
                onClick={() => handleDeleteClick()}
                className={className}
                disabled={disabled}
            >
                Delete{(label) ? ' ' + label : ''}
            </Button>
            }
            <DeleteDialog
                resourceId={record[resourceParam]}
                resourceName={label}
                openDialog={openDialog}
                onCloseFunc={() => setOpenDialog(false)}
                onDeleteFunc={() => postDelete()}
            />
        </>
    );
};

export default DeleteWithDialog;
