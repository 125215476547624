export const ORDER_QUERY_DEFAULTS = {
    filter_list_view: 'browse',
    filter_by: 'status',
    filter_status_id: 1,
    page: 1,
    per_page: 50,
    sort_by: 'created_on_date',
    sort_order: 'DESC'
};

export const RECEIPT_QUERY_DEFAULTS = {
    filter_list_view: 'browse',
    filter_by: 'date_range',
    page: 1,
    per_page: 50,
    sort_by: 'created_on_date',
    sort_order: 'DESC'
};

export const ORDER_RECEIPT_TABLE_FILTERS = [
    {key: 's_approved_by', clearOnFilterBy: ['id', 'sku']},
    {key: 's_bill_ref_desc', clearOnFilterBy: ['bill_ref', 'id','sku']},
    {key: 's_billed_by_name', clearOnFilterBy: ['id', 'sku']},
    {key: 's_created_by', clearOnFilterBy: ['id', 'sku']},
    {key: 's_id', clearOnFilterBy: ['sku']},
    {key: 's_order_status', clearOnFilterBy: ['id', 'status', 'sku']},
    {key: 's_org_name', clearOnFilterBy: ['client', 'contact', 'id', 'sku']},
    {key: 's_shipped_with_name', clearOnFilterBy: ['id', 'sku']},
    {key: 's_shipping_type_name', clearOnFilterBy: ['id', 'sku']},
    {key: 's_tracking_number', clearOnFilterBy: ['id', 'sku']},
    {key: 's_ship_to_name', clearOnFilterBy: ['contact', 'id', 'sku']}
];

export const ORDER_BROWSE_EXPORT_COLUMNS = [
    {key: 'created_on_string', label: 'Created On'},
    {key: 'required_by_string', label: 'Required By'},
    {key: 'org_name', label: 'Client'},
    {key: 'bill_ref_desc', label: 'Billing Reference'},
    {key: 'id', label: 'Order ID'},
    {key: 'order_status', label: 'Status'},
    {key: 'shipped_with_name', label: 'Shipped With'},
    {key: 'shipping_type_name', label: 'Shipping Method'},
    {key: 'tracking_number', label: 'Tracking Number'},
    {key: 'ship_to.name', label: 'Destination'},
    {key: 'created_by', label: 'Created By'},
    {key: 'notes', label: 'Notes'}
];

export const ORDER_BILLING_EXPORT_COLUMNS = [
    {key: 'created_on_string', label: 'Created On'},
    {key: 'org_name', label: 'Client'},
    {key: 'bill_ref_desc', label: 'Billing Reference'},
    {key: 'id', label: 'Order ID'},
    {key: 'order_status', label: 'Status'},
    {key: 'billed', label: 'Billed'},
    {key: 'billed_by_name', label: 'Billed By'},
    {key: 'labor_time', label: 'Labor Time'},
    {key: 'labor_cost_str', label: 'Labor Cost'},
    {key: 'material_cost_str', label: 'Material Cost'},
    {key: 'courier_cost_str', label: 'Courier Cost'},
    {key: 'shipping_cost_str', label: 'Shipping Cost'},
    {key: 'total_cost', label: 'Total Cost'},
    {key: 'created_by', label: 'Created By'},
];

export const RECEIPT_BROWSE_EXPORT_COLUMNS = [
    {key: 'id', label: 'ID'},
    {key: 'created_on_date', label: 'Created On'},
    {key: 'org_name', label: 'Client'},
    {key: 'tracking_number', label: 'Tracking #'},
    {key: 'approved', label: 'Approved'},
    {key: 'receiving_cost_str', label: 'Receiving Cost'},
    {key: 'labor_cost_str', label: 'Labor Cost'},
    {key: 'material_cost_str', label: 'Material Cost'},
    {key: 'courier_cost_str', label: 'Courier Cost'},
    {key: 'total_cost', label: 'Total Cost'},
    {key: 'created_by', label: 'Created By'},
    {key: 'notes', label: 'Notes'}
];

export const RECEIPT_BILLING_EXPORT_COLUMNS = [
    {key: 'id', label: 'ID'},
    {key: 'created_on_date', label: 'Created On'},
    {key: 'org_name', label: 'Client'},
    {key: 'tracking_number', label: 'Tracking #'},
    {key: 'approved', label: 'Approved'},
    {key: 'billed', label: 'Billed'},
    {key: 'billed_by_name', label: 'Billed By'},
    {key: 'receiving_cost_str', label: 'Receiving Cost'},
    {key: 'labor_cost_str', label: 'Labor Cost'},
    {key: 'material_cost_str', label: 'Material Cost'},
    {key: 'courier_cost_str', label: 'Courier Cost'},
    {key: 'total_cost', label: 'Total Cost'},
    {key: 'created_by', label: 'Created By'},
    {key: 'notes', label: 'Notes'}
];
