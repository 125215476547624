import React, {useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../resources';
import {getGetHeaders} from '../utils/headers';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from './FieldDivider';
import LocationsList from './LocationsList';
import SelectFromListInput from './SelectFromListInput';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    btnCheck: {
        display: 'inline-flex',
        height: 48,
        margin: '0 1em 0 0',
        padding: '4px 12px 4px 12px'
    },
    icon: {
        display: 'inline-block',
        fontSize: 20,
        margin: '0 4px 0 0',
        verticalAlign: 'middle'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
    },
    messageContainer: {
        display: 'block',
        margin: '5px 0 20px 8px'
    },
    messageError: {
        display: 'inline-block',
        fontSize: 14,
        color: '#b4212d',
        verticalAlign: 'middle'
    },
    messageValid: {
        display: 'inline-block',
        fontSize: 14,
        color: '#508242',
        verticalAlign: 'middle'
    }
}));

/**
 * A component to validate Warehouse Locations and add current Product
 *
 * @param formData
 * @param otherLocations
 * @param onAdd
 * @returns {JSX.Element}
 * @constructor
 */
const ProductLocationAddInput = ({formData, otherLocations, onAdd}) => {

    const [addLocationState, setAddLocationState] = useState('');
    const [addLocationIdState, setAddLocationIdState] = useState('');
    const [availableState, setAvailableState] = useState({
        available: false,
        message: ''
    });
    const [locationsState, setLocationsState] = useState([]);

    const resetAddValues = () => {
        setAddLocationState('');
        setAddLocationIdState('');
        setAvailableState({available: false, message: ''});
    };

    const handleLocationSelection = (e) => {
        setAddLocationState(e);
        setLocationsState([]);

        let available = true;
        let message = 'Warehouse Location ' + e + ' is available to add this Product.';
        const cfg = resources.WarehouseLocationByLocation.GET_ONE({location: e});
        axios({
            method: 'GET',
            url: cfg.uri,
            headers: getGetHeaders()
        }).then((response) => {
            if (response && !response.data) {
                // Location has no data and does not exist
                available = false;
                message = 'This Location does not exist. Please enter a different Location or create a new Location.';
            }
            if (response?.data?.length > 0) {
                // Location has Products within it; update table
                const data = response.data;
                if (data && data.length > 0) {
                    let locations = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].product_id === formData.id) {
                            // This Location already contains this Product, so is not available to add
                            available = false;
                            message = 'This Location already contains or used to contain this Product. You can add or remove the Product above.';
                        }
                        locations.push(data[i]);
                        setAddLocationIdState(data[i].id);
                    }
                    setLocationsState(locations);
                }
            }
        }).catch((error) => {
            message = 'Sorry, there was an error accessing this Location. Please select a different Location.';
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
        }).finally(() => {
            // Set messaging based on availability
            setAvailableState({available: available, message: message});
        });
    };

    const handleAddLocation = () => {
        if (typeof onAdd === 'function') {
            onAdd({location: addLocationState, qty: 1, id: addLocationIdState});
            setTimeout(() => {
                resetAddValues();
            }, 600);
        }
    };

    const disableAdd = (formData.qty === 0);
    const classes = useStyles();

    return (
        <>
            <div>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text="Add this Product to a Warehouse Location"
                />
                <FieldDivider type="break"/>
                {!disableAdd &&
                    <FieldDescription
                        description={true} marginBottom={false}
                        text={`To add this Product to a Location, enter a Warehouse Location. The Location will be 
                        checked to determine if this Product may be added to it.`}
                    />
                }
                <FieldDivider type="break" margin="bottom"/>

                <div className={classes.inputContainer}>
                    <SelectFromListInput
                        inAdminForm={true}
                        source="add_custom_location"
                        resourceName="WarehouseLocation"
                        listLabel="Find a Location"
                        itemPlural="Locations"
                        returnType="string"
                        listFilters={{
                            active: true,
                            params: {
                                filter: null
                            }
                        }}
                        isRequired={false}
                        alwaysOn
                        size="small"
                        hasClear={true}
                        onChangeFunc={(e) => handleLocationSelection(e)}
                        disabled={disableAdd}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={(!availableState.available || !addLocationState || addLocationState === '')}
                        onClick={() => handleAddLocation()}
                        className={classes.btnCheck}
                    >
                        Add to {(!addLocationState || addLocationState === '') ? 'Location' : addLocationState}
                    </Button>
                </div>
            </div>

            <div className={classes.messageContainer}>
                {availableState.message !== '' &&
                    <>
                        {!availableState.available &&
                            <>
                                <HighlightOffIcon className={classes.icon}/>
                                <span className={classes.messageError}>{availableState.message}</span>
                            </>
                        }
                        {availableState.available &&
                            <>
                                <CheckCircleOutlinedIcon className={classes.icon}/>
                                <span className={classes.messageValid}>{availableState.message}</span>
                            </>
                        }
                    </>
                }
            </div>

            <LocationsList itemList={locationsState} hasEdit={false}/>
        </>
    )
}

export default ProductLocationAddInput;
