import React, {useEffect, useState} from 'react';
import ListLoading from './ListLoading';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useDialogStyles = makeStyles({
    actions: {
        textAlign: 'right'
    },
    root: {
        margin: 0,
        padding: 12,
    },
    btnClose: {
        position: 'absolute',
        right: 10,
        top: 8,
        color: '#666666',
    },
    btnDialog: {
        margin: '0 0.5em 0 0.5em',
        verticalAlign: 'middle'
    }
});

/**
 * A Material UI Dialog to display deletion options for Orders and Receipts
 *
 * @param item
 * @param openDialog
 * @param onCloseFunc
 * @param onDeleteFunc
 * @param type
 * @return {JSX.Element}
 * @constructor
 */
const OrderReceiptItemDeleteDialog = (
    {
        item,
        openDialog,
        onCloseFunc,
        onDeleteFunc,
        type = 'Order'
    }) => {

    const [open, setOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(
        () => {
            const resetDialog = () => {
                setDeleting(false);
                setOpen(openDialog);
            };

            return resetDialog();

        }, [openDialog]
    );

    const handleClose = () => {
        setDeleting(false);
        setOpen(false);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const handleDelete = () => {
        setDeleting(true);
        if (typeof onDeleteFunc === 'function') {
            onDeleteFunc();
        }
    };

    const classes = useDialogStyles();

    if (!item) {
        return (
            <>
            </>
        )
    }

    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="Delete-Order-Receipt-Item-Dialog" open={open} fullWidth={true} maxWidth="sm">
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">
                        Delete {item.title} from {type}?
                    </Typography>
                    <IconButton aria-label="close" className={classes.btnClose} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers className={classes.actions}>

                    {!deleting &&
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClose()}
                            className={classes.btnDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<HighlightOffIcon/>}
                            onClick={() => handleDelete()}
                            className={classes.btnDialog}
                        >
                            Delete
                        </Button>
                    </>
                    }

                    {deleting &&
                    <>
                        <ListLoading text="Deleting..."/>
                    </>
                    }
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default OrderReceiptItemDeleteDialog;
