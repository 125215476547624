import React from "react";
import {ListButton, TopToolbar} from "react-admin";

/**
 * Custom Create Actions Toolbar
 *
 * @param basePath
 * @param className
 * @param hasList
 * @returns {JSX.Element}
 * @constructor
 */
const CreateActions = ({
                           basePath,
                           className,
                           hasList
                       }) => {
    return (
        <>
            <TopToolbar className={className}>
                {hasList && <ListButton basePath={basePath}/>}
            </TopToolbar>
        </>
    )
};

export default CreateActions;
