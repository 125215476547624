import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import {NumberInput} from 'react-admin';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import GetProductsByShowSelectInput from './GetProductsByShowSelectInput';
import SelectFromListInput from './SelectFromListInput';

/**
 * A component for selecting an available Product for a new Warehouse Location
 *
 * @param formData
 * @return {JSX.Element}
 * @constructor
 */
const LocationProductAdd = ({formData}) => {

    const [product, setProduct] = useState(null);
    const [hasProductData, setHasProductData] = useState(false);

    useEffect(() => {
        const updateProduct = (data) => {
            setHasProductData(true);
        };

        const fetchProduct = () => {
            let cfg = resources.Product.GET_ONE({id: product.id});
            axios({
                method: 'GET',
                url: cfg.uri,
                headers: getGetHeaders(),
            }).then((response) => {
                updateProduct(response?.data ?? null);
            }).catch((error) => {
                updateProduct(null);
            });
        };

        if (product) {
            return fetchProduct();
        }
    }, [product]);

    const resetProduct = () => {
        setProduct(null);
        setHasProductData(false);
    };

    const handleSelectProduct = (e) => {
        resetProduct();
        if (e?.id) {
            setProduct(e);
        }
    };

    return (
        <>
            <div className="location-product-add">
                <SelectFromListInput
                    inAdminForm={true}
                    source="org_id"
                    resourceName="Organization"
                    listLabel="Select a Client"
                    itemPlural="Clients"
                    returnType="string"
                    listFilters={{
                        active: true
                    }}
                    isRequired={false}
                    size="small"
                    hasClear={true}
                    disabled={(!formData.location)}
                    onChangeFunc={() => resetProduct()}
                    className="input-inline input-360"
                />
                <SelectFromListInput
                    inAdminForm={true}
                    source="show_id"
                    resourceName="ShowByOrg"
                    listLabel="Select a Title"
                    itemPlural="Titles"
                    returnType="string"
                    listFilters={{
                        active: true,
                        org_id: formData.org_id
                    }}
                    isRequired={false}
                    size="small"
                    hasClear={true}
                    disabled={(!formData.org_id)}
                    onChangeFunc={() => resetProduct()}
                    className="input-inline input-360"
                />
                <FieldDivider type="break" margin="bottom"/>

                <GetProductsByShowSelectInput
                    source="product_id"
                    label="Select a Product"
                    displayType="title"
                    displayQty={true}
                    includeZeroQuantity={false}
                    isRequired={true}
                    resourceActive={true}
                    requiresFilter={true}
                    returnValueType="object"
                    filter={(formData.org_id && formData.show_id) ? {active: true, id: formData.show_id} : null}
                    resourceId={(formData.show_id) ? formData.show_id : null}
                    className="input-inline input-full"
                    emptyText="No Products with quantity available."
                    addLabel={false}
                    disabled={(!formData.org_id || !formData.show_id || !formData.location)}
                    allowEmpty
                    onChangeFunc={(e) => handleSelectProduct(e)}
                />
                <FieldDivider type="break"/>

                {product && !hasProductData &&
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text="Checking Product quantity in all Locations..."
                    />
                }
                {product && hasProductData && product.qty > 0 &&
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text={`Selected Product "${product.title}" will be added to this Location.`}
                    />
                }
                {product && hasProductData && product.qty < 1 &&
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text={`Selected Product "${product.title}" has 0 quantity. Please select another Product.`}
                    />
                }

                <NumberInput
                    label=""
                    source="qty"
                    className="input-hidden"
                />
                <FieldDivider type="break" margin="bottom"/>
            </div>
        </>
    );
}

export default LocationProductAdd;
