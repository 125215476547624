import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {setListSelectedIds} from 'react-admin';
import Emitter from './eventEmitter';

/**
 * Component for receiving and updating React-Admin List events
 */
const ListEventHandler = (props) => {

    // Effect: Detect selection of List rows
    useEffect(() => {
        const updateSelectedIds = (e) => {
            if (e.id && e.resource) {
                const selectedIds = JSON.parse(JSON.stringify(e.selectedIds));
                let doUpdate = false;
                const idx = selectedIds.indexOf(e.id);

                // If checked and not in the list, add the ID
                if (e.checked && idx === -1) {
                    doUpdate = true;
                    selectedIds.push(e.id);
                }
                // If unchecked and in the list, remove the ID
                if (e.checked === false && idx !== -1) {
                    doUpdate = true;
                    selectedIds.splice(idx, 1);
                }

                if (doUpdate) {
                    // Update Redux
                    props.setListSelectedIds(e.resource, selectedIds);
                }
            }
        };

        // Add a listener to update Resource
        Emitter.on('updateListSelectedIds', (e) => updateSelectedIds(e));

        return () => Emitter.off('updateListSelectedIds', (e) => updateSelectedIds(e));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        </>
    )
};

const mapDispatchToProps = {
    setListSelectedIds: setListSelectedIds
};

export default connect(
    null,
    mapDispatchToProps
)(ListEventHandler);
