import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ProductDetailDialog from '../components/ProductDetailDialog';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles({
    btn: {
        marginTop: -4,
        marginRight: 4,
        padding: 2
    },
    btnInfo: {
        position: 'absolute',
        top: 2,
        right: 4,
        padding: 2
    },
    btnLedgerActive: {
        position: 'absolute',
        top: 2,
        left: 'auto',
        padding: 2,
        color: '#66bb6a'
    },
    btnLedgerInactive: {
        position: 'absolute',
        top: 2,
        left: 'auto',
        padding: 2
    },
    btnText: {
        marginRight: 6,
        padding: '0px 10px'
    },
    message: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    messageError: {
        fontSize: 16,
        color: '#b4212d'
    },
    saving: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    th: {
        lineHeight: '1.2',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '5px 12px 7px 12px'
    },
    tdLight: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    tdName: {
        position: 'relative',
        padding: '5px 36px 5px 12px'
    },
    tdNameLight: {
        position: 'relative',
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '5px 36px 5px 16px'
    }
});

/**
 * A component to display a list of Products in a Warehouse Location with optional Ledger Table
 *
 * @param location
 * @param onEditQtyFunc
 * @param itemList
 * @param hasEditQty
 * @param saving
 * @param error
 * @return {JSX.Element}
 * @constructor
 */
const LocationProductsList = (
    {
        location,
        onEditQtyFunc,
        itemList = [],
        hasEditQty = false,
        saving = false,
        error = null
    }) => {

    const [currentItem, setCurrentItem] = useState(null);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [qty, setQty] = useState(-1);
    const [newQty, setNewQty] = useState(-1);
    const [saveRequest, setSaveRequest] = useState(false);
    const [saveMessage, setSaveMessage] = useState({
        error: false,
        message: ''
    });

    // State: Product Detail Item with Dialog
    const [itemDetailState, setItemDetailState] = useState({
        dialog: false,
        item: null
    });

    useEffect(
        () => {
            // Reset items after an item has been updated
            if (currentItem && saveRequest && !saving) {
                handleEditQtyClose();
                setSaveMessage({error: false, message: (error) ? error : 'Item updated.'})
            }
        }, [currentItem, saveRequest, saving, error]
    );

    // All Views: Handle event from Info button
    const handleInfoClick = (item) => {
        setItemDetailState({dialog: !!(item), item: item});
    };

    const handleCloseItemDialog = () => {
        setItemDetailState({dialog: false, item: null});
    };

    // Edit View: Handle event from Edit Qty button
    const handleEditQtyClick = (id) => {
        let idx = itemList.findIndex(r => r.product_id === id);
        if (idx !== -1) {
            const qty = itemList[idx].qty;
            // Display the New Qty input
            setQty(qty);
            setNewQty(qty);
            setCurrentItem(itemList[idx]);
            setCurrentItemId(id);
            setSaveMessage({error: false, message: ''});
        }
    };

    // Edit View: Handle event from change in New Qty field
    const handleEditQtyChange = (e, item) => {
        const value = (e.target && e.target.value) ? e.target.value : null;
        if (value) {
            let qty = parseInt(value, 10);
            if (qty > item.available_qty) {
                qty = item.available_qty;
            }
            if (qty < 0) {
                qty = 0;
            }
            setNewQty(qty);
        }
    };

    // Edit View: Handle event from Qty Save button
    const handleSaveEditQty = (id) => {
        if (typeof onEditQtyFunc === 'function') {
            setSaveRequest(true);
            onEditQtyFunc({item: currentItem, new_qty: newQty});
        }
    };

    // Edit View: Handle event from Edit Qty Cancel button
    const handleEditQtyClose = () => {
        setQty(-1);
        setNewQty(-1);
        setCurrentItem(null);
        setCurrentItemId(null);
        setSaveRequest(false);
    };

    // Generate Product Item list rows
    const setRows = () => {
        const list = itemList;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Products in Location</span>
            )
        }

        return (
            itemList.map((item, index) => (
                <TableRow key={`location-product-item-${item.product_id}-${index}`}>

                    <TableCell className={(item.qty < 1) ? classes.tdLight : classes.td}>
                        {item.product_id}
                    </TableCell>

                    <TableCell className={classes.td}>
                        <Link to={`/Product/${item.product_id}`} title={`Product ID ${item.product_id}`}
                              className={classes.tdLight}>
                            <EditIcon/>
                        </Link>
                    </TableCell>

                    <TableCell className={(item.qty < 1) ? classes.tdNameLight : classes.tdName}>
                        {item.product_title} &nbsp;
                        <IconButton
                            aria-label="Info"
                            className={classes.btnInfo}
                            onClick={() => handleInfoClick(item)}
                        >
                            <InfoIcon/>
                        </IconButton>
                    </TableCell>

                    {hasEditQty &&
                        <TableCell align="right" className={(item.qty < 1) ? classes.tdLight : classes.td}>
                            {item.available_qty}
                        </TableCell>
                    }

                    <TableCell align="right" className={(item.qty < 1) ? classes.tdLight : classes.td}>
                        {(item.qty < 1) ? 'Empty' : 'Yes'}
                    </TableCell>

                    {hasEditQty &&
                        <TableCell className={classes.td}>
                            {(!currentItemId || currentItemId !== item.product_id) &&
                                <IconButton
                                    aria-label="Edit Qty"
                                    className={classes.btn}
                                    onClick={() => handleEditQtyClick(item.product_id)}
                                    disabled={!!(currentItemId && currentItemId !== item.product_id)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            }
                            {currentItemId && currentItemId === item.product_id &&
                                <>
                                    {!saving &&
                                        <>
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                id={`edit-product-item-qty-${index}`}
                                                label=""
                                                value={newQty}
                                                className="input-table-small number"
                                                onChange={(e) => handleEditQtyChange(e, item)}
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0,
                                                        step: 1
                                                    }
                                                }}
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.btnText}
                                                startIcon={<SaveIcon/>}
                                                onClick={() => handleSaveEditQty(item.product_id)}
                                                disabled={(qty === newQty)}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                className={classes.btnText}
                                                startIcon={<CloseIcon/>}
                                                onClick={() => handleEditQtyClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    }
                                    {saving &&
                                        <span className={classes.saving}>Saving...</span>
                                    }
                                </>
                            }
                        </TableCell>
                    }
                </TableRow>
            ))
        )
    };

    const classes = useStyles();

    return (
        <>
            {itemList.length > 0 &&
                <div className="location-product-item-list-container">
                    {/* Product Table */}
                    <Table size="small" stickyHeader aria-label="Added Items">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.th}>ID</TableCell>
                                <TableCell className={classes.th}>Edit<br/>Product</TableCell>
                                <TableCell className={classes.th}>Product</TableCell>
                                {hasEditQty &&
                                    <TableCell align="right" className={classes.th}>Available<br/>Qty</TableCell>
                                }
                                <TableCell align="right" className={classes.th}>Stored in<br/>Location</TableCell>
                                {hasEditQty &&
                                    <TableCell className={classes.th}>Edit Qty in<br/>Location</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...setRows()]}

                            {saveMessage.message !== '' &&
                                <TableRow key="location-product-item-message">
                                    <TableCell colSpan={5} align="right">
                            <span className={(saveMessage.error) ? classes.messageError : classes.message}>
                                {saveMessage.message}
                            </span>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </div>
            }
            <div className="location-product-item-ledger-container">
                {/* Product Detail Dialog */}
                <ProductDetailDialog
                    openDialog={itemDetailState.dialog}
                    productId={(itemDetailState.item) ? itemDetailState.item.product_id : null}
                    onCloseFunc={handleCloseItemDialog}
                />
            </div>
        </>
    )
}

export default LocationProductsList;
