import React from 'react';
import {Admin, Resource} from 'react-admin';
import {Provider} from 'react-redux';
import axios from 'axios';
import {history} from './utils/history';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import Emitter from './utils/eventEmitter';
import createAdminStore from './store/store';
import {processKeycloakTokens} from './utils/tokenFunctions';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import customRoutes from './routes';
import englishMessages from './language/en';
import KeycloakHandler from './utils/KeycloakHandler';
import ParrotLayout from './components/Layout';

import {
    BillingReferenceList,
    BillingReferenceShow,
    BillingReferenceCreate,
    BillingReferenceEdit,
    BillingReferenceIcon
} from './routes/BillingReference';
import {
    ClientUserList,
    ClientUserShow,
    ClientUserCreate,
    ClientUserEdit,
    ClientUserIcon
} from './routes/ClientUser';
import {
    ContactList,
    ContactShow,
    ContactCreate,
    ContactEdit,
    ContactIcon
} from './routes/Contact';
import {
    EmployeeList,
    EmployeeShow,
    EmployeeCreate,
    EmployeeEdit,
    EmployeeIcon
} from './routes/Employee';
import {
    OrderList,
    OrderShow,
    OrderEdit,
    OrderIcon
} from './routes/Order';
import {
    OrganizationList,
    OrganizationShow,
    OrganizationCreate,
    OrganizationEdit,
    OrganizationIcon
} from './routes/Organization';
import {
    ProductList,
    ProductShow,
    ProductEdit,
    ProductIcon
} from './routes/Product';
import {ProductCreate} from './routes/ProductCreate';
import {
    ProductTypeList,
    ProductTypeShow,
    ProductTypeCreate,
    ProductTypeEdit,
    ProductTypeIcon
} from './routes/ProductType';
import {
    ReceiptList,
    ReceiptShow,
    ReceiptEdit,
    ReceiptIcon
} from './routes/Receipt';
import {
    ShippingCompanyList,
    ShippingCompanyShow,
    ShippingCompanyCreate,
    ShippingCompanyEdit,
    ShippingCompanyIcon
} from './routes/ShippingCompany';
import {
    ShippingMethodList,
    ShippingMethodShow,
    ShippingMethodCreate,
    ShippingMethodEdit,
    ShippingMethodIcon
} from './routes/ShippingMethod';
import {
    ShowList,
    ShowShow,
    ShowCreate,
    ShowEdit,
    ShowIcon
} from './routes/Show';
import {
    WarehouseLocationList,
    WarehouseLocationShow,
    WarehouseLocationCreate,
    WarehouseLocationIcon
} from './routes/WarehouseLocation';
import Dashboard from './routes/Dashboard';
import LoginPage from './routes/Login';
import NotFound from './routes/NotFound';

import PTheme from './global/theme';
import {createTheme} from '@material-ui/core/styles';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {allowMissing: true});
const theme = createTheme(PTheme);

/**
 * Initialize Keycloak
 *
 * @param keycloak
 * @param config
 * @returns {Promise<void>}
 */
const initKeycloak = async (keycloak, config) => {
    try {
        await keycloak.init(config).then(function (result) {
            if (keycloak.didInitialize) {
                Emitter.emit('keycloakReady', true);
            }
            Emitter.emit('keycloakAuthenticated', keycloak.authenticated);
            if (keycloak.authenticated === false) {
                Emitter.emit('loginKeycloak', window.origin);
            } else {
                processKeycloakTokens(keycloak.token, keycloak.idToken, keycloak.refreshToken);
            }
        }).catch(function (e) {
            console.log('Keycloak initialization error: ', e);
        });
    } catch (error) {
        console.log('Error: Authentication failed: ', error);
    }
};

/**
 * Set listeners for the Keycloak instance
 *
 * @param keycloak
 */
const setKeycloakEventHandlers = (keycloak) => {
    keycloak.onAuthLogout = () => {
        processKeycloakTokens(null, null, null);
    };
    keycloak.onAuthRefreshSuccess = () => {
        processKeycloakTokens(keycloak.token, keycloak.idToken, keycloak.refreshToken);
        // Set Authorization header for all Axios calls
        axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
    };
    keycloak.onAuthSuccess = () => {
        processKeycloakTokens(keycloak.token, keycloak.idToken, keycloak.refreshToken);
        // Set Authorization header for all Axios calls
        axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
    };
    keycloak.onReady = () => {
    };
    keycloak.onTokenExpired = () => {
        processKeycloakTokens(null, null, null);
    };
};

const App = ({
                       configParams,
                       keycloak,
                       keycloakInitConfig
                   }) => {

    const dp = Object.assign(dataProvider);
    const store = createAdminStore({authProvider, dp, history});
    initKeycloak(keycloak, keycloakInitConfig).then(() => {
        setKeycloakEventHandlers(keycloak);
    });

    return (
        <Provider
            store={store}
        >
            <>
                <Admin
                    theme={theme}
                    authProvider={authProvider}
                    configParams={configParams}
                    dataProvider={dp}
                    history={history}
                    i18nProvider={i18nProvider}
                    title="Parrot Media Admin"
                    layout={ParrotLayout}
                    dashboard={Dashboard}
                    customRoutes={customRoutes}
                    loginPage={LoginPage}
                    catchAll={NotFound}
                    disableTelemetry
                >
                    <Resource
                        name="Contact" icon={ContactIcon}
                        list={ContactList} show={ContactShow}
                        create={ContactCreate} edit={ContactEdit}
                        options={{label: 'Contacts'}}
                    />
                    <Resource
                        name="ClientUser" icon={ClientUserIcon}
                        list={ClientUserList} show={ClientUserShow}
                        create={ClientUserCreate} edit={ClientUserEdit}
                        options={{label: 'Client Users'}}
                    />
                    <Resource
                        name="Employee" icon={EmployeeIcon}
                        list={EmployeeList} show={EmployeeShow}
                        create={EmployeeCreate} edit={EmployeeEdit}
                        options={{label: 'Employees'}}
                    />
                    <Resource
                        name="BillingReference" icon={BillingReferenceIcon}
                        list={BillingReferenceList} show={BillingReferenceShow}
                        create={BillingReferenceCreate} edit={BillingReferenceEdit}
                        options={{label: 'Billing References'}}
                    />
                    <Resource
                        name="Order" icon={OrderIcon}
                        list={OrderList} show={OrderShow}
                        edit={OrderEdit}
                        options={{label: 'Orders'}}
                    />
                    <Resource
                        name="Receipt" icon={ReceiptIcon}
                        list={ReceiptList} show={ReceiptShow}
                        edit={ReceiptEdit}
                        options={{label: 'Receipt'}}
                    />
                    <Resource
                        name="Organization" icon={OrganizationIcon}
                        list={OrganizationList} show={OrganizationShow}
                        create={OrganizationCreate} edit={OrganizationEdit}
                        options={{label: 'Clients'}}
                    />
                    <Resource
                        name="ShippingCompany" icon={ShippingCompanyIcon}
                        list={ShippingCompanyList} show={ShippingCompanyShow}
                        create={ShippingCompanyCreate} edit={ShippingCompanyEdit}
                        options={{label: 'Shipping Companies'}}
                    />
                    <Resource
                        name="ShippingMethod" icon={ShippingMethodIcon}
                        list={ShippingMethodList} show={ShippingMethodShow}
                        create={ShippingMethodCreate} edit={ShippingMethodEdit}
                        options={{label: 'Shipping Method'}}
                    />
                    <Resource
                        name="Show" icon={ShowIcon}
                        list={ShowList} show={ShowShow}
                        create={ShowCreate} edit={ShowEdit}
                        options={{label: 'Titles'}}
                    />
                    <Resource
                        name="Product" icon={ProductIcon}
                        list={ProductList} show={ProductShow}
                        create={ProductCreate} edit={ProductEdit}
                        options={{label: 'Products'}}
                    />
                    <Resource
                        name="ProductType" icon={ProductTypeIcon}
                        list={ProductTypeList} show={ProductTypeShow}
                        create={ProductTypeCreate} edit={ProductTypeEdit}
                        options={{label: 'Product Categories'}}
                    />
                    <Resource
                        name="WarehouseLocation" icon={WarehouseLocationIcon}
                        list={WarehouseLocationList} show={WarehouseLocationShow}
                        create={WarehouseLocationCreate}
                        options={{label: 'Warehouse Locations'}}
                    />
                    <Resource name="InventoryLedgerAdjustment"/>
                    <Resource name="ClientUserOrg"/>
                    <Resource name="Org"/>
                    <Resource name="OrdersByStatus"/>
                    <Resource name="Products"/>
                    <Resource name="ProductsByOrg"/>
                    <Resource name="ProductsByShow"/>
                    <Resource name="ProductsByTitle"/>
                    <Resource name="ProductsByType"/>
                </Admin>
                <KeycloakHandler keycloak={keycloak}/>
            </>
        </Provider>
    );
};

export default App;
