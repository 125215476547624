import Config from '../config';

const currentTableDataReducer = (state = Config.currentTableData, action) => {
    return {
        ...state,
        currentList: (action.currentList) ? action.currentList : state.currentList
    };
};

export default currentTableDataReducer;
