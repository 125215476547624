import React, {useEffect, useState} from 'react';
import {useForm} from 'react-final-form';
import {NumberInput, TextInput} from 'react-admin';
import FieldDescription from '../components/FieldDescription';
import {makeStyles} from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';

const infoValidation = (value, allValues) => {
    if (value) {
        const valueStr = value.toLowerCase();
        if (valueStr === 'ordered' || valueStr === 'order deleted' || valueStr === 'order item updated') {
            return 'Sorry, this note is reserved for Orders.';
        }
        if (valueStr === 'received' || valueStr === 'receipt deleted' || valueStr === 'receipt item updated') {
            return 'Sorry, this note is reserved for Receipts.';
        }
    }
    if (allValues.inventory_adjustment) {
        if ((!value || value === '') && allValues.inventory_adjustment.qty !== 0) {
            return 'A reason is required for a manual adjustment.';
        }
    }
    return null;
};
const validateInfo = [infoValidation];

const useStyles = makeStyles((theme) => ({
    indicatorIcon: {
        display: 'inline-block',
        fontSize: 26,
        margin: '10px 9px 0 0'
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'flex-start',
        margin: 0,
    }
}));

/**
 * A component for storing a new Product Quantity and Note
 *
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */
const ProductInventoryAdjustmentInput = ({record}) => {

    const [qty, setQty] = useState((record.qty !== undefined && record.qty !== null) ? record.qty : -1);
    const [adjustmentQty, setAdjustmentQty] = useState((record && record.qty) ? parseInt(record.qty, 10) : 0);
    const [adjustmentDifference, setAdjustmentDifference] = useState(0);
    const [adjustmentNote, setAdjustmentNote] = useState('');
    const adjustmentForm = useForm();

    useEffect(
        () => {
            const setFormQty = () => {
                setQty(parseInt(record.qty, 10));
                setAdjustmentQty(parseInt(record.qty, 10));
            };

            if (qty === -1 && record && record.qty !== undefined && record.qty !== null) {
                return setFormQty();
            }

        }, [record, qty]
    );

    const handleChangeQty = (e) => {
        let value = (e && e.target && e.target.value) ? e.target.value : 0;
        if (!e.target.value) {
            adjustmentForm.change('adjustment_qty', adjustmentQty);
        }
        let newQty = parseInt(value, 10);
        if (newQty < 0) {
            newQty = 0;
            adjustmentForm.change('adjustment_qty', 0);
        }
        setAdjustmentQty(newQty);
        const diff = newQty - qty;
        setAdjustmentDifference(diff);
        setTimeout(function() {
            setAdjustment('qty', diff);
        }, 100);
    };

    const handleChangeInfo = (e) => {
        setAdjustmentNote(e.target.value);
        setAdjustment('info', e.target.value);
    };

    const setAdjustment = (type, value) => {
        const adjustedDiff = (type === 'qty') ? value : adjustmentDifference;
        const adjustedInfo = (type === 'info') ? value : adjustmentNote;
        // Set the qty difference and the info
        let inventory_adjustment = {
            info: adjustedInfo,
            qty: adjustedDiff
        };
        if (adjustedDiff === qty) {
            // Clear the Adjustment so it will not be saved
            adjustmentForm.change('inventory_adjustment', null);
            adjustmentForm.change('adjustment_difference', 0);
            adjustmentForm.change('adjustment_qty', qty);
            adjustmentForm.change('adjustment_note', '');
        } else {
            // Set the Adjustment so it will be saved before the Product form
            adjustmentForm.change('inventory_adjustment', inventory_adjustment);
            adjustmentForm.change('adjustment_difference', adjustedDiff);
            adjustmentForm.change('adjustment_note', adjustedInfo);
        }
    };

    const classes = useStyles();
    const label = (adjustmentDifference !== 0) ? 'Reason *' : 'Reason';
    const sign = (adjustmentDifference > 0) ? '+' : '';
    const diffStr = sign + adjustmentDifference;

    return (
        <>
            <div>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text="Adjust Product Quantity"
                />
                <FieldDescription
                    description={true} marginBottom={true}
                    text={`To manually adjust this Product's quantity, enter a new quantity and a brief note explaining the change. If the new quantity remains the same, no change to the quantity will be made.`}
                />
                <div className={classes.inputContainer}>
                    <TextInput label="Current Qty" source="qty"
                               value={qty}
                               className="input-inline input-90"
                               disabled
                    />
                    <NumberInput label="New Qty" source="adjustment_qty"
                                 min={0} max={99999} step={1}
                                 onChange={(e) => handleChangeQty(e)}
                                 className="input-inline input-90"
                    />
                    <TextInput label="Difference" source="adjustment_difference"
                               value={diffStr}
                               className="input-inline input-90 input-right"
                               disabled
                    />
                    {adjustmentDifference === 0 &&
                        <RemoveIcon color="primary" className={classes.indicatorIcon}/>
                    }
                    {adjustmentDifference < 0 &&
                        <ArrowDownwardIcon color="primary" className={classes.indicatorIcon}/>
                    }
                    {adjustmentDifference > 0 &&
                        <ArrowUpwardIcon color="primary" className={classes.indicatorIcon}/>
                    }
                    <TextInput
                        label={label}
                        source="adjustment_note"
                        onBlur={(e) => handleChangeInfo(e)}
                        onChange={(e) => handleChangeInfo(e)}
                        inputProps={{
                            placeholder: 'adjustment due to audit'
                        }}
                        validate={validateInfo}
                        className="input-inline input-360"
                    />
                </div>
            </div>
        </>
    )
}

export default ProductInventoryAdjustmentInput;
