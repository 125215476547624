import React, {useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import axios from 'axios';
import {
    TextInput
} from 'react-admin';
import {RESOURCES as resources} from '../resources';
import {getPostHeaders} from '../utils/headers';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import {useDialogStyles, useLoadingStyles} from '../global/dialogStyles';
import {viewStyles} from '../global/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

/**
 * A Material UI Dialog to display a Contact Create form
 *
 * @param openDialog
 * @param onCloseFunc
 * @param onCompleteFunc
 * @param selectedOrgId
 * @param success
 * @returns {JSX.Element}
 * @constructor
 */
const ContactCreateDialog = (
    {
        openDialog,
        onCloseFunc,
        onCompleteFunc,
        selectedOrgId,
        success
    }) => {

    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState({
        error: false,
        message: null
    });

    // Effect: Open dialog from parent
    useEffect(() => {
        return setOpen(openDialog);
    }, [openDialog]);

    const validateForm = (values) => {
        return !(
            !values.new_name
        );
    };

    const assembleFormData = (values) => {
        const v = values;
        return {
            org_id: selectedOrgId,
            name: v?.new_name ?? '',
            first_name: v?.new_first_name ?? '',
            last_name: v?.new_last_name ?? '',
            address: v?.new_address ?? '',
            address2: v?.new_address2 ?? '',
            city: v?.new_city ?? '',
            country: v?.new_country ?? '',
            state: v?.new_state ?? '',
            zip: v?.new_zip ?? '',
            email: v?.new_email ?? '',
            phone: v?.new_phone ?? '',
            notes: v?.new_notes ?? '',
            active: true
        };
    };

    const handleSubmit = (values) => {
        const formValues = assembleFormData(values);
        let errorMessage = 'Sorry, there was an error creating this Contact.';
        const resource = resources.Contact.CREATE(formValues);
        setSubmitting(true);
        axios({
            method: resource.method,
            url: resource.uri,
            data: formValues,
            headers: getPostHeaders(true)
        }).then((response) => {
            if (response.data && response.data.id) {
                setSubmitting(false);

                // Complete creation
                if (typeof onCompleteFunc === 'function') {
                    onCompleteFunc(response.data);
                }
                if (!response.data) {
                    setFormMessage({
                        error: true,
                        message: errorMessage
                    });
                } else {
                    setFormMessage({
                        error: false,
                        message: 'Contact created.'
                    });
                }
                setTimeout(() => {
                    handleClose(null);
                }, 1000);
            } else {
                setSubmitting(false);
                setFormMessage({
                    error: true,
                    message: errorMessage
                });
            }
        }).catch((error) => {
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
            setSubmitting(false);
            setFormMessage({
                error: true,
                message: errorMessage
            });
        });
    };

    const handleClose = () => {
        setOpen(false);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const classes = useDialogStyles();
    const loadingClasses = useLoadingStyles();

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={false}
                onClose={handleClose}
                aria-labelledby="Contact-Create"
                open={open}
                className={classes.root}
                transitionDuration={{
                    enter: 100,
                    exit: 150
                }}
            >
                <MuiDialogTitle disableTypography className={classes.title}>
                    <Typography variant="h6">
                        Create Contact
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <Form
                        onSubmit={handleSubmit}
                        mutators={{
                            setValue: ([field, value], state, {changeValue}) => {
                                changeValue(state, field, () => value)
                            }
                        }}
                        render={({form, handleSubmit, values}) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text='Add a Contact by entering details below.'
                                    />
                                    <TextInput label="Company Name *" source="new_name"
                                               helperText="Enter the company name. This may be a business name or the full name of a person."
                                               fullWidth={true}/>
                                    <TextInput label="First Name" source="new_first_name"
                                               formClassName="input-inline half"/>
                                    <TextInput label="Last Name" source="new_last_name" formClassName="input-inline half"/>
                                    <TextInput label="Address" source="new_address" fullWidth={true}/>
                                    <TextInput label="Address 2" source="new_address2" fullWidth={true}/>
                                    <TextInput label="City" source="new_city" formClassName="input-inline third"/>
                                    <TextInput label="State" source="new_state" formClassName="input-inline third"/>
                                    <TextInput label="ZIP/Postal Code" source="new_zip" formClassName="input-inline third"/>
                                    <TextInput label="Country" source="new_country" formClassName="input-inline half"/>
                                    <FieldDivider type="break"/>
                                    <TextInput label="Email Address" source="new_email" formClassName="input-inline half"/>
                                    <TextInput label="Phone" source="new_phone" formClassName="input-inline half"/>
                                    <TextInput label="Notes" source="new_notes" multiline fullWidth={true}/>
                                    <FieldDivider type="break" margin="bottom"/>
                                    <FieldDescription
                                        marginBottom={true}
                                        description={true}
                                        text="After clicking Create Contact, this reference should be available to select."
                                    />
                                    <hr style={viewStyles.break}/>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        style={viewStyles.buttonInline}
                                        disabled={(validateForm(values) === false || submitting || success)}
                                        onClick={() => handleSubmit()}
                                    >
                                        Create Contact
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={viewStyles.buttonInline}
                                        disabled={(submitting || success)}
                                        onClick={() => handleClose()}
                                    >
                                        Cancel
                                    </Button>
                                    {submitting &&
                                        <>
                                            <CircularProgress size={30} className={loadingClasses.root}/>
                                            <p className={loadingClasses.text}>Creating Contact...</p>
                                        </>
                                    }
                                    {formMessage.message &&
                                        <p className={(formMessage.error) ? loadingClasses.textError : loadingClasses.text}>
                                            {formMessage.message}
                                        </p>
                                    }
                                </form>
                            )
                        }}
                    >
                    </Form>
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default ContactCreateDialog;
