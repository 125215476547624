import React, {useCallback, useEffect, useState} from 'react';
import {postItem, refreshLocationProducts} from '../utils/apiProductFunctions';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import LocationAvailableProductsContainer from './LocationAvailableProductsContainer';
import LocationProductsList from './LocationProductsList';

/**
 * A component for displaying a Product List by Location
 *
 * @param view
 * @param productData
 * @param hasEditQty
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const LocationProductListContainer = (
    {
        view,
        productData,
        hasEditQty = true,
        ...props
    }) => {

    const [itemList, setItemList] = useState({
        products: productData?.products ?? []
    });
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [dataComplete, setDataComplete] = useState(false);
    const [updateComplete, setUpdateComplete] = useState(true);

    const location = productData?.location ?? null;

    const refreshList = useCallback(() => {
        setSaving(true);
        refreshLocationProducts(location)
            .then(result => {
                if (result && result.length) {
                    // Display message and reset item list
                    setItemList({
                        products: result
                    });
                    setSaving(false);
                    setSaveError(null);
                    setUpdateComplete(true);
                }
            })
            .catch(error => {
                // Display message
                setSaving(false);
                setSaveError('Sorry, there was an error updating this Product List.');
                setUpdateComplete(true);
            });

    }, [location]);

    useEffect(
        () => {
            if (!dataComplete && productData?.products?.length > 0) {
                setItemList({
                    products: productData.products
                });
                setDataComplete(true);
            }
        }, [productData, dataComplete]
    );

    useEffect(
        () => {
            if (!updateComplete) {
                refreshList();
            }
        }, [updateComplete, refreshList]
    );

    const handleAddItem = (item) => {
        let qty = (item && item.qty_requested) ? parseInt(item.qty_requested, 10) : 0;
        if (item && item.id) {
            setSaving(true);
            const params = {
                location: location,
                product_id: item.id,
                qty: qty
            };
            postItem('add', params)
                .then(result => {
                    if (result && result.product_id) {
                        // Display message and reset item list
                        setSaveError(null);
                        setUpdateComplete(false);
                    }
                })
                .then(() => {
                    setSaving(false);
                })
                .catch(error => {
                    // Display message
                    setSaving(false);
                    setSaveError('Sorry, there was an error adding this Product to this Location.');
                });
        }
    };

    const handleUpdateQty = (e) => {
        const item = (e && e.item) ? e.item : null;
        // Locations cannot be deleted by emptying them due to history
        if (item) {
            // Post the update for the requested Product
            setSaving(true);
            let qty = (e.new_qty >= 0) ? (e.new_qty - item.qty) : item.qty;
            const params = {
                id: item.id,
                location: item.location,
                product_id: item.product_id,
                qty: qty
            };

            postItem('update', params)
                .then(result => {
                    if (result && (result.product_id || result.success)) {
                        // Display message and reset item list
                        setSaving(false);
                        setSaveError(null);
                        setUpdateComplete(false);
                    }
                })
                .catch(error => {
                    // Display message
                    setSaving(false);
                    setSaveError('Sorry, there was an error updating this Location.');
                });
        }
    };

    return (
        <>
            <LocationProductsList
                location={productData.location}
                itemList={itemList.products}
                hasEditQty={hasEditQty}
                saving={saving}
                error={saveError}
                onEditQtyFunc={(e) => handleUpdateQty(e)}
            />

            {view === 'create' &&
                <>
                    <FieldDivider type="break" margin="bottom"/>
                    <FieldDescription
                        instructions={true} margin="bottom"
                        text={`Browse for a Product to add to this new Location.`}
                    />
                </>
            }

            {view === 'edit' &&
                <>
                    <FieldDivider type="break" margin="bottom"/>
                    <FieldDescription
                        instructions={true} margin="bottom"
                        text={`Add Products to this Location if desired, by searching or browsing.`}
                    />
                    <FieldDescription
                        instructions={true} margin="bottom"
                        text={`If a Product is already present, update its quantity.`}
                    />
                    <FieldDivider type="break" margin="bottom"/>

                    <LocationAvailableProductsContainer
                        products={itemList.products}
                        onProductAdd={(e) => handleAddItem(e)}
                    />
                    <FieldDivider type="break" margin="bottom"/>
                    <FieldDivider type="divider" margin="bottom"/>
                </>
            }
        </>
    );
}

export default LocationProductListContainer;
