import * as React from "react";
import {
    List,
    Create,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    FormDataConsumer,
    Toolbar,
    TextField,
    TextInput,
    useShowController
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import ListPrimaryButton from '../components/admin/ListPrimaryButton';
import LocationCreateInput from '../components/LocationCreateInput';
import LocationDelete from '../components/LocationDelete';
import LocationProductAdd from '../components/LocationProductAdd';
import LocationProductListContainer from '../components/LocationProductListContainer';
import {WAREHOUSE_LOCATION_COLUMNS} from '../global/listColumns';
import {showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export const WarehouseLocationIcon = LocationOnIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Locations"><WarehouseLocationIcon
    style={viewStyles.avatarSvg}/></Avatar>Warehouse Locations</span>;

const showButtonColumn = showButtonColumnObject();

const listColumns = [showButtonColumn, ...WAREHOUSE_LOCATION_COLUMNS];

const listSort = {field: 'location', order: 'ASC'};

export const WarehouseLocationList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={20}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList primaryText={record => record.location}/>
            ) : (
                <DataTable
                    columns={listColumns}
                    sort={listSort}
                    messageEmpty=" No Warehouse Locations found."
                    {...props}
                />
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Warehouse Locations"><WarehouseLocationIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.location}` : 'Location'}</span>;
};

export const WarehouseLocationShow = (props) => {
    const controllerProps = useShowController(props);

    return (
        <Show title={<ShowTitle/>} {...props}>
            <SimpleShowLayout className="show-layout">
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text="Review Location details below. To edit Products contained in this Location, click the Product's Edit icon."
                />
                <TextField label="Location" source="location" className="input-inline input-280 field-bg"/>
                <FieldDivider type="divider" margin="bottom"/>
                <FieldDescription text="Products Stored in this Location" header={true} marginBottom={true}/>
                <FieldDescription
                    instructions={true}
                    marginBottom={true}
                    text="Click a Product's Edit icon to edit it. You can add or remove it from this Location, or add to a new Location."
                />
                <LocationProductListContainer
                    view="show"
                    productData={controllerProps.record}
                    hasEditQty={false}
                    {...props}
                />
            </SimpleShowLayout>
        </Show>
    )
};

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<WarehouseLocationIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Warehouse Location</span>;

const createDefaultValues = {
    location: null,
    qty: 1,
    rack_number: 1,
    shelf_letter: '',
    shelf_number: 1,
    shelf_position: 'L'
};

const validateLocation = (values) => {
    const errors = {};
    const location = values.location;
    if (!location || location === '') {
        errors.location = 'Please assemble or enter a valid Location.'
    }
    if (location) {
        if (location.indexOf('/') !== -1 || location.indexOf(' ') !== -1) {
            errors.location = 'Locations cannot contain a slash or a space.'
        }
    }
    return errors;
};

const createRedirect = (basePath, id, data) => `/WarehouseLocation/${data.location}/show`;

export const WarehouseLocationCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm initialValues={createDefaultValues}
                    submitOnEnter={false}
                    redirect={createRedirect}
                    validate={validateLocation}>
            <FieldDescription
                instructions={true}
                marginBottom={true}
                text={`Warehouse Locations can be created as needed to store Products. Locations must contain a 
                Product when first created.`}
            />
            <FieldDescription
                instructions={true}
                text={`Add a Warehouse Location. Select a Rack number, Shelf Letter and number, and 
                    Left/Right/Center position.`}
            />
            <FieldDescription
                description={true} marginBottom={true}
                text={`If a Location is not a Rack/Shelf/Position value, select Custom Location and enter a 
                    value. Location must be unique.`}
            />

            <FormDataConsumer {...props}>
                {({formData, ...rest}) => (
                    <>
                        <LocationCreateInput formData={formData} {...props}/>
                        <TextInput label="" source="location" disabled className="input-320 hidden-input"/>
                    </>
                )}
            </FormDataConsumer>

            <FieldDivider type="divider" margin="bottom"/>

            <FieldDescription
                instructions={true} marginBottom={true}
                text="Add a Product to store in this Location, then click Save."
            />
            <FieldDescription
                instructions={true} marginBottom={true}
                text="You must add one Product to this Location. Browse for Products with available quantity to add to
                 this Location. Only Products with available quantity will be shown."
            />
            <FieldDescription description={true} text="Browse for a Product:"/>

            <FormDataConsumer {...props}>
                {({formData, ...rest}) => (
                    <LocationProductAdd formData={formData}/>
                )}
            </FormDataConsumer>

            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to this Warehouse Location's Detail view. You may then view this Location to add more Products."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit (Unused)
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<WarehouseLocationIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit Location {record ? `${record.location}` : 'Warehouse Location'}</span>;
};

const EditToolbar = props => {
    return (
        <Toolbar {...props} classes={useToolbarStyles()}>
            <ListPrimaryButton basePath={props.basePath} label="List"/>
        </Toolbar>
    )
};

export const WarehouseLocationEdit = (props) => (
    <Edit title={<EditTitle/>} undoable={false} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>} validate={validateLocation}>

            <TextInput disabled label="Location" source="location" formClassName="field-bg input-inline input-280"/>

            <FormDataConsumer {...props}>
                {({formData, ...rest}) => (
                    <>
                        <FieldDivider type="divider" margin="bottom"/>
                        <FieldDescription text="Products Stored in this Location" header={true} marginBottom={true}/>
                        <FieldDescription
                            instructions={true}
                            marginBottom={false}
                            text="Click a Product's Location History icon to view its history of storage in this Location."
                        />
                        <FieldDescription
                            instructions={true}
                            marginBottom={true}
                            text="Click a Product's Edit Product icon to edit its quantity in this Location."
                        />
                        <LocationProductListContainer
                            view="show"
                            productData={formData}
                            hasEditQty={false}
                            {...props}
                        />
                    </>
                )}
            </FormDataConsumer>

            <FormDataConsumer {...props}>
                {({formData, ...rest}) => (
                    <LocationDelete formData={formData}/>
                )}
            </FormDataConsumer>

            <FieldDescription
                description={true}
                text="After viewing or editing Products, click List to return to the Warehouse Locations List."
            />
        </SimpleForm>
    </Edit>
);

