import React, {useState} from 'react';
import {
    Confirm
} from 'react-admin';
import axios from 'axios';
import {RESOURCES as orderResources} from '../orderResources';
import {RESOURCES as receiptResources} from '../receiptResources';
import {getPostHeaders} from '../utils/headers';
import {allowStoredOrderReceipt} from '../utils/orderReceiptStorageFunctions';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles({
    btnText: {
        marginLeft: 6,
        padding: '1px 10px'
    }
});

/**
 * A component to handle deleting Orders or Receipts with confirmation dialog
 *
 * @param onFail
 * @param onSuccess
 * @param id
 * @param client
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteOrderButton = ({
                               onFail,
                               onSuccess,
                               id = 0,
                               client = '',
                               type = 'order'
                           }) => {
    const [open, setOpen] = useState(false);

    const typeStr = (type === 'receipt') ? 'Receipt' : 'Order';

    const handleClick = () => setOpen(true);

    const handleDialogClose = () => {
        setOpen(false);
    };

    const checkOrderOrReceipt = () => {
        allowStoredOrderReceipt(type, id)
            .then((mayBeDeleted) => {
                if (mayBeDeleted) {
                    // Continue with deletion request and removal
                    handleConfirm();
                } else {
                    // Remove from stored
                    if (typeof onFail === 'function') {
                        onFail(id);
                    }
                }

            })
            .catch((error) => {
                // Remove from stored
                if (typeof onFail === 'function') {
                    onFail(id);
                }
            });
    };

    const handleConfirm = () => {
        let params = {id: id};
        let cfg;
        if (type === 'receipt') {
            cfg = receiptResources.Receipt.DELETE(params);
        } else if (type === 'order') {
            cfg = orderResources.Order.DELETE(params);
        }
        const headers = getPostHeaders(true);
        try {
            axios({
                method: cfg.method,
                url: cfg.uri,
                headers: headers
            })
                .then((result) => {
                    if (result && result.data && result.data.message) {
                        if (typeof onSuccess === 'function') {
                            onSuccess(id);
                        }
                    }
                })
                .catch((error) => {
                    if (typeof onFail === 'function') {
                        onFail(id);
                    }
                })
                .finally(() => {
                    setOpen(false);
                });
        } catch (err) {
            if (typeof onFail === 'function') {
                onFail(id);
            }
            setOpen(false);
        }
    };

    const classes = useStyles();

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                className={classes.btnText}
                startIcon={<RemoveCircleOutlineIcon/>}
                onClick={() => handleClick()}
            >
                Delete {typeStr}
            </Button>
            <Confirm
                isOpen={open}
                title={`Delete ${typeStr}`}
                content={`Delete ${typeStr} #${id} for ${client} from the system?`}
                confirm={`Yes, Delete ${typeStr}`}
                onConfirm={checkOrderOrReceipt}
                onClose={handleDialogClose}
            />
        </>
    );
}

export default DeleteOrderButton;
