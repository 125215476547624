import {
    addLeadingZero,
    addTimeZoneOffset,
    isBlank
} from './utils/dataFunctions';

/**
 * Intercept resources that attempt to load data before a filter value is available
 *
 * @param resource
 * @param type
 * @param params
 *
 * @return boolean
 */
export const interceptLoadData = (type, resource, params) => {
    const filter = params.filter;
    if (resource === 'BillingReference' && type === 'GET_LIST') {
        if (isBlank(filter.filter_org_id)) {
            return true;
        }
    }
    if (resource === 'Contact' && type === 'GET_LIST') {
        if (isBlank(filter.filter_org_id)) {
            return true;
        }
    }
    if (resource === 'Order' && type === 'GET_LIST') {
        const filterOrdersBy = filter.filter_by;
        if (
            isBlank(filterOrdersBy) ||
            (filterOrdersBy === 'bill_ref' && isBlank(filter.filter_bill_ref_id)) ||
            (filterOrdersBy === 'client' && isBlank(filter.filter_org_id)) ||
            (filterOrdersBy === 'contact' && (isBlank(filter.filter_org_id) || isBlank(filter.filter_contact_id))) ||
            (filterOrdersBy === 'date_range' && (isBlank(filter.filter_from) || isBlank(filter.filter_to))) ||
            (filterOrdersBy === 'id' && (isBlank(filter.filter_id) || filter.filter_id.length < 3 )) ||
            (filterOrdersBy === 'sku' && (isBlank(filter.filter_sku) || filter.filter_sku.length < 12 )) ||
            (filterOrdersBy === 'status' && isBlank(filter.filter_status_id) && (isBlank(filter.filter_from) || isBlank(filter.filter_to)))
        ) {
            return true;
        }
    }
    if (resource === 'Receipt' && type === 'GET_LIST') {
        const filterReceiptsBy = filter.filter_by;
        if (
            isBlank(filterReceiptsBy) ||
            (filterReceiptsBy === 'bill_ref' && isBlank(filter.filter_bill_ref_id)) ||
            (filterReceiptsBy === 'client' && isBlank(filter.filter_org_id)) ||
            (filterReceiptsBy === 'id' && (isBlank(filter.filter_id) || filter.filter_id.length < 3 )) ||
            (filterReceiptsBy === 'sku' && (isBlank(filter.filter_sku) || filter.filter_sku.length < 12 ))
        ) {
            return true;
        }
    }
    if (resource === 'Product' && type === 'GET_LIST') {
        if (
            (!isBlank(filter.filter_title) && filter.filter_title.length > 2)
        ) {
            // Allow title search
            return false;
        }
        if (!isBlank(filter.filter_sku)) {
            let sku = filter.filter_sku.toLowerCase().replace('sku-l', '').replace('sku-', '');
            if (sku.length < 6) {
                return true;
            } else {
                return false;
            }
        }
        const filterProductBy = filter.filter_by;
        if (
            isBlank(filterProductBy) ||
            (filterProductBy === 'client' && isBlank(filter.filter_org_id)) ||
            (filterProductBy === 'product_type' && isBlank(filter.filter_product_type_id)) ||
            (filterProductBy === 'show' && isBlank(filter.filter_show_id))
        ) {
            return true;
        }
    }
    if (resource === 'ShippingMethod' && type === 'GET_LIST') {
        if (isBlank(filter.filter_company_id)) {
            return true;
        }
    }
    return false;
};

/**
 * Modify format of params before a GET call
 *
 * @param type
 * @param resource
 * @param params
 * @return {{active: *, id: *, params: *}}
 */
export const formatGetParams = (type, resource, params) => {
    if (type === 'GET_LIST') {
        if (
            resource === 'BillingReference' ||
            resource === 'ClientUser' ||
            resource === 'Contact' ||
            resource === 'Employee' ||
            resource === 'Order' ||
            resource === 'Organization' ||
            resource === 'Receipt' ||
            resource === 'Show' ||
            resource === 'ShippingCompany' ||
            resource === 'ShippingMethod' ||
            resource === 'ParentProductType' ||
            resource === 'ProductType' ||
            resource === 'WarehouseLocation' ||
            resource === 'Product'
        ) {
            params = preProcessActiveIdParams(params);
        }
    }
    if (
        (resource === 'ProductsByOrg') ||
        (resource === 'ProductsByShow') ||
        (resource === 'ProductsByTitle') ||
        (resource === 'ProductsByType')
    ) {
        params = preProcessActiveIdParams(params);
    }
    return params;
};

/**
 * Format parameters and query string before a GET call
 *
 * @param params
 * @return {{active: *, id: *, params: *}}
 */
const preProcessActiveIdParams = (params) => {
    let active = params.filter.active;
    let id = params.filter.id;
    delete params.filter.active;
    delete params.filter.id;

    return {
        active: active,
        id: id,
        params: params
    };
};

/**
 * Modify or format data before a POST call
 *
 * @param type
 * @param resource
 * @param data
 * @return {*}
 */
export const formatPostData = (type, resource, data) => {
    if (resource === 'BillingReference') {
        data = formatPostBillingReferenceData(type, data);
    }
    if (resource === 'ClientUser') {
        data = formatPostClientUserData(type, data);
    }
    if (resource === 'Product') {
        data = formatPostProductData(type, data);
    }
    if (resource === 'ProductType') {
        data = formatPostProductTypeData(type, data);
    }
    if (resource === 'Order' && type === 'UPDATE') {
        data = formatPostOrderData(type, data);
    }
    if (resource === 'Receipt' && type === 'UPDATE') {
        data = formatPostReceiptData(type, data);
    }
    if (resource === 'Show') {
        data = formatPostShowData(type, data);
    }
    if (resource === 'WarehouseLocation' && type !== 'DELETE') {
        data = formatPostWarehouseLocationData(type, data);
    }
    return data;
};

/* Processing for Specific Resources ::::: */

const formatPostBillingReferenceData = (type, data) => {
    if (data.id) {
        data.id = parseInt(data.id, 10);
    }
    if (data.new_org_id) {
        data.org_id = parseInt(data.new_org_id, 10);
    }
    delete data.current_org_id;
    delete data.new_org_id;
    delete data.org_name;

    return data;
};

const formatPostClientUserData = (type, data) => {
    delete data.org_id;

    return data;
};

const formatPostOrderData = (type, data) => {
    data.is_outgoing = true;

    // Set Billed By if billed
    data.billed = data.set_billed;
    let billed_by_name = data.billed_by_name;
    if (data.billed) {
        data.billed_by_name = (data.set_billed_by && data.set_billed_by !== '') ? data.set_billed_by : billed_by_name;
    } else {
        data.billed_by_name = '';
    }

    if (data.billed_on) {
        let date = addTimeZoneOffset(new Date(data.billed_on));
        data.billed_on = date.toISOString();
    }
    if (data.invoice_date) {
        let date = addTimeZoneOffset(new Date(data.invoice_date));
        data.invoice_date = date.toISOString();
    }
    if (data.new_bill_ref_id) {
        data.bill_ref_id = parseInt(data.new_bill_ref_id, 10);
    }
    if (data.clear_bill_ref_id) {
        data.bill_ref_id = null;
    }
    if (data.new_required_by) {
        let required_by = addTimeZoneOffset(new Date(data.new_required_by));
        data.required_by = required_by.toISOString();
    }
    if (data.new_ship_to_id) {
        data.ship_to_id = parseInt(data.new_ship_to_id, 10);
    }
    if (data.new_status_id) {
        data.status_id = parseInt(data.new_status_id, 10);
    }
    if (data.shipped_on) {
        let date = addTimeZoneOffset(new Date(data.shipped_on));
        data.shipped_on = date.toISOString();
    }
    // Convert/combine separate time/currency fields
    data.courier_cost = (data.courier_cost) ? data.courier_cost.toString() : '0';
    data.labor_cost = (data.labor_cost) ? data.labor_cost.toString() : '0';
    data.material_cost = (data.material_cost) ? data.material_cost.toString() : '0';
    data.shipping_cost = (data.shipping_cost) ? data.shipping_cost.toString() : '0';
    let labor_time_hours = (data.labor_time_hours) ? data.labor_time_hours.toString() : 0;
    let labor_time_mins = (data.labor_time_mins) ? data.labor_time_mins.toString() : 0;
    data.labor_time = labor_time_hours + ':' + addLeadingZero(labor_time_mins);

    // // Delete parameters that will not be sent (do not delete dates and strings, as List needs them)
    delete data.set_billed;
    delete data.set_billed_by;
    delete data.bill_ref_desc;
    delete data.clear_bill_ref_id;
    delete data.created_by;
    delete data.created_on;
    delete data.invoice_date_date;
    delete data.invoice_date_string;
    delete data.labor_time_hours;
    delete data.labor_time_mins;
    delete data.modified_by;
    delete data.new_bill_ref_id;
    delete data.new_required_by;
    delete data.new_ship_to_id;
    delete data.new_status_id;
    delete data.order_status;
    delete data.org_name;
    delete data.required_by_date;
    delete data.shipped_with_name;
    delete data.shipping_type_name;
    delete data.show_name;

    return data;
};

const formatPostReceiptData = (type, data) => {
    data.is_outgoing = false;

    // Set Billed By if billed
    data.billed = data.set_billed;
    let billed_by_name = data.billed_by_name;
    if (data.billed) {
        data.billed_by_name = (data.set_billed_by && data.set_billed_by !== '') ? data.set_billed_by : billed_by_name;
    } else {
        data.billed_by_name = '';
    }

    if (data.billed_on) {
        let date = addTimeZoneOffset(new Date(data.billed_on));
        data.billed_on = date.toISOString();
    }
    if (data.invoice_date) {
        let date = addTimeZoneOffset(new Date(data.invoice_date));
        data.invoice_date = date.toISOString();
    }

    if (data.new_bill_ref_id) {
        data.bill_ref_id = parseInt(data.new_bill_ref_id, 10);
    }
    if (data.clear_bill_ref_id) {
        data.bill_ref_id = null;
    }

    // Convert/combine separate time/currency fields
    data.courier_cost = (data.courier_cost) ? data.courier_cost.toString() : '0';
    data.labor_cost = (data.labor_cost) ? data.labor_cost.toString() : '0';
    data.material_cost = (data.material_cost) ? data.material_cost.toString() : '0';
    data.receiving_cost = (data.receiving_cost) ? data.receiving_cost.toString() : '0';
    let labor_time_hours = (data.labor_time_hours) ? data.labor_time_hours.toString() : 0;
    let labor_time_mins = (data.labor_time_mins) ? data.labor_time_mins.toString() : 0;
    data.labor_time = labor_time_hours + ':' + addLeadingZero(labor_time_mins);

    data.approved = data.set_approved;
    let approved_by = data.approved_by;
    if (data.approved) {
        data.approved_by = (data.set_approved_by && data.set_approved_by !== '') ? data.set_approved_by : approved_by;
    } else {
        data.approved_by = '';
    }

    // Delete parameters that will not be sent
    delete data.bill_ref_desc;
    delete data.clear_bill_ref_id;
    delete data.created_by;
    delete data.created_on;
    delete data.created_on_date;
    delete data.created_on_string;
    delete data.invoice_date_date;
    delete data.invoice_date_string;
    delete data.labor_time_hours;
    delete data.labor_time_mins;
    delete data.modified_by;
    delete data.modified_string;
    delete data.new_bill_ref_id;
    delete data.org_name;
    delete data.set_approved;
    delete data.set_approved_by;
    delete data.set_billed;
    delete data.set_billed_by;
    delete data.show_name;

    return data;
};

const formatPostProductData = (type, data) => {
    if (data.id) {
        data.id = parseInt(data.id, 10);
    }
    // Update Org ID (New Product)
    if (data.selected_org_id) {
        data.org_id = parseInt(data.selected_org_id, 10);
    }
    // Update Org ID (Edit Product)
    if (data.new_org_id) {
        data.org_id = parseInt(data.new_org_id, 10);
    }

    // Update Show ID
    if (data.selected_show_id) {
        data.show_id = parseInt(data.selected_show_id, 10);
    }
    if (data.new_show_id) {
        data.show_id = parseInt(data.new_show_id, 10);
    }

    delete data.current_show_id;
    delete data.new_show_id;

    // Update Product Category ID (from child or parent)
    if (data.new_parent_type_id && !data.new_type_id) {
        data.type_id = parseInt(data.new_parent_type_id, 10);
    } else if (data.new_type_id) {
        data.type_id = parseInt(data.new_type_id, 10);
    } else if (data.type_id) {
        data.type_id = parseInt(data.type_id, 10);
    }

    // Update Quantity total if an Inventory Adjustment was made
    if (data.inventory_adjustment && data.adjustment_qty !== data.qty) {
        data.qty = data.adjustment_qty;
    }

    if (type === 'CREATE') {
        data.active = true;
    }

    // Delete parameters that will not be sent
    delete data.add_custom_location;
    delete data.add_custom_qty;
    delete data.current_org_id;
    delete data.modified;
    delete data.new_parent_type_id;
    delete data.new_type_id;
    delete data.new_org_id;
    delete data.org_name;
    delete data.parent_type_id;
    delete data.parent_type_name;
    delete data.selected_org_id;
    delete data.selected_show_id;
    delete data.show_name;
    delete data.type_display_name;
    delete data.type_name;
    delete data.warehouse_locations;
    delete data.inventory_adjustment;
    delete data.adjustment_note;
    delete data.adjustment_qty;
    delete data.adjustment_difference;

    return data;
};

const formatPostProductTypeData = (type, data) => {
    // Update parent Product Category
    if (data.new_parent_type_id) {
        data.parent_type_id = parseInt(data.new_parent_type_id, 10);
    } else if (data.parent_type_id) {
        data.parent_type_id = parseInt(data.parent_type_id, 10);
    }
    // Override if Category should have no parent
    if (data.no_parent_type_id) {
        data.parent_type_id = null;
    }

    // Delete parameters that will not be sent
    delete data.modified;
    delete data.new_parent_type_id;
    delete data.new_parent_type_name;
    delete data.no_parent_type_id;
    delete data.parent_type_name;

    return data;
};

const formatPostShowData = (type, data) => {
    if (data.id) {
        data.id = parseInt(data.id, 10);
    }
    if (data.new_org_id) {
        data.org_id = parseInt(data.new_org_id, 10);
    }
    if (type === 'CREATE') {
        data.active = true;
    }

    delete data.current_org_id;
    delete data.new_org_id;

    return data;
};

const formatPostWarehouseLocationData = (type, data) => {
    if (data.product_id === null) {
        delete data.product_id;
        data.qty = 0;
    }
    delete data.available_qty;
    delete data.custom_location;
    delete data.custom_value;
    delete data.is_custom;
    delete data.location_value_assembled;
    delete data.location_value_custom;
    delete data.org_id;
    delete data.rack_number;
    delete data.shelf_letter;
    delete data.shelf_number;
    delete data.shelf_position;
    delete data.show_id;

    return data;
};
