import React, {Fragment, cloneElement, useState} from 'react';
import {Form} from 'react-final-form';
import {
    CreateButton,
    List,
    ListButton,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    FormDataConsumer,
    BooleanField,
    DateField,
    TextField,
    BooleanInput,
    DateInput,
    NumberInput,
    SelectInput,
    TextInput,
    SaveButton,
    ShowButton,
    TopToolbar,
    Toolbar,
    sanitizeListRestProps,
    useListContext,
    useListController,
    useRefresh,
    useShowController,
    useUnselectAll, EditButton
} from 'react-admin';
import Emitter from '../utils/eventEmitter';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import BulkEditOrderSubmitButton from '../components/BulkEditOrderSubmitButton';
import ContactDetails from '../components/ContactDetails';
import ContactDetailsFromID from '../components/ContactDetailsFromID';
import DataTable from '../components/admin/DataTable';
import DeleteWithDialog from '../components/DeleteWithDialog';
import ExportBasicToolbarButton from '../components/ExportBasicToolbarButton';
import ExportDetailedToolbarButton from '../components/ExportDetailedToolbarButton';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import GetListSelectInput from '../components/GetListSelectInput';
import ListEventHandler from '../utils/ListEventHandler';
import OrderReceiptCostTotals from '../components/OrderReceiptCostTotals';
import OrderItemListViewContainer from '../components/OrderItemListViewContainer';
import PrintToolbarButton from '../components/admin/PrintToolbarButton';
import SelectFromListInput from '../components/SelectFromListInput';
import {getDefaultDateRanges} from '../utils/dataFunctions';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {BILLING_LABOR_MINUTES, BILLING_LABOR_PER_HOUR_TITLE} from "../global/billingDefaults";
import {
    ORDER_BROWSE_EXPORT_COLUMNS,
    ORDER_BILLING_EXPORT_COLUMNS,
    ORDER_QUERY_DEFAULTS,
    ORDER_RECEIPT_TABLE_FILTERS
} from '../global/orderReceiptDefaults';
import {ORDER_STATUS, ORDER_STATUS_NO_CANCEL} from '../global/orderStatus';
import {viewStyles} from '../global/styles';
import {makeStyles} from '@material-ui/core/styles';
import {useMediaQuery} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import OrdersIcon from '@material-ui/icons/Receipt';
import OrderUsernameField from '../components/OrderUsernameField';

export const OrderIcon = OrdersIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const defaultDateRanges = getDefaultDateRanges();

/**
 * View: List :::::::::::::::::::::::::::::::::::
 */

/**
 * Filter List View
 *
 * Select View Mode
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ListFilter = (props) => {

    const unselectAll = useUnselectAll();

    const onFilterChange = (e) => {
        // Unselect Bulk-edit items if there are none
        if (props.selectedIds.length) {
            unselectAll('Order');
        }

        // Clear table quick-filters when top-level Filters change
        let updateFilters = false;
        let fName, fValue;
        if (e && e.target) {
            fName = e.target.name;
            fValue = e.target.value;
        }
        // If View Mode or Filter By changes:
        if (
            fName && fValue &&
            (fName === 'filter_list_view' || fName === 'filter_by')
        ) {
            const fValues = JSON.parse(JSON.stringify(props.filterValues));
            ORDER_RECEIPT_TABLE_FILTERS.forEach((filter) => {
                if (fValues[filter.key]) {
                    if (filter.clearOnFilterBy.includes(fValue) || fName === 'filter_list_view') {
                        fValues[filter.key] = undefined;
                        updateFilters = true;
                    }
                }
            });
            if (updateFilters && typeof props.setFilters === 'function') {
                fValues[fName] = fValue;
                props.setFilters(fValues);
            }
        }
    };

    return (
        <Filter {...props}>
            <SelectInput
                key="filter_list_view" source="filter_list_view" label="Select Viewing Mode"
                choices={[
                    {id: 'browse', name: 'Browse Mode'},
                    {id: 'billing', name: 'Billing Mode'},
                ]}
                alwaysOn
                allowEmpty={false}
                onChange={(e) => onFilterChange(e)}
                className="input-120"
            />
            <SelectInput
                key="filter_by" source="filter_by" label="Filter By:"
                choices={[
                    {id: 'bill_ref', name: 'Billing Reference'},
                    {id: 'date_range', name: 'Date Range'},
                    {id: 'client', name: 'Client'},
                    {id: 'contact', name: 'Client Contact'},
                    {id: 'id', name: 'Order ID'},
                    {id: 'sku', name: 'Product SKU'},
                    {id: 'status', name: 'Status'}
                ]}
                alwaysOn
                allowEmpty={true}
                onChange={(e) => onFilterChange(e)}
                className="input-150"
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_org_id"
                resourceName="Organization"
                listLabel={(props.filterValues.filter_by === 'contact') ? 'Select Client' : 'By Client'}
                itemPlural="Clients"
                returnType="string"
                listFilters={{
                    active: true
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={false}
                onChangeFunc={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && (props.filterValues.filter_by === 'client' || props.filterValues.filter_by === 'contact' || props.filterValues.filter_by === 'bill_ref')) ? 'input-select-list' : 'input-hidden'}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_contact_id"
                resourceName="Contact"
                listLabel="Select Contact"
                itemPlural="Contacts"
                returnType="string"
                listFilters={{
                    active: true,
                    params: {
                        filter: {
                            filter_org_id: props.filterValues.filter_org_id
                        },
                    }
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={false}
                onChangeFunc={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'contact') ? 'input-select-list' : 'input-hidden'}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_bill_ref_id"
                resourceName="BillingReference"
                listLabel="By Billing Reference"
                itemPlural="Billing References"
                returnType="string"
                listFilters={{
                    active: true,
                    params: {
                        filter: {
                            filter_org_id: props.filterValues.filter_org_id
                        },
                    }
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={false}
                onChangeFunc={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'bill_ref') ? 'input-select-list' : 'input-hidden'}
            />
            <SelectInput
                key="filter_status_id"
                source="filter_status_id"
                label="By Status"
                choices={ORDER_STATUS}
                alwaysOn
                allowEmpty={false}
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'status') ? 'input-120' : 'input-hidden'}
            />
            <TextInput
                key="filter_id"
                label="Enter Order ID"
                source="filter_id"
                placeholder=""
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'id') ? 'input-180' : 'input-hidden'}
            />
            <TextInput
                key="filter_sku"
                label="Enter full Product SKU"
                source="filter_sku"
                placeholder="SKU-00012345"
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'sku') ? 'input-180' : 'input-hidden'}
            />
            <DateInput
                key="filter_from"
                source="filter_from" label="From"
                inputProps={{
                    min: defaultDateRanges.from,
                    max: defaultDateRanges.to
                }}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={
                    (
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'date_range') ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'client' && props.filterValues.filter_org_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'contact' && props.filterValues.filter_contact_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'status' && props.filterValues.filter_status_id)
                    )
                        ? 'input-180' : 'input-hidden'
                }
            />
            <DateInput
                key="filter_to"
                source="filter_to" label="To"
                inputProps={{
                    min: defaultDateRanges.from,
                    max: defaultDateRanges.to
                }}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={
                    (
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'date_range') ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'client' && props.filterValues.filter_org_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'contact' && props.filterValues.filter_contact_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'status' && props.filterValues.filter_status_id)
                    )
                        ? 'input-180' : 'input-hidden'
                }
            />
        </Filter>
    )
};

// Container for Bulk-editing actions
const ListBulkActionButtons = props => {

    const refresh = useRefresh();

    const [editOption, setEditOption] = useState(null);
    const [selectedBilled, setSelectedBilled] = useState(true);

    const onBulkEditOption = (e) => {
        let val = e?.target?.value;
        if (val) {
            setEditOption(val);
        } else {
            setEditOption(null);
        }
    };

    const onBilledSelect = (e) => {
        let val = e?.target?.value;
        if (val !== undefined && val !== null) {
            setSelectedBilled(val);
        } else {
            setSelectedBilled(false);
        }
    };

    const handleBulkEditSubmit = (e) => {
        e.preventDefault();
    };

    const handleUpdateSuccess = () => {
        setTimeout(function () {
            refresh();
        }, 1000);
    };

    return (
        <>
            <Form
                onSubmit={handleBulkEditSubmit}
                render={({form, handleSubmit, values}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <span className="input-inline input-180 input-bulk-edit">
                                <SelectInput
                                    key="bulk_edit_option" source="bulk_edit_option" label="Select value to edit:"
                                    choices={[
                                        {id: 'bulk_edit_option_billed', name: 'Billed State'}
                                    ]}
                                    allowEmpty={true}
                                    onChange={(e) => onBulkEditOption(e)}
                                />
                            </span>
                            <span
                                className={(editOption === 'bulk_edit_option_billed') ? 'input-inline input-180 input-bulk-edit' : 'input-hidden'}>
                                <SelectInput
                                    key="bulk_edit_billed" source="bulk_edit_billed" label="Select Billed State:"
                                    choices={[
                                        {id: true, name: 'Billed'},
                                        {id: false, name: 'Not Billed'},
                                    ]}
                                    defaultValue={true}
                                    allowEmpty={false}
                                    onChange={(e) => onBilledSelect(e)}
                                />
                            </span>
                            <BulkEditOrderSubmitButton
                                editOption={editOption}
                                selectedIds={props.selectedIds}
                                selectedBilled={selectedBilled}
                                onSuccess={() => handleUpdateSuccess()}
                                {...props}
                            />
                        </form>
                    )
                }}
            >
            </Form>
        </>
    );
};

// List Title
const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Orders"><OrderIcon style={viewStyles.avatarSvg}/></Avatar>Orders</span>;

// List Actions (Top Toolbar): Filters, Create, Export
const ListActions = (props) => {
    const {
        className,
        ids,
        filters,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext();

    const view = (filterValues && filterValues.filter_list_view && filterValues.filter_list_view === 'billing') ? 'Billing' : 'Browse';
    let exportColumns = ORDER_BROWSE_EXPORT_COLUMNS;
    if (view === 'Browse') {
        exportColumns = ORDER_BROWSE_EXPORT_COLUMNS;
    }
    if (view === 'Billing') {
        exportColumns = ORDER_BILLING_EXPORT_COLUMNS;
    }
    const sort = {
        sort_by: props.currentSort.field ?? 'created_on',
        sort_order: props.currentSort.order ?? 'DESC'
    };

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath}/>
            <ExportBasicToolbarButton
                type="Orders"
                label="Export"
                filename={`Parrot-Orders-${view}-${defaultDateRanges.to}`}
                filterValues={filterValues}
                sort={sort}
                exportColumns={exportColumns}
                disabled={(!ids || ids.length === 0)}
            />
            <ExportDetailedToolbarButton
                type="Orders"
                label="Detailed Export"
                filterValues={filterValues}
                disabled={(!ids || ids.length === 0)}
            />
        </TopToolbar>
    );
};

// Handle event from List checkbox column
const onToggleItem = (selectedIds, id, e) => {
    Emitter.emit('updateListSelectedIds', {
        selectedIds: selectedIds,
        resource: 'Order',
        id: id,
        checked: e?.target?.checked ?? false
    });
};

// Column Object: Delete Icon
const deleteOrderButtonColumnObject = (label, resourceFile, resourceName, resourceParam) => {
    return {
        Header: () => null,
        id: 'list-delete',
        Cell: ({row, basePath}) => {
            return (
                <DeleteWithDialog
                    type="icon"
                    label={label}
                    resourceFile={resourceFile}
                    resourceName={resourceName}
                    resourceParam={resourceParam}
                    record={row.original}
                    className="table-icon-button"
                    disabled={(row.original.status_id !== 1)}
                />
            )
        },
        width: 60,
        maxWidth: 60,
        ClassName: 'cell-icon',
        disableFilters: true,
        disableSortBy: true
    }
};

// Column Icons: Show, Edit, Delete
const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();
const deleteButtonColumn = deleteOrderButtonColumnObject('Order', 'order', 'Order', 'id');

const listSort = {field: ORDER_QUERY_DEFAULTS.sort_by, order: ORDER_QUERY_DEFAULTS.sort_order};
const listPagination = {page: ORDER_QUERY_DEFAULTS.page, perPage: ORDER_QUERY_DEFAULTS.per_page};

/**
 * Order List with column assembly
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const OrderList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const lc = useListController(props);
    const fv = lc.filterValues;
    const filterDefaultValues = {
        filter_list_view: 'browse',
        filter_by: 'status',
        filter_status_id: 1,
        filter_from: defaultDateRanges.monthBack,
        filter_to: defaultDateRanges.to
    };

    // Assemble List columns based on View Mode or Filter By values
    const billedCol = {
        Header: 'Billed',
        accessor: 'billed',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => (
            <BooleanField record={row.original} label="" source="billed"/>
        )
    };
    const billedByCol = {
        Header: 'Billed by',
        accessor: 'billed_by_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.billed_by_name) ? row.original.billed_by_name : ''}</span>
    };
    const billRefCol = {
        Header: 'Billing Reference',
        accessor: 'bill_ref_desc',
        sortBy: 'bill_ref_id',
        sortType: 'basic',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.bill_ref_desc) ? row.original.bill_ref_desc : ''}</span>
    };
    const clientCol = {
        Header: 'Client',
        accessor: 'org_name',
        sortType: 'basic',
        sortBy: 'org_id',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.org_name) ? row.original.org_name : ''}</span>
    };
    const courierCostCol = {
        Header: 'Courier Cost',
        accessor: 'courier_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span
            className="right">{(row.original.courier_cost_str) ? row.original.courier_cost_str : '0.00'}</span>
    };
    const destinationCol = {
        Header: 'Destination',
        accessor: 'ship_to_name',
        sortType: 'basic',
        sortBy: 'ship_to_id',
        Cell: ({row}) =>
            <span>{(row.original.ship_to && row.original.ship_to_name) ? row.original.ship_to_name : ''}</span>
    };
    const shippedWithCol = {
        Header: 'Shipped With',
        accessor: 'shipped_with_name',
        sortType: 'basic',
        sortBy: 'shipped_with',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.shipped_with_name) ? row.original.shipped_with_name : ''}</span>
    };
    const shippingMethodCol = {
        Header: 'Shipping Method',
        accessor: 'shipping_type_name',
        sortType: 'basic',
        sortBy: 'shipping_type',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.shipping_type_name) ? row.original.shipping_type_name : ''}</span>
    };
    const trackingNumberCol = {
        Header: 'Tracking Number',
        accessor: 'tracking_number',
        sortType: 'basic',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.tracking_number) ? row.original.tracking_number : ''}</span>
    };
    const laborCostCol = {
        Header: 'Labor Cost',
        accessor: 'labor_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.labor_cost_str) ? row.original.labor_cost_str : '0.00'}</span>
    };
    const materialCostCol = {
        Header: 'Material Cost',
        accessor: 'material_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.material_cost_str) ? row.original.material_cost_str : '0.00'}</span>
    };
    const shippingCostCol = {
        Header: 'Shipping Cost',
        accessor: 'shipping_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.shipping_cost_str) ? row.original.shipping_cost_str : '0.00'}</span>
    };
    const statusCol = {
        Header: 'Status',
        accessor: 'order_status',
        sortType: 'basic',
        sortBy: 'status_id',
        Cell: ({row}) => <span>{(row.original.order_status) ? row.original.order_status : ''}</span>
    };
    const totalCostCol = {
        Header: 'Total',
        accessor: 'total_cost',
        sortType: 'basic',
        disableFilters: true,
        width: 90,
        maxWidth: 90,
        Cell: ({row}) => <span>{(row.original.total_cost) ? row.original.total_cost : '0.00'}</span>
    };

    // Create initial columns
    const listColumns = [
        {
            Header: () => null,
            id: 'list-select',
            Cell: ({row}) => (
                <Checkbox
                    color="default"
                    checked={(lc.selectedIds.indexOf(row.original.id) !== -1)}
                    onClick={(e) => onToggleItem(lc.selectedIds, row.original.id, e)}
                />
            ),
            width: 80,
            maxWidth: 80,
            disableFilters: true,
            disableSortBy: true
        },
        showButtonColumn,
        editButtonColumn,
        {
            Header: 'Order ID',
            accessor: 'id',
            sortType: 'basic',
            width: 90,
            maxWidth: 90,
        },
        {
            Header: 'Created On',
            accessor: 'created_on',
            sortType: 'datetime',
            disableFilters: true,
            Cell: ({row}) => <span>{(row.original.created_on_string) ? row.original.created_on_string : ''}</span>
        }
    ];

    // Exclude columns based on Filter By (Client, Contact, Billing Reference)
    if (fv?.filter_by) {
        if (
            (!fv.filter_org_id) ||
            (fv.filter_by !== 'client' && fv.filter_by !== 'contact')
        ) {
            listColumns.push(clientCol);
        }
        if (
            !fv.filter_bill_ref_id ||
            fv.filter_by !== 'bill_ref'
        ) {
            listColumns.push(billRefCol);
        }
    } else {
        // If Filter By is unselected, display columns
        listColumns.push(clientCol, billRefCol);
    }

    // Include Destination if in Browse mode and filtering by Client
    if (fv?.filter_list_view === 'browse') {
        if (fv?.filter_by) {
            if (!fv.filter_contact_id || fv.filter_by !== 'contact') {
                listColumns.push(destinationCol);
            }
        }
    } else if (!fv.filter_list_view && filterDefaultValues.filter_list_view === 'browse') {
        listColumns.push(destinationCol);
    }

    // Include Shipping data if in Browse Mode
    if (fv?.filter_list_view === 'browse' || (!fv.filter_list_view && filterDefaultValues.filter_list_view === 'browse')) {
        listColumns.push(shippedWithCol);
        listColumns.push(shippingMethodCol);
    }
    // Include Tracking Number if in Browse Mode and filtering by Status: Shipped
    if (
        (fv?.filter_list_view === 'browse' || (!fv.filter_list_view && filterDefaultValues.filter_list_view === 'browse')) &&
        fv?.filter_by === 'status' && fv?.filter_status_id === 4
    ) {
        listColumns.push(trackingNumberCol);
    }

    // Include Status if not filtering by status
    if (fv?.filter_by) {
        if (!fv.filter_status_id || fv.filter_by !== 'status') {
            listColumns.push(statusCol);
        }
    } else if (!fv.filter_by && filterDefaultValues.filter_by === 'status') {
    } else {
        // If Filter By is unselected, display columns
        listColumns.push(statusCol);
    }

    listColumns.push(
        {
            Header: 'Created By',
            accessor: 'created_by',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.created_by) ? row.original.created_by : ''}</span>
        }
    );

    // Include Billed and cost columns if in Billing viewing mode
    if (fv?.filter_list_view === 'billing') {
        listColumns.push(billedCol, billedByCol, laborCostCol, materialCostCol, shippingCostCol, courierCostCol, totalCostCol);
    }

    listColumns.push(
        deleteButtonColumn
    );

    return (
        <>
            <List
                title={ListTitle}
                sort={listSort}
                perPage={listPagination.perPage}
                actions={<ListActions/>}
                filters={<ListFilter setFilters={lc.setFilters}/>}
                filterDefaultValues={filterDefaultValues}
                bulkActionButtons={<ListBulkActionButtons/>}
                pagination={false}
                {...props}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => record.org_name}
                        secondaryText={record => record.ship_to_name}
                        tertiaryText={record => record.id}
                    />
                ) : (
                    <DataTable
                        columns={listColumns}
                        sort={listSort}
                        listFilters="filterValues"
                        listFilter="filter_by"
                        messageFilter="Select a Filter to view Orders, or select a viewing Mode."
                        messageEmpty=" No Orders found. Please select a filter to view Orders."
                        instructions="To edit multiple Orders, select checkboxes and choose a function."
                        storeForExport={true}
                        manualQuickFilters={true}
                        manualSortAndPagination={true}
                        {...props}
                    />
                )}
            </List>
            <ListEventHandler/>
        </>
    )
};

/**
 * View: Show :::::::::::::::::::::::::::::::::::
 */

const ShowActions = ({basePath, data}) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath}/>
            <EditButton basePath={basePath} record={data}/>
            <PrintToolbarButton record={data} type="Order"/>
        </TopToolbar>
    )
};

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title" alt="Orders"><OrderIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `Order #${record.id}` : 'Order'}</span>;
};

export const OrderShow = (props) => {
    const controllerProps = useShowController(props);

    return (
        <Show title={<ShowTitle/>} actions={<ShowActions/>} {...props} {...controllerProps}>
            <SimpleShowLayout className="show-layout">
                <FieldDescription
                    text={`
                Review Order details below. To update this Order's status, click Edit above.
                `}
                    instructions={true} marginBottom={true}
                />
                <TextField label="Order ID" source="id" className="input-inline input-900"/>
                <TextField label="Client" source="org_name" className="input-inline input-320"/>
                <TextField label="Order Status" source="order_status" className="input-inline input-120"/>
                <FieldDivider type="break"/>
                <TextField label="Billing Reference" source="bill_ref_desc" className="field-bg"/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Order Details" instructions={true} marginBottom={true}/>
                <TextField label="Created By" source="created_by" className="input-inline input-220"/>
                <DateField label="Created On" source="created_on" className="input-inline input-220"/>
                <FieldDivider type="break"/>
                <DateField
                    label="Due Date" source="required_by"
                    options={{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}}
                    className="field-bg"/>
                <FieldDivider type="break"/>
                <TextField label="Carrier" source="shipped_with_name" className="input-inline input-220"/>
                <TextField label="Method" source="shipping_type_name" className="input-inline input-220"/>
                <DateField label="Shipped On:" source="shipped_on" className="input-inline input-180"/>
                <FieldDivider type="break"/>
                <TextField label="Tracking Number" source="tracking_number" className="field-bg"/>
                <TextField label="Client Notes" source="client_notes" className="field-bg has-breaks"/>
                <TextField label="Admin Notes" source="notes" className="field-bg has-breaks"/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Product List" instructions={true} marginBottom={true}/>
                <OrderItemListViewContainer
                    orderData={controllerProps.record}
                    view="show"
                    hasDelete={false}
                    hasEditQty={false}
                    hasRemove={false}
                    {...props}
                />

                <FieldDivider type="divider"/>
                <FieldDescription text="Billing" instructions={true} marginBottom={true}/>

                <BooleanField label="Billed?" source="billed" className="input-inline input-220"/>
                <TextField label="Labor Cost" source="labor_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <TextField label="Billed By:" source="billed_by_name" className="input-inline input-220"/>
                <TextField label="Material Cost" source="material_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <DateField label="Invoice Date" source="invoice_date" className="input-inline input-220"/>
                <TextField label="Shipping Cost" source="shipping_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <TextField label="Labor Time" source="labor_time" className="input-inline input-220"/>
                <TextField label="Courier Cost" source="courier_cost_str"
                           className="input-right input-inline input-120 input-border-left"/>
                <FieldDivider type="break"/>

                <span className="input-inline input-220">
                </span>
                <OrderReceiptCostTotals
                    type="order"
                    record={controllerProps.record}
                    className="input-right input-inline input-120 input-border-left input-margin-left"
                />
                <FieldDivider type="break"/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Shipping Details" instructions={true} marginBottom={true}/>
                {controllerProps.record && controllerProps.record.org_id &&
                    <ContactDetails
                        record={controllerProps.record}
                    />
                }
                <FieldDivider type="break"/>
                <TextField label="Shipping Notes" source="ship_to.notes" className="field-bg"/>
            </SimpleShowLayout>
        </Show>
    )
};

/**
 * View: Edit :::::::::::::::::::::::::::::::::::
 */

const EditActions = ({basePath, data}) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath}/>
            <ShowButton basePath={basePath} record={data}/>
            <PrintToolbarButton record={data} type="Order"/>
        </TopToolbar>
    )
};

const EditTitle = ({record}) => {
    return <span><Avatar icon={<OrderIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit Order {record ? `${record.id}` : 'Order'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton label="Save Order"/>
        <DeleteWithDialog
            type="button"
            label="Order"
            resourceFile="order"
            resourceName="Order"
            resourceParam="id"
            record={props.record}
            disabled={(props.record.status_id && props.record.status_id !== 1)}
        />
    </Toolbar>
);

export const OrderEdit = (props) => {
    return (
        <Edit title={<EditTitle/>} actions={<EditActions/>} {...props}>
            <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
                <FieldDescription
                    instructions={true} marginBottom={true}
                    text={`Edit Order details below.`}
                />
                <TextField label="Order #" source="id" formClassName="input-inline input-90"/>
                <TextField label="Client" source="org_name" formClassName="input-inline input-320"/>
                <FieldDivider type="break"/>

                <TextField label="Billing Reference" source="bill_ref_desc" formClassName="input-inline input-280"/>
                <FormDataConsumer formClassName="input-inline input-320" {...props}>
                    {({formData, ...rest}) => (
                        <SelectFromListInput
                            inAdminForm={true}
                            source="new_bill_ref_id"
                            resourceName="BillingReference"
                            listLabel="Change Billing Reference (optional)"
                            itemPlural="Billing References"
                            listFilters={{
                                active: true,
                                params: {
                                    filter: {
                                        filter_org_id: formData.org_id
                                    },
                                }
                            }}
                            className="input-inline input-320"
                            isRequired={false}
                            returnType="string"
                        />
                    )}
                </FormDataConsumer>
                <FieldDivider type="break"/>
                <FieldDescription
                    instructions={false} marginBottom={false}
                    text={`Remove all Billing References for this Order`}
                />
                <BooleanInput source="clear_bill_ref_id" label="Clear Billing Reference"
                              formClassName="input-inline input-320"/>

                {/* Edit: Order Details */}

                <FieldDivider type="divider"/>
                <FieldDescription text="Order Details" instructions={true} marginBottom={true}/>
                <TextField label="Created By" source="created_by" formClassName="input-inline input-280"/>
                <DateField label="Created On" source="created_on" formClassName="input-inline input-220"/>
                <FieldDivider type="break"/>
                <TextField label="Order Status" source="order_status" formClassName="input-inline input-280"/>
                <FormDataConsumer formClassName="input-inline input-220" {...props}>
                    {({formData, ...rest}) => (
                        <SelectInput
                            source="new_status_id" label="Change Order Status"
                            choices={(formData.status_id === 4) ? ORDER_STATUS_NO_CANCEL : ORDER_STATUS}
                            allowEmpty
                            formClassName="input-inline input-220"
                        />
                    )}
                </FormDataConsumer>
                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <>
                            {formData.new_status_id && formData.new_status_id === 3 &&
                                <FieldDescription
                                    instructions={true} marginBottom={true}
                                    text={`Note: If you Cancel an Order, all Products in the Order will be returned.`}
                                />
                            }
                        </>
                    )}
                </FormDataConsumer>
                <FieldDivider type="break"/>
                <DateField
                    label="Required By Date" source="required_by"
                    options={{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}}
                    formClassName="input-inline input-280"/>
                <DateInput
                    source="new_required_by" label="Change Required Date"
                    formClassName="input-inline input-220"
                />
                <FieldDivider type="break"/>
                <TextInput label="Client Notes" source="client_notes" multiline fullWidth={true}/>
                <TextInput label="Admin Notes" source="notes" multiline fullWidth={true}/>

                {/* Edit: Products */}

                <FieldDivider type="divider"/>
                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <>
                            {(formData.status_id !== 4) &&
                                <>
                                    <FieldDescription text="Products in Order" instructions={true} marginBottom={true}/>
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`To edit the requested quantity of a Product, click its Edit icon. You may add 
                                quantity up to the available inventory for that Product. If no more of that Product 
                                is available, the requested quantity may only be lowered.`}
                                    />
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`If changing a Product's quantity, click its Update button before clicking 
                                Save Order.`}
                                    />
                                </>
                            }
                            <OrderItemListViewContainer
                                view="edit"
                                orderData={formData}
                                hasDelete={true}
                                hasEditQty={(formData.status_id !== 4)}
                                hasRemove={false}
                                {...props}
                            />
                        </>
                    )}
                </FormDataConsumer>

                {/* Edit: Shipping Details */}

                <FieldDivider type="divider"/>
                <FieldDescription text="Shipping Details" instructions={true} marginBottom={true}/>
                <GetListSelectInput
                    {...props}
                    source="shipped_with"
                    label="Carrier"
                    displayType="name"
                    resourceFile="default"
                    resourceName="ShippingCompany"
                    resourceActive={true}
                    addLabel={false}
                    fullWidth={true}
                    formClassName="input-inline input-220"
                    allowEmpty
                />
                <FormDataConsumer formClassName="input-inline input-220" {...props}>
                    {({formData, ...rest}) => (
                        <GetListSelectInput
                            {...props}
                            source="shipping_type"
                            label="Shipping Method"
                            displayType="name"
                            resourceFile="default"
                            resourceName="ShippingMethod"
                            resourceActive={true}
                            requiresFilter={true}
                            filter={(formData.shipped_with) ? {filter_company_id: formData.shipped_with} : null}
                            addLabel={false}
                            fullWidth={true}
                            formClassName="input-inline input-220"
                            allowEmpty
                        />
                    )}
                </FormDataConsumer>
                <DateInput
                    source="shipped_on" label="Shipped On:"
                    formClassName="input-inline input-220"
                />
                <FieldDivider type="break"/>
                <TextInput label="Tracking Number" source="tracking_number" formClassName="input-inline input-320"/>
                <FieldDivider type="break"/>

                {/* Edit: Billing */}

                <FieldDivider type="divider"/>
                <FieldDescription text="Billing" instructions={true} marginBottom={true}/>
                <TextField label="" source="billed" formClassName="input-hidden"/>
                <FormDataConsumer formClassName="input-inline input-280 input-col" {...props}>
                    {({formData, ...rest}) => (
                        <>
                            <BooleanInput source="set_billed" label="Billed?" formClassName="input-inline input-280"/>
                            <TextInput label="Billed By:" source="billed_by_name" formClassName="input-inline input-280"
                                       disabled/>
                            <DateInput source="invoice_date" label="Invoice Date"
                                       formClassName="input-inline input-280"/>
                            <OrderUsernameField type="edit" record={formData}/>
                            <TextInput label="" source="set_billed_by" className="hidden"/>
                        </>
                    )}
                </FormDataConsumer>
                <FormDataConsumer formClassName="input-inline input-border-left input-col" {...props}>
                    {({formData, ...rest}) => (
                        <>
                            <FieldDescription
                                instructions={false} marginBottom={true}
                                text={`Labor is calculated at ${BILLING_LABOR_PER_HOUR_TITLE}.`}
                            />
                            <FieldDescription text="Labor Time" inline={true} type="input" style={{width: 120}}/>
                            <NumberInput label="Hours" source="labor_time_hours" step={1} min={0}
                                         className="input-right input-inline input-90"/>
                            <SelectInput
                                key="labor_time_mins" source="labor_time_mins" label="Minutes"
                                choices={BILLING_LABOR_MINUTES}
                                allowEmpty={false}
                                className="input-inline input-90"
                            />
                            <FieldDivider type="break"/>

                            <FieldDescription text="Labor Cost" inline={true} type="input" style={{width: 120}}/>
                            <NumberInput label="" source="labor_cost" step={0.1} min={0}
                                         className="input-right input-inline input-90"/>
                            <FieldDivider type="break"/>

                            <FieldDescription text="Material Cost" inline={true} type="input" style={{width: 120}}/>
                            <NumberInput label="" source="material_cost" step={0.1} min={0}
                                         className="input-right input-inline input-90"/>
                            <FieldDivider type="break"/>

                            <FieldDescription text="Shipping Cost" inline={true} type="input" style={{width: 120}}/>
                            <NumberInput label="" source="shipping_cost" step={0.1} min={0}
                                         className="input-right input-inline input-90"/>
                            <FieldDivider type="break"/>

                            <FieldDescription text="Courier Cost" inline={true} type="input" style={{width: 120}}/>
                            <NumberInput label="" source="courier_cost" step={0.1} min={0}
                                         className="input-right input-inline input-90"/>
                            <FieldDivider type="break"/>

                            <FieldDescription text="TOTALS" inline={true} type="input" style={{width: 120}}/>
                            <OrderReceiptCostTotals
                                type="order"
                                record={formData}
                                hasLabel={false}
                                className="input-right"
                            />
                        </>
                    )}
                </FormDataConsumer>
                <FieldDivider type="break" margin="bottom"/>

                {/* Edit: Shipping Destination (Contact) */}

                <FieldDivider type="divider"/>
                <FieldDivider type="break" margin="bottom"/>
                <FieldDivider type="divider"/>
                <FieldDescription text="Shipping Destination" instructions={true}/>
                <FieldDivider type="break"/>
                <FieldDescription
                    marginBottom={true}
                    text="Current Destination"
                />
                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <ContactDetails
                            record={formData}
                        />
                    )}
                </FormDataConsumer>
                <TextField label="Shipping Notes" source="ship_to.notes" className="field-bg"/>
                <FieldDivider type="break" margin="bottom"/>
                <FieldDescription
                    instructions={true}
                    marginBottom={true}
                    text={`
                If this Order should ship to a different address, select a new Address. Otherwise, leave blank.
                `}
                />
                <FormDataConsumer {...props}>
                    {({formData, ...rest}) => (
                        <Fragment>
                            <SelectFromListInput
                                inAdminForm={true}
                                source="new_ship_to_id"
                                resourceName="Contact"
                                listLabel="Change Destination (optional)"
                                itemPlural="Contacts"
                                listFilters={{
                                    active: true,
                                    params: {
                                        filter: {
                                            filter_org_id: formData.org_id
                                        },
                                    }
                                }}
                                className="input-full"
                                isRequired={false}
                                returnType="string"
                            />
                            {formData.new_ship_to_id &&
                                <Fragment>
                                    <FieldDivider type="break" margin="bottom"/>
                                    <FieldDescription
                                        marginBottom={true}
                                        text="New Destination"
                                    />
                                    <FormDataConsumer {...props}>
                                        {({formData, ...rest}) => (
                                            <ContactDetailsFromID
                                                org_id={formData.org_id}
                                                id={formData.new_ship_to_id}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Fragment>
                            }
                        </Fragment>
                    )}
                </FormDataConsumer>

                <FieldDescription
                    description={true}
                    text="After clicking Save, you will be redirected to the Orders List."
                />
            </SimpleForm>
        </Edit>
    )
};
