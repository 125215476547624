import React, {useEffect, useState} from 'react';
import {BooleanInput} from 'react-admin';
import axios from 'axios';
import {RESOURCES as resources} from '../resources';
import {getGetHeaders} from '../utils/headers';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import ChildProductTypeTable from './ChildProductTypeTable';
import GetListSelectInput from './GetListSelectInput';

/**
 * A component for displaying child Categories or parent Category selection
 *
 * @param record
 * @param display
 * @return {JSX.Element}
 * @constructor
 */
const ProductTypeEditParentChild = ({record, display = 'show'}) => {

    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(
        () => {
            const fetchEntries = () => {
                const params = {
                    id: record.id
                };
                let cfg = resources.ChildProductType.GET_LIST(params);
                axios({
                    method: 'GET',
                    url: cfg.uri,
                    headers: getGetHeaders(),
                    maxBodyLength: Infinity,
                    maxContentLength: Infinity
                }).then((response) => {
                    if (response.data && response.data.length > 0) {
                        setCategoryList(response.data);
                    }
                    setDataRetrieved(true);
                }).catch((error) => {
                    console.log('error response for Categories: ', error);
                    if (error.response) {
                        // Server response outside 2xx
                    } else if (error.request) {
                        // No response
                    }
                });
            };

            if (record && record.id) {
                return fetchEntries();
            }

        }, [record]
    );

    if (!record || !record.id) {
        return (
            <>
            </>
        )
    }

    return (
        <>
            {display === 'edit' && dataRetrieved && !categoryList.length &&
                <>
                    <FieldDivider type="divider" margin="bottom"/>
                    <FieldDivider type="break" margin="bottom"/>
                    <FieldDescription
                        header={true} marginBottom={true}
                        text="Parent Category"
                    />
                    {!record.parent_type_id &&
                        <FieldDescription
                            instructions={true} marginBottom={true}
                            text={`If this should be a Subcategory, select a parent Category.`}
                        />
                    }
                    {record.parent_type_id &&
                        <FieldDescription
                            instructions={true} marginBottom={true}
                            text={`If this should be a Subcategory of a different Category than ${record.parent_type_name}, select a new parent Category.`}
                        />
                    }
                    <GetListSelectInput
                        source="new_parent_type_id"
                        label="Select a parent Category"
                        displayType="name"
                        excludedId={record.id}
                        resourceFile="default"
                        resourceName="ParentProductType"
                        resourceActive={true}
                        className="half-width"
                        addLabel={false}
                        allowEmpty
                    />
                </>
            }

            {display === 'edit' && dataRetrieved && record.parent_type_id &&
                <>
                    <FieldDescription
                        instructions={true} marginBottom={true}
                        text={`Should this Product Category have no parent Category?`}
                    />
                    <BooleanInput label={`Remove from parent Category ${record.parent_type_name}`}
                                  source="no_parent_type_id"/>
                    <FieldDivider type="break" margin="bottom"/>
                </>
            }

            <FieldDivider type="divider" margin="bottom"/>
            <FieldDivider type="break" margin="bottom"/>
            <FieldDescription
                header={true} marginBottom={true}
                text="Subcategories"
            />

            {categoryList && categoryList.length === 0 &&
                <>
                    {record.parent_type_id &&
                        <FieldDescription
                            description={true}
                            text={`This Category has a parent Category and can therefore have no Subcategories.`}
                        />
                    }
                    {!record.parent_type_id &&
                        <FieldDescription
                            description={true}
                            text={`This Category has no Subcategories.`}
                        />
                    }
                </>
            }
            {display === 'edit' && categoryList && categoryList.length > 0 &&
                <FieldDescription
                    description={true} marginBottom={true}
                    text={`Because this Category has Subcategories, it cannot have a parent Category.`}
                />
            }

            <ChildProductTypeTable categoryList={categoryList}/>

            <FieldDivider type="divider" margin="bottom"/>
            <FieldDivider type="break" margin="bottom"/>
        </>
    );
}

export default ProductTypeEditParentChild;
