import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    BooleanField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton,
    required, FormDataConsumer
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import ListEmpty from '../components/ListEmpty';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import SelectFromListInput from '../components/SelectFromListInput';
import TitleActiveInput from '../components/TitleActiveInput';
import {TITLE_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

export const ShowIcon = LocalActivityIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const validateName = [required()];

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="active" label="Active"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Titles"><ShowIcon style={viewStyles.avatarSvg}/></Avatar>Titles</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [showButtonColumn, editButtonColumn, ...TITLE_COLUMNS];

const listSort = {field: 'org_name', order: 'ASC'};

export const ShowList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              empty={<ListEmpty props={{namePlural: 'Titles', nameSingle: 'Title', connector: 'a'}}/>}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.org_name}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable columns={listColumns} sort={listSort} messageEmpty=" No Titles found." {...props}/>
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Titles"><ShowIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Title'}</span>;
};

export const ShowShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Title details below. To edit this Title, click Edit above.
                `}
            />
            <TextField label="Title ID" source="id" className="input-inline input-120"/>
            <TextField label="Client" source="org_name" className="input-inline input-120"/>
            <TextField label="Title Name" source="name" className="field-bg"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<ShowIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Title</span>;

export const ShowCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add a Title by selecting a Client and entering the name of the Title. A Title may be a show, event, 
                property, or other entity.
                `}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="org_id"
                resourceName="Organization"
                listLabel="Select a Client"
                itemPlural="Clients"
                listFilters={{
                    active: true
                }}
                returnType="string"
                className="input-full"
                isRequired={true}
                hasClear={false}
            />
            <FieldDivider type="break" margin="bottom"/>
            <FieldDescription
                instructions={false} marginBottom={false}
                text={'Enter the full name of the Title.'}
            />
            <TextInput label="Name of Title" source="name" fullWidth={true} validate={validateName}/>
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Titles List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ShowIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.name}` : 'Title'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const ShowEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Edit Title details below. Title ID cannot be edited.
                `}
            />
            <TextField label="Title ID" source="id" formClassName="input-inline input-120"/>
            <TextField disabled label="Current Client" source="org_name" formClassName="input-inline input-320"/>

            <FieldDescription
                description={true}
                text={`
                    If this Title should be associated with a different Client, select a new Client. Otherwise, leave blank.
                    `}
            />
            <FormDataConsumer formClassName="input-full" {...props}>
                {({formData, ...rest}) => (
                    <SelectFromListInput
                        inAdminForm={true}
                        source="new_org_id"
                        resourceName="Organization"
                        listLabel="Change Client (optional)"
                        itemPlural="Clients"
                        listFilters={{
                            active: true
                        }}
                        className="input-full"
                        isRequired={false}
                        returnType="string"
                    />
                )}
            </FormDataConsumer>
            <FieldDivider type="break" margin="bottom"/>
            <FieldDivider type="break" margin="bottom"/>

            <TextInput label="Name of Title" source="name" fullWidth={true} validate={validateName}/>

            <FormDataConsumer formClassName="input-full" {...props}>
                {({formData, ...rest}) => (
                    <>
                        <TitleActiveInput formData={formData} {...props}/>
                        <TextInput label="" source="active" disabled className="input-280 hidden-input"/>
                    </>
                )}
            </FormDataConsumer>

            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Titles List."
            />
        </SimpleForm>
    </Edit>
);

