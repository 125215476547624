import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import OrderItemsAvailableList from './OrderItemsAvailableList';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {TextInput} from 'react-admin';
import OrgShowSelectFromListInput from "./OrgShowSelectFromListInput";
import SelectFromListInput from "./SelectFromListInput";
import FieldDivider from "./FieldDivider";

const checkboxStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        verticalAlign: 'top',
        marginTop: 4
    },
}));

/**
 * A component for retrieving and displaying a Product List by Order ID
 *
 * @param type
 * @param orderId
 * @param orgId
 * @param displayFields
 * @param onProductAdd
 * @param onProductAdjust
 * @param productToUpdate
 * @param addedList
 * @param itemsInOrder
 * @param refreshList
 * @param updateList
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OrderItemsAvailableListContainer = (
    {
        type,
        orderId,
        orgId,
        displayFields,
        onProductAdd,
        onProductAdjust,
        productToUpdate,
        addedList = [],
        itemsInOrder = [],
        refreshList = false,
        updateList = false,
        ...props
    }) => {

    const [currentOrgId, setCurrentOrgId] = useState(null);
    const [itemList, setItemList] = useState({
        list: []
    });
    const [filterName, setFilterName] = useState(null);
    const [filterNameValue, setFilterNameValue] = useState(null);
    const [filterSKU, setFilterSKU] = useState(null);
    const [filterSKUValue, setFilterSKUValue] = useState(null);
    const [filterTitleId, setFilterTitleId] = useState(null);
    const [filterTypeId, setFilterTypeId] = useState(null);
    const [excludeZeroQty, setExcludeZeroQty] = useState(true);
    const [updateComplete, setUpdateComplete] = useState(false);
    const [updateProductComplete, setUpdateProductComplete] = useState(false);

    // Callback: Retrieve list of Products
    const getItemList = useCallback(() => {
        return JSON.parse(JSON.stringify(itemList.list));
    }, [itemList]);

    // Effect: Fetch all Products by Org ID
    useEffect(
        () => {
            if (orgId && !updateComplete && displayFields) {
                setCurrentOrgId(orgId);
                const fetchItems = () => {
                    const params = {
                        active: true,
                        id: orgId
                    };

                    const cfg = resources.ProductsByOrg.GET_LIST(params);
                    axios({
                        method: 'GET',
                        url: cfg.uri,
                        headers: getGetHeaders(),
                        maxBodyLength: Infinity,
                        maxContentLength: Infinity
                    }).then((response) => {
                        if (response.data && response.data.length > 0) {
                            processItems(response.data);
                        }
                        // resolve(response);
                    }).catch((error) => {
                        console.log('error response for Products: ', error);
                        if (error.response) {
                            // Server response outside 2xx
                        } else if (error.request) {
                            // No response
                        }
                    });
                };

                const processItems = (data) => {
                    let list = [];
                    if (data && data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            const item = data[i];
                            const itemToAdd = {
                                active: item.active,
                                added: false,
                                id: item.id,
                                notes: '',
                                org_id: item.org_id,
                                org_name: item.org_name,
                                qty: item.qty,
                                qty_available: (item.qty >= 0) ? item.qty : 0,
                                qty_requested: 0,
                                show_id: item.show_id,
                                show_name: item.show_name,
                                sku: item.sku,
                                thumbnail: item.thumbnail,
                                title: item.title,
                                type_id: item.type_id,
                                type_name: item.type_name,
                                warehouse_locations: item.warehouse_locations
                            };
                            // If Products have already been added to a stored Order, adjust the list
                            if (addedList.length > 0) {
                                const addedItem = addedList.find(p => p.id === itemToAdd.id);
                                if (addedItem) {
                                    itemToAdd.qty_requested = addedItem.qty_requested;
                                    itemToAdd.notes = addedItem.notes;
                                    itemToAdd.added = true;
                                }
                            }
                            list.push(itemToAdd);
                        }
                    }
                    // Store a complete list of Products
                    setItemList({list: list});
                    setUpdateComplete(true);
                };

                // If an Org ID becomes available or has changed, or if a refresh is needed, fetch Products
                if (orgId || !updateComplete) {
                    fetchItems();
                }
            }

        }, [orgId, displayFields, updateComplete, addedList]
    );

    // Effect: Update a Product's qty edited outside this component
    useEffect(() => {
        const updateProductQty = () => {
            let list = getItemList();
            const idx = list.findIndex(r => r.id === productToUpdate.id);
            if (idx !== -1) {
                list[idx].qty_requested = productToUpdate.qty_requested;
                list[idx].added = (list[idx].qty_requested !== 0);
                list[idx].notes = productToUpdate.notes;
                setItemList({list: list});
            }
            setUpdateProductComplete(true);
        };
        if (!updateProductComplete) {
            updateProductQty();
        }
    }, [getItemList, productToUpdate, updateProductComplete]);

    // Effect: Update if another component has edited a requested Product Qty
    useEffect(() => {
        if (updateList && productToUpdate) {
            setUpdateProductComplete(false);
        }
    }, [productToUpdate, updateList]);

    // Effect: Refresh if Org ID is changed or list should refresh
    useEffect(
        () => {
            if ((orgId !== currentOrgId) || refreshList) {
                setUpdateComplete(false);
            }

        }, [orgId, currentOrgId, refreshList, updateComplete]
    );

    // Effect: Debounce SKU input
    useEffect(() => {
        const updateFilterSKU = setTimeout(() => {
            setFilterSKUValue(filterSKU);
        }, 1000);

        return () => clearTimeout(updateFilterSKU);

    }, [filterSKU]);

    // Effect: Debounce Title input
    useEffect(() => {
        const updateFilterName = setTimeout(() => {
            setFilterNameValue(filterName);
        }, 1000);

        return () => clearTimeout(updateFilterName);

    }, [filterName]);

    const blurFilter = () => {
        const active = document.activeElement;
        if (active) {
            active.blur();
        }
    };

    const handleSearchNameSKUFilter = (type, e) => {
        let val = e?.currentTarget?.value ?? null;
        if (type === 'name') {
            setFilterName(val);
        }
        if (type === 'sku') {
            setFilterSKU(val);
        }
    };

    const handleSelectTitleFilter = (e) => {
        let id = (e) ? e : null;
        setFilterTitleId(id);
        blurFilter();
    };

    const handleSelectTypeFilter = (e) => {
        let id = (e) ? e : null;
        setFilterTypeId(id);
        blurFilter();
    };

    const handleQtyToggle = (e) => {
        setExcludeZeroQty(e.target.checked);
    };

    /**
     * Handle add/remove/update event from Available List
     *
     * @param e
     */
    const handleAddChange = (e) => {
        let list = JSON.parse(JSON.stringify(itemList.list));
        const idx = list.findIndex(r => r.id === e.item.id);
        if (idx !== -1) {
            // Update the Product's requested Qty and Notes
            list[idx].qty_requested = parseInt(e.qty_requested, 10);
            list[idx].notes = e.notes;

            // If the Product is to be added or removed, or the quantity is 0, update the row
            list[idx].added = (list[idx].qty_requested >= 0);

            // Update this available list
            setItemList({list: list});

            // Notify the form that this item is to be added or removed on this Order
            const item = JSON.parse(JSON.stringify(list[idx]));
            item.product_id = item.id;
            item.qty_requested = list[idx].qty_requested;
            item.submitted = false;
            // Create: Checkbox to add items to Added table
            if (type === 'create' && typeof onProductAdjust === 'function') {
                onProductAdjust({item: item, add: item.added});
            }
            // Edit: Button to post items to add to Order/Receipt
            if (type === 'edit' && e.add && typeof onProductAdd === 'function') {
                onProductAdd({item: item, add: item.added});
            }
        }
    };

    const classes = checkboxStyles();

    return (
        <>
            <div className="order-items-filter">
                <OrgShowSelectFromListInput
                    selectOrg={!orgId}
                    selectedOrgId={orgId}
                    orgLabel="Select a Client"
                    orgSrc="order_items_filter_org_id"
                    showLabel="Filter Products by Title"
                    showSrc="order_items_filter_show_id"
                    addLabel={false}
                    inline={true}
                    className="input-inline third"
                    onSelectShow={(e) => handleSelectTitleFilter(e)}
                />
                <SelectFromListInput
                    inAdminForm={true}
                    source="filter_product_type_id"
                    resourceName="ProductType"
                    listLabel="Filter Products by Category"
                    itemPlural="ProductType"
                    listFilters={{
                        active: true
                    }}
                    className="input-inline third"
                    isRequired={true}
                    returnType="string"
                    onChangeFunc={(e) => handleSelectTypeFilter(e)}
                />
                <FieldDivider type="break" margin="bottom"/>
                <TextInput
                    key="filter_product_sku"
                    label="Search Product SKU"
                    source="filter_product_sku"
                    placeholder="SKU-00012345"
                    onChange={(e) => handleSearchNameSKUFilter('sku', e)}
                    className="input-inline third"
                />
                <TextInput
                    key="filter_product_title"
                    label="Search Product Name"
                    source="filter_product_title"
                    alwaysOn
                    onChange={(e) => handleSearchNameSKUFilter('name', e)}
                    className="input-inline third"
                />
                <FormGroup className={classes.root}>
                    <FormControlLabel
                        label="Hide 0 Qty"
                        control={
                            <Checkbox
                                color="default"
                                checked={excludeZeroQty}
                                onChange={(e) => handleQtyToggle(e)}
                            />
                        }
                    />
                </FormGroup>
                <FieldDivider type="break"/>
            </div>
            <OrderItemsAvailableList
                type={type}
                fullItemList={itemList.list}
                itemsInOrder={itemsInOrder}
                filterName={filterNameValue}
                filterSKU={filterSKUValue}
                filterTitleId={filterTitleId}
                filterTypeId={filterTypeId}
                excludeZeroQty={excludeZeroQty}
                onEditFunc={(e) => handleAddChange(e)}
            />
        </>
    );
}

export default OrderItemsAvailableListContainer;
