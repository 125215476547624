import React, {useState, useEffect} from 'react';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useProductLocationStyles = makeStyles((theme) => ({
    th: {
        lineHeight: '1.4',
        padding: '8px 16px 8px 16px',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '8px 16px 8px 16px'
    },
    tdLight: {
        color: 'rgba(0, 0, 0, 0.54)'
    }
}));

/**
 * A component to display a list of a Product's Warehouse Locations
 *
 * @param record
 * @param locations
 * @returns {JSX.Element}
 * @constructor
 */
const ProductLocationsList = ({record, locations}) => {

    const [locationsState, setLocationsState] = useState([]);

    useEffect(
        () => {
            if (
                record.warehouse_locations
                && record.warehouse_locations.length > 0
            ) {
                setLocationsState(record.warehouse_locations);
            }

        }, [record.warehouse_locations]
    );


    const setRows = () => {
        return (
            locationsState.map((loc, index) => (
                <TableRow key={`product-location-${loc.location}-${index}`}>
                    <TableCell className={(loc.qty < 1) ? classes.tdLight : classes.td}>
                        {loc.location}
                    </TableCell>
                    <TableCell align="right" className={(loc.qty < 1) ? classes.tdLight : classes.td}>
                        {(loc.qty < 1) ? 'Empty' : 'Yes'}
                    </TableCell>
                </TableRow>
            ))
        )
    };

    const classes = useProductLocationStyles();

    return (
        <>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription header={true} margin="bottom" text="Warehouse Locations"/>
            <FieldDivider type="break" margin="bottom"/>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`This Product may be stored in one or more Warehouse Locations. To move this Product between 
                Locations, remove it entirely from a Location, or add it to a new Location, edit this product.`}
            />

            {locationsState.length === 0 &&
            <>
                <FieldDescription description={true} text="(This Product is not stored in any Locations)"/>
                <FieldDivider type="break" margin="bottom"/>
                <FieldDivider type="divider" margin="bottom"/>
            </>
            }
            {locationsState.length > 0 &&
            <>
                <Table size="small" stickyHeader aria-label="Warehouse Locations">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.th}>Warehouse Locations</TableCell>
                            <TableCell align="right" className={classes.th}>Stored in<br/>Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...setRows()]}
                    </TableBody>
                </Table>
                <FieldDivider type="break" margin="bottom"/>
                <FieldDivider type="divider" margin="bottom"/>
            </>
            }
        </>
    );
}

export default ProductLocationsList;
