import React, {useEffect, useMemo, useState} from 'react';
import ListTable from './ListTable';
import OrderReceiptProductEditDialog from './OrderReceiptProductEditDialog';
import ProductDetailDialog from '../components/ProductDetailDialog';
import ProductThumbnail from './ProductThumbnail';
import {isBlank} from '../utils/dataFunctions';
import {tableStyles} from '../global/tableStyles';
import {makeStyles} from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(tableStyles);

/**
 * A component to display a filtered list of Products for an Order
 *
 * @param onEditFunc
 * @param fullItemList
 * @param itemsInOrder
 * @param filterName
 * @param filterSKU
 * @param filterTitleId
 * @param filterTypeId
 * @param excludeZeroQty
 * @return {JSX.Element}
 * @constructor
 */
const OrderItemsAvailableList = (
    {
        onEditFunc,
        fullItemList = [],
        itemsInOrder = [],
        filterName = null,
        filterSKU = null,
        filterTitleId = null,
        filterTypeId = null,
        excludeZeroQty = false
    }) => {

    const [filteredItemList, setFilteredItemList] = useState({
        list: []
    });

    const [itemDetailState, setItemDetailState] = useState({
        dialog: false,
        item: null
    });

    const [itemEditState, setItemEditState] = useState({
        dialog: false,
        item: null
    });

    useEffect(
        () => {
            const filterList = () => {
                let list = [];
                const nameFilter = (!isBlank(filterName)) ? filterName : null;
                const skuFilter = (!isBlank(filterSKU)) ? filterSKU : null;
                const titleId = (filterTitleId) ? parseInt(filterTitleId, 10) : null;
                const typeId = (filterTypeId) ? parseInt(filterTypeId, 10) : null;
                for (let i = 0; i < fullItemList.length; i++) {
                    let add = true;
                    const item = fullItemList[i];
                    // If there is a Product Name to search, include only Product names with that string
                    if (nameFilter && !item.title.toLowerCase().includes(nameFilter.toLowerCase())) {
                        add = false;
                    }
                    // If there is a SKU to search, include only Products containing that SKU string
                    if (skuFilter && !item.sku.toLowerCase().includes(skuFilter.toLowerCase())) {
                        add = false;
                    }
                    // If there is a Title to filter by, include only Products of that Title
                    if (titleId && titleId !== item.show_id) {
                        add = false;
                    }
                    // If there is a Type to filter by, include only Products of that Type
                    if (typeId && typeId !== item.type_id) {
                        add = false;
                    }
                    if (excludeZeroQty && item.qty === 0) {
                        add = false;
                    }
                    if (add) {
                        list.push(item);
                    }
                }
                // Set the list only if a filter exists
                setFilteredItemList({list: (nameFilter || skuFilter || titleId || typeId) ? list : []});
            };

            if (fullItemList || filterName || filterSKU || filterTitleId || filterTypeId) {
                filterList();
            }
        }, [fullItemList, filterName, filterSKU, filterTitleId, filterTypeId, excludeZeroQty]
    );

    // All Views: Handle event from Info button
    const handleInfoClick = (item) => {
        setItemDetailState({dialog: !!(item), item: item});
    };

    const handleCloseItemDialog = () => {
        setItemDetailState({dialog: false, item: null});
    };

    // All Views: Handle event from Edit Product button
    const handleEditClick = (item) => {
        setItemEditState({dialog: !!(item), item: item});
    };

    const handleSaveEditDialog = (e) => {
        let product = JSON.parse(JSON.stringify(e.product));
        product.qty_requested = e.qty_requested;
        let add = (e.qty_requested >= 0);
        setItemEditState({dialog: false, item: null});
        if (typeof onEditFunc === 'function') {
            onEditFunc({
                item: product,
                qty_requested: product.qty_requested,
                notes: e.notes,
                add: add
            });
        }
    };

    const handleCloseEditDialog = () => {
        setItemEditState({dialog: false, item: null});
    };

    const classes = useStyles();

    const tableColumns = useMemo(() => [
        {
            Header: () => (<span style={tableStyles.th}>SKU</span>),
            accessor: 'sku',
            sortType: 'basic',
            setRowState: ({row}) => (row.original.added) ? tableStyles.tr : tableStyles.trAdded,
            Cell: ({row}) => (
                <span style={tableStyles.td}>{(row.original.sku) ? row.original.sku : ''}</span>
            )
        },
        {
            Header: 'Image',
            id: 'thumb',
            Cell: ({row}) => <ProductThumbnail
                label="Image"
                id={row.original.id}
                title={row.original.title}
                thumbnail={row.original.thumbnail}/>,
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: () => (<span style={tableStyles.th}>Title</span>),
            accessor: 'show_name',
            sortType: 'basic',
            Cell: ({row}) => (
                <span style={tableStyles.td}>{(row.original.show_name) ? row.original.show_name : ''}</span>
            )
        },
        {
            Header: () => (<span style={tableStyles.th}>Category</span>),
            accessor: 'type_name',
            sortType: 'basic',
            Cell: ({row}) => (
                <span style={tableStyles.td}>{(row.original.type_name) ? row.original.type_name : ''}</span>
            )
        },
        {
            Header: () => (<span style={tableStyles.th}>Product</span>),
            accessor: 'title',
            sortType: 'basic',
            Cell: ({row}) => (
                <span style={tableStyles.tdName}>
                    {(row.original.title) ? row.original.title : ''}
                    <IconButton
                        aria-label="Info"
                        className={classes.btnInfo}
                        onClick={() => handleInfoClick(row.original)}
                    >
                        <InfoIcon/>
                    </IconButton>
                </span>
            )
        },
        {
            Header: () => (<span style={tableStyles.thRight}>Available<br/>Qty</span>),
            accessor: 'qty',
            sortType: 'basic',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({row}) => (
                <span style={tableStyles.tdRight}>{(row.original.qty) ? row.original.qty : '0'}</span>
            )
        },
        {
            Header: () => (<span style={tableStyles.thRight}>Requested<br/>Qty</span>),
            accessor: 'qty_requested',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({row}) => (
                <span
                    style={tableStyles.tdRight}>{(row.original.qty_requested) ? row.original.qty_requested : '0'}</span>
            )
        },
        {
            Header: () => (<span style={tableStyles.th}>Add<br/>Qty</span>),
            id: 'add',
            disableFilters: true,
            disableSortBy: true,
            Cell: ({row}) => {
                let inOrder = itemsInOrder.find(r => r.product_id === row.original.id);
                return (
                    <span style={tableStyles.tdName}>
                        {!inOrder &&
                            <IconButton
                                aria-label="Add"
                                color="default"
                                className={classes.btnIcon}
                                onClick={() => handleEditClick(row.original)}
                            >
                                <EditIcon/>
                            </IconButton>
                        }
                        {inOrder &&
                            <IconButton
                                aria-label="Add"
                                color="default"
                                className={classes.btnIcon}
                                disabled
                            >
                                <BlockIcon/>
                            </IconButton>
                        }
                </span>
                )
            }
        }
    ], [classes, itemsInOrder]);

    const tableData = useMemo(() => filteredItemList.list, [filteredItemList]);

    const listSort = {field: 'title', order: 'ASC'};

    const getRowStyle = (row) => {
        return (row.original && row.original.added) ? tableStyles.trAdded : tableStyles.tr;
    };

    return (
        <>
            {fullItemList.length > 0 &&
                <div className="order-item-list-container">
                    <ListTable
                        data={tableData}
                        columns={tableColumns}
                        perPage={20}
                        includePagination={true}
                        loaded={true}
                        sort={listSort}
                        tableHeight={465}
                        rowStyle={(row) => getRowStyle(row)}
                        messageEmpty="Please filter or search to view Products."
                    />
                    <ProductDetailDialog
                        openDialog={itemDetailState.dialog}
                        productId={(itemDetailState.item) ? itemDetailState.item.id : null}
                        onCloseFunc={handleCloseItemDialog}
                    />
                    <OrderReceiptProductEditDialog
                        dialogType="order"
                        openDialog={itemEditState.dialog}
                        product={(itemEditState.item) ? itemEditState.item : null}
                        onSaveFunc={handleSaveEditDialog}
                        onCloseFunc={handleCloseEditDialog}
                    />
                </div>
            }
        </>
    )
}

export default OrderItemsAvailableList;
