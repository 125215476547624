import React from 'react';
import FieldDivider from './FieldDivider';
import {
    PrintDateField,
    PrintLongTextField,
    PrintTextField
} from './PrintFields';
import OrderItemListViewContainer from './OrderItemListViewContainer';

/**
 * Component containing Order data for printing
 *
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */
const OrderPrint = ({record}) => {

    return (
        <div className="content">
            <h1>Order #{record.id}</h1>

            <PrintTextField label="Order ID" value={record.id} classes="input-quarter"/>
            <PrintTextField label="Client" value={record.org_name} classes="input-half"/>
            <PrintTextField label="Status" value={record.order_status} classes="input-quarter"/>
            <FieldDivider type="break"/>

            {record.bill_ref_desc &&
                <PrintTextField label="Billing Reference" value={record.bill_ref_desc}
                                classes="input-full has-bg"/>
            }

            <h2>Order Details</h2>

            <PrintTextField label="Created By" value={record.created_by} classes="input-third"/>
            <PrintDateField label="Created On" value={record.created_on} classes="input-third"/>
            <PrintDateField label="Due Date" value={record.required_by} classes="input-third"/>
            <FieldDivider type="break"/>

            <table className="table-print">
                <tbody>
                <tr>
                    <td className="table-half">
                        <table className="table-print">
                            <tbody>
                            <tr>
                                <td className="label">To:</td>
                                <td className="value" colSpan={3}>{record.ship_to.name}</td>
                            </tr>
                            {record.ship_to.first_name ?
                                (
                                    <tr>
                                        <td className="label"></td>
                                        <td className="value"
                                            colSpan={3}>{record.ship_to.first_name} {record.ship_to.last_name}</td>
                                    </tr>
                                ) : null

                            }
                            <tr>
                                <td className="label"></td>
                                <td className="value" colSpan={3}>{record.ship_to.address}</td>
                            </tr>
                            {record.ship_to.address2 ?
                                (
                                    <tr>
                                        <td className="label"></td>
                                        <td className="value" colSpan={3}>{record.ship_to.address2}</td>
                                    </tr>
                                ) : null

                            }
                            <tr>
                                <td className="label"></td>
                                <td className="value">{record.ship_to.city}</td>
                                <td className="value">{record.ship_to.state}</td>
                                <td className="value">{record.ship_to.zip}</td>
                            </tr>
                            {record.ship_to.country ?
                                (
                                    <tr>
                                        <td className="label"></td>
                                        <td className="value" colSpan={3}>{record.ship_to.country}</td>
                                    </tr>
                                ) : null

                            }
                            {record.ship_to.email ?
                                (
                                    <tr>
                                        <td className="label"></td>
                                        <td className="value" colSpan={3}>{record.ship_to.email}</td>
                                    </tr>
                                ) : null

                            }
                            {record.ship_to.phone ?
                                (
                                    <tr>
                                        <td className="label"></td>
                                        <td className="value" colSpan={3}>{record.ship_to.phone}</td>
                                    </tr>
                                ) : null
                            }
                            {record.ship_to.notes ?
                                (
                                    <tr>
                                        <td className="label"></td>
                                        <td className="value has-breaks" colSpan={3}>{record.ship_to.notes}</td>
                                    </tr>
                                ) : null
                            }
                            </tbody>
                        </table>
                    </td>
                    <td className="table-half">
                        <table className="table-print">
                            <tbody>
                            <tr>
                                <td className="label">Carrier:</td>
                                <td>{record.shipped_with_name}</td>
                            </tr>
                            <tr>
                                <td className="label">Method:</td>
                                <td>{record.shipping_type_name}</td>
                            </tr>
                            <tr>
                                <td className="label">Shipped On:</td>
                                <td>{record.shipped_on}</td>
                            </tr>
                            {record.tracking_number &&
                                <tr>
                                    <td className="label">Tracking Number(s):</td>
                                    <td>{record.tracking_number}</td>
                                </tr>

                            }
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>

            {record.client_notes &&
                <PrintLongTextField label="Client Notes" value={record.client_notes}
                                    classes="input-block has-bg has-breaks"/>
            }
            {record.notes &&
                <PrintLongTextField label="Admin Notes" value={record.notes} classes="input-block has-bg has-breaks"/>
            }
            <FieldDivider type="break"/>

            <h2>Product List</h2>

            <OrderItemListViewContainer view="print" forPrint={true} hasEditQty={false} orderId={record.id}/>
        </div>
    )
};

export default OrderPrint;
