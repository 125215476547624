import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    TextField,
    SelectInput,
    TextInput,
    SaveButton
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import {EMPLOYEE_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PeopleIcon from '@material-ui/icons/People';

export const EmployeeIcon = PeopleIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="active" label="Active Employees"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Employees"><EmployeeIcon style={viewStyles.avatarSvg}/></Avatar>Employees</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [...EMPLOYEE_COLUMNS, showButtonColumn, editButtonColumn];

const listSort = {field: 'name', order: 'ASC'};

export const EmployeeList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.first_name + ' ' + record.last_name}
                    secondaryText={record => record.department}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable columns={listColumns} sort={listSort} messageEmpty=" No Employees found." {...props}/>
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Employee"><EmployeeIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.first_name} ${record.last_name}` : 'Employee'}</span>;
};

export const EmployeeShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text="Review Employee details below. To edit this Employee, click Edit above."/>
            <TextField label="ID" source="id"/>
            <TextField label="First Name" source="first_name" className="input-inline input-220"/>
            <TextField label="Last Name" source="last_name" className="input-inline input-220"/>
            <FieldDivider type="break"/>
            <TextField label="Department" source="department" className="input-inline input-320"/>
            <TextField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<EmployeeIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create an Employee</span>;

export const EmployeeCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add an Employee by entering a name and description.
                `}
            />
            <TextInput label="First Name" source="first_name" formClassName="input-inline input-220"/>
            <TextInput label="Last Name" source="last_name" formClassName="input-inline input-220"/>
            <FieldDivider type="break"/>
            <TextInput label="Department" source="department"/>
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Employees List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<EmployeeIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.first_name} ${record.last_name}` : 'Employee'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const EmployeeEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Edit Employee details below. Employee ID cannot be edited.
                `}
            />
            <TextInput disabled label="ID" source="id"/>
            <TextInput label="First Name" source="first_name" formClassName="input-inline input-220"/>
            <TextInput label="Last Name" source="last_name" formClassName="input-inline input-220"/>
            <FieldDivider type="break"/>
            <TextInput label="Department" source="department"/>
            <FieldDescription
                description={true}
                text="If this Employee is no longer valid, mark as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Employees List."
            />
        </SimpleForm>
    </Edit>
);

