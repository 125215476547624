import React, {useState} from 'react';
import {
    Button,
    Confirm,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import {assemblePostTasks} from '../utils/apiFunctions';

const BulkEditProductSubmitButton = ({
                                         editOption,
                                         selectedIds,
                                         selectedCategory,
                                         selectedClient,
                                         selectedTitle,
                                         selectedActive,
                                         onSuccess
                                     }) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const onSaveProgress = (e) => {
    };

    const saveData = async () => {
        const data = {
            isOrderValueUpdate: false,
            isProductValueUpdate: true,
            selectedIds: selectedIds
        };
        switch (editOption) {
            case 'bulk_edit_option_type':
                data.updateType = 'type';
                data.updateValue = selectedCategory.id;
                break;
            case 'bulk_edit_option_client':
                data.updateType = 'show';
                data.updateValue = selectedTitle.id;
                break;
            case 'bulk_edit_option_show':
                data.updateType = 'show';
                data.updateValue = selectedTitle.id;
                break;
            case 'bulk_edit_option_active':
                data.updateType = 'active';
                data.updateValue = selectedActive;
                break;
            default:
                break;
        }
        return await assemblePostTasks(data, onSaveProgress)
            .then((result, ...rest) => {
                notify('Products updated');
                if (typeof onSuccess === 'function') {
                    onSuccess();
                    unselectAll('Product');
                }
            })
            .catch(error => {
                console.log('Posting Error: ', error);
                notify('Error: Products not updated', 'warning');
            });
    };

    const handleClick = () => setOpen(true);

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        saveData().then(() => {
            setOpen(false);
        });
    };

    // Assemble button and confirmation text
    const productStr = (selectedIds.length === 1) ? 'Product' : 'Products';
    let headerText = 'Update Products';
    let btnText = 'Update Products';
    let content = '?';
    let isDisabled = false;
    if (editOption === 'bulk_edit_option_type') {
        headerText = 'Update Product Category';
        btnText = 'Set Product Category';
        content = `to the "${selectedCategory.name}" Category?`;
        isDisabled = (!selectedCategory.id);
    }
    if (editOption === 'bulk_edit_option_client') {
        headerText = 'Update Product Client & Title';
        btnText = 'Set Client & Title';
        content = `with the "${selectedClient.name}" Client and "${selectedTitle.name}" Title?`;
        isDisabled = (!selectedClient.id || !selectedTitle.id);
    }
    if (editOption === 'bulk_edit_option_show') {
        headerText = 'Update Product Title';
        btnText = 'Set Title';
        content = `with the "${selectedTitle.name}" Title?`;
        isDisabled = (!selectedTitle.id);
    }
    if (editOption === 'bulk_edit_option_active') {
        headerText = 'Update Product Active State';
        btnText = 'Set Active State';
        content = `as ${(selectedActive) ? 'Active' : 'Inactive'}?`;
    }

    return (
        <>
            {editOption &&
                <Button
                    color="primary"
                    variant="outlined"
                    size="medium"
                    label={btnText}
                    onClick={handleClick}
                    disabled={(isDisabled)}
                    className="btn-bulk-edit"
                />
            }
            <Confirm
                isOpen={open}
                title={headerText}
                content={`Are you sure you want to update ${selectedIds.length} ${productStr} ${content}`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
}

export default BulkEditProductSubmitButton;
